import React from "react";
import sorry from "./images/sorry.png";

const No_courses = () => {
    return (
        <>
            <div className="mycrses no_course">
                <h3 className="dash_hdng">My Courses</h3>
                <div className="cardbox">
                    <div className="crs_txt">
                        <div className="">
                            <img src={sorry} />
                        </div>
                        <h6>You haven’t entered or purchased any course.</h6>

                        <a href="" className="vwall_btn">Go To Course</a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default No_courses;
