import React from "react";
import axios from "axios";
import moment from "moment-timezone";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import blog_dtl from "../../src/images/blog_dtl.png";
import Trending_blogs from "./Trending_blgs";
import { cmsdetail } from "../Api_list";
import { Helmet } from "react-helmet";
let slug = document.URL.split("/")[3];
const Blog_dtltext = (props) => {
    let getData = props.data; 
    return (
        <>
         <Helmet>
            <title>{(getData != null) ? getData.meta_title : 'Careereduversity'}</title> 
            <meta name="title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta name="description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta name="keywords" content={(getData != null) ? getData.meta_keyword : 'Careereduversity'} />
            <meta property="og:title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta property="og:image" content={(getData != null) ?  window.IMGURL + getData.image : 'Careereduversity'}/>
            <meta property="og:description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta property="og:url" content={window.APPURL+slug} />
        </Helmet>
        <section className='bg-voilet bdtl_top'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-12'>
                            <div className="whysbs_top">
                                <div class="txt_img text-center">
                                    <h1 className="sitehdng">{ getData.titleorheading }</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container pt_50">
                <div className="row">
                    <div className="col-sm-8">
                    <div className='blog_dtlbox'>
                <h2>{ getData.titleorheading }</h2>
                <div class="by_date">
                    <span> <PersonIcon /> {(typeof getData.add_by !== "undefined") ?  getData.add_by.split('#')[1] : 'Admin'} </span>
                    <span><CalendarMonthOutlinedIcon /> {moment(getData.added_on).format("DD/MM/YYYY")}  </span>
                </div>
                <div className="blgd_img">
                    <img src={window.IMGURL + getData.image} alt={ getData.titleorheading } />
                </div>
                <div className='blgdtl_txt' dangerouslySetInnerHTML={{ __html: getData.content }} />
            </div>
                    </div>
                    <div className="col-sm-4">
                        <Trending_blogs cmstype={getData.page_type}/>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default Blog_dtltext;
