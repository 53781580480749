import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../src/images/logo.png";
// import LockIcon from "@mui/icons-material/Lock";
// import PersonIcon from "@mui/icons-material/Person";
import styled from 'styled-components';
import Burger from './Burger';
// import { NavLink } from "react-router-dom";

const Nav = styled.nav`
  width: 100%;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items:center;

  .logo {
    padding: 15px 0;
  }
`
const Navbar = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mainmenu">
            <Nav>
              <div className="logo">
                <NavLink className="navbar-brand" to="/">
                  <img src={logo} className="logoimg" alt="Logo" />
                </NavLink>
              </div>
              <Burger />
              
            </Nav>
          </div>
        </div>
      </div>

      {/* <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav className="header_site navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <NavLink className="navbar-brand" to="/">
                    <img src={logo} className="logoimg" alt="Logo" />
                  </NavLink>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          aria-current="page"
                          to="/why_sbs"
                        >
                          Why SBS
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/college_listing">
                          College By Course
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/job_internship">
                          Job And Internship
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/hire_sbs">
                          Hire From SBS
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/plans">
                          Plan
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/contact_us">
                          More
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="header_btn">
                    <ul>
                      <li className="nav-item">
                        <NavLink className="nav-link btn_know" to="/login">
                          <LockIcon /> Login
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link btn_know bbtn" to="/register">
                          <PersonIcon /> Register
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Navbar;
