import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';

export default function Bottom_fixed() {
  return (
    <>
        <div className="bottom-fixed"> 
            <ul>
                <li><a href=""> <HomeOutlinedIcon/> <br/> Home</a> </li>
                <li><a href=""> <InventoryOutlinedIcon/> <br/> Our Plan</a> </li>
                <li><a href=""> <LibraryBooksOutlinedIcon/> <br/> Our Course</a> </li>
                <li><a href=""> <ThreePOutlinedIcon/> <br/> CAreer Coach</a> </li>
            </ul>
        </div>
    </>
  )
}
