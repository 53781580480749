import React from 'react';
import axios from 'axios';
import Hire_form from "./Hire_form";
import { cmsdetail } from './Api_list';
import { Helmet } from 'react-helmet';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
 
const Why_hire_sec = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get( cmsdetail+'hire', headerData ).then(( response )=>{
            setPost( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data;
    return (
        <> 
        <Helmet>
            <title>{(getData != null) ? getData.meta_title : 'Careereduversity'}</title> 
            <meta name="title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta name="description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta name="keywords" content={(getData != null) ? getData.meta_keyword : 'Careereduversity'} />
            <meta property="og:title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta property="og:image" content={(getData != null) ?  window.IMGURL + getData.image : 'Careereduversity'}/>
            <meta property="og:description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta property="og:url" content={window.BASEURL+'hire-sbs'} />
        </Helmet>
         <section className='bg-voilet hireform_sec'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-7'>
                            <div className="whysbs_top">
                                <div class="txt_img">
                                    <h2 className="sitehdng">Hire Job Ready Candidates at <span>No Cost </span> </h2>
                                    <p>{ getData.shortcontent }</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-5'>
                            <Hire_form />
                        </div>
                    </div>
                </div>
            </section>
            <div className="container job_sect padd_50">
                <div className="row hdngtxt text-center">
                    <h3 className='site_hdng'>Why Hire From Us</h3>
                </div>
                <div className="row hirerow">
                    <div className="col-md-6"> 
                        <div className='hiretxt'>
                            <ul>
                            {getData.left_points.map(leftpoint => (
                                <li><CheckCircleIcon/> {leftpoint }</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='hireimg'>
                            <div className="imgs"><img src={ window.IMGURL+getData.image } alt={ getData.alt_tag } /></div>
                        </div>
                    </div> 
                </div>
                <div className="row hirerow">
                    <div className="col-md-6">
                        <div className='hireimg'>
                            <div className="imgs"><img src={ window.IMGURL+getData.image1 } alt={ getData.alt_tag1 }/></div>
                        </div>
                    </div>
                    <div className="col-md-6"> 
                        <div className='hiretxt'>
                            <ul> 
                            {getData.right_points.map(rightpoints => (
                                <li><CheckCircleIcon/> {rightpoints }</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='gettouch'>
                        <a href='/contact-us' className='cta_btn'>Get in Touch</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Why_hire_sec;