import React, { useState } from "react";
import Footer from "./partial/Footer";
import { cmsdetail } from "./Api_list";
import axios from "axios";
import { Helmet } from "react-helmet";
const Know_interest = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json", 
            "authorization": window.HEADERSAUTH,
          }}
        axios.get(cmsdetail+'cancellation-policy', headerData).then(( response )=>{
            setPost( response.data )
        })
    }, [])
    if( !post ) return null;
    let getData = post.data;
    let imgshow;
    if (getData.image) {
      imgshow = <img src={ window.IMGURL+getData.image } />;
    } 
    return (
        <>
         <Helmet>
            <title>{(getData != null) ? getData.meta_title : 'Careereduversity'}</title> 
            <meta name="title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta name="description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta name="keywords" content={(getData != null) ? getData.meta_keyword : 'Careereduversity'} />
            <meta property="og:title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta property="og:image" content={(getData != null) ?  window.IMGURL + getData.image : 'Careereduversity'}/>
            <meta property="og:description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta property="og:url" content={window.BASEURL+'terms-and-conditions'} />
        </Helmet>
          <section className='bg-voilet bdtl_top'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-12'>
                            <div className="whysbs_top">
                                <div class="txt_img text-center">
                                    <h1 className="sitehdng">Refund Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container pt_50">
                <div className="row">
                    <div className="col-sm-12">
                    <div className='blog_dtlbox'>
                <h2>{ getData.titleorheading }</h2>
                <div className="blgd_img">
                    {imgshow}
                </div>
                <div className='blgdtl_txt' dangerouslySetInnerHTML={{ __html: getData.content }} />
            </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Know_interest;






