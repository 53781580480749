import React, { useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { homeslider } from '../Api_list';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Goalslider = () => {

  var [nav1, setNav1] = useState();
  var [nav2, setNav2] = useState();

  const [post, setPost] = React.useState(null);
  React.useEffect(() => {
    var headerData = {"headers":{
      "Accept": "application/json",
      "Content-Type": "application/json",
      "authorization": window.HEADERSAUTH,
    }} 
    axios.get(homeslider, headerData).then((response) => {
      setPost(response.data);
    });
  }, []);
  if (!post) return null;
  var getData = post.data;


  return (
<section className="container goals_sec" >
              <div className="row centr_hdng_txt">
                  <h2 className="sitehdng"> Take the next step towards your personal and professional goals with  <span>Career Eduversity</span> </h2>
                  <p>Career Eduversity is smart career guidance platform designed to cater to needs of students who are looking forward to building successful careers in the future. Here we have combined technology, traditional counselling services, and admission assistance services to form a platform where you can find all the career guidance services under one roof. We intend to become the no.1 career guidance company in India and we are steadily moving towards our goal of achieving excellence in the field of career guidance and development.</p>
              </div>
              

    <div className='row'>
     <div className='col-sm-6 left-steps'>
        <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={getData.length}
        swipeToSlide={true}
        focusOnSelect={true} 
      >
        {getData.map(getData => (
        <div className='steps_txt'>
          <h6><CheckCircleIcon/>{ getData.left_heading }</h6>
          <p>{ getData.left_desc } </p>
          <span className='rightarw'><ChevronRightIcon/></span>
        </div>
        ))}
       
        </Slider>
      </div>
      
      <div className='col-sm-6 right-goals'>
        <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
        {getData.map(getData => (
            <div className='goal_txt'>
              <img src={  window.IMGURL + getData.image } alt={ getData.right_heading }/>
              <h5>{ getData.right_heading }</h5>
              <p>{ getData.right_desc }</p>
            </div>
             ))}
           
        </Slider>
      </div>
    </div>
    </section>
  );
}

export default Goalslider;