import React from "react";
import axios from "axios";
import stp_big from "../src/images/stp_big.png";
import stp1 from "../src/images/stp1.png";
import stp2 from "../src/images/stp2.png";
import stp3 from "../src/images/stp3.png";
import { cmsdetail } from './Api_list';

const Next_step = () => {
    const [post, setPost] = React.useState(null);
    React.useEffect(() => {
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
      axios.get(cmsdetail+'why-sbs', headerData).then((response) => {
        setPost(response.data);
      });
    }, []);
    if (!post) return null;
    return (
        <>
            <section className="bg_skyb whysbs_step padd_30">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-sm-6">
                            <div class="txt_img">
                            {/* {post.data.titleorheading_sec} */}
                                <h2 className="sitehdng"> Take a <span>next step</span> towards your personal and professional goals with <span>Career Eduversity</span></h2>
                                <div className="s" dangerouslySetInnerHTML={{ __html: post.data.content_sec }} />;
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="stpsbig_img">
                                <img src={  window.IMGURL + post.data.image1 } alt={post.data.alt_tag1} />
                            </div>
                        </div>
                    </div>
                    <div className="row stps_btmcolms">
                        <div className="col-sm-3 stpsbox">
                            <img src={stp1} />
                            <p>Our dedicated <span>Career Coaches</span> driven by <span>accountability</span>
                                and <span>passion</span> .</p>
                        </div>
                        <div className="col-sm-3 stpsbox">
                            <img src={stp2} />
                            <p>Industry wide <span>HR networking</span> driving <span>placements</span> in global job market. </p>
                        </div>
                        <div className="col-sm-3 stpsbox">
                            <img src={stp3} />
                            <p>Right skill drive  <span>empowering students</span> and making them <span>industry ready</span>.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Next_step;
 