import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import menuprofile from "./images/menuimg.png";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import GradingIcon from '@mui/icons-material/Grading';
import PermPhoneMsgOutlinedIcon from '@mui/icons-material/PermPhoneMsgOutlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin:0;

  li {
    padding: 18px 6px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(1000%)'};
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    padding: 2.5rem 1.5rem;
    transition: transform 0.3s ease-in-out;
  }
`;

const RightNav = ({ open }) => {
    return (
        <Ul open={open}>
            <div className='mobprofl_menu'>
                <img src={menuprofile} />
                <h6>Guest User</h6>
                <p>Sign up and get job or internship assurance benefits right from start</p>
            </div>
            <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/student/dashboard">
                   <DashboardIcon/> Dashboard
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/student/job-application">
                   <GradingOutlinedIcon/> Job Application Status
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/student/latest-blogs-articles">
                    <ClassOutlinedIcon /> Blog/Articles
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/student/applied-institute">
                   <GradingIcon/> Applied Institute
                </NavLink>
            </li>
            <li className="nav-item d-flex">
                {/* <NavLink className="nav-link" to="/student/book-appointment">
                    <PermPhoneMsgOutlinedIcon /> Book a call with experts
                </NavLink> */}
                <PermPhoneMsgOutlinedIcon />
                <NavDropdown title="Book a call with experts" id="basic-nav-dropdown">
                <NavDropdown.Item href="/student/book-appointment/phone-call/hr-coach">
                    HR Coach
                </NavDropdown.Item>
                <NavDropdown.Item href="/student/book-appointment/phone-call/career-coach">
                Career Coach
                </NavDropdown.Item>
                </NavDropdown>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/student/internship-form">
                    <AppRegistrationIcon/> Internship Form
                </NavLink>
            </li>

            <li className="nav-item d-flex">
                {/* <NavLink className="nav-link" to="/student/book-appointment">
                    <PermPhoneMsgOutlinedIcon /> Book a call with experts
                </NavLink> */}
                <SupportAgentOutlinedIcon/> 
                <NavDropdown title="Support" id="basic-nav-dropdown">
                <NavDropdown.Item href="/student/chat/hr-coach">
                    HR Coach
                </NavDropdown.Item>
                <NavDropdown.Item href="/student/chat/career-coach">
                Career Coach
                </NavDropdown.Item>
                </NavDropdown>
            </li>
            {/* <li className="nav-item">
                <NavLink className="nav-link" to="/student/chat">
                   <SupportAgentOutlinedIcon/> 
                </NavLink>
                </li> */}

        </Ul>
 

    )
}

export default RightNav