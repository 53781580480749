import React, { useState } from "react";
import axios from "axios";
import Footer from "./partial/Footer";  
import Success_sec from "./Success_sec";
import Failure_sec from "./Failure_sec";
import { paymentVarify, planactivate } from "./Api_list";
let slug = document.URL.split("ord=")[1];

const Success = () => {
    let obj = JSON.parse( localStorage.getItem('loginddata') );
    const [ post, setPost] = React.useState( null );
    const [showReg, setShowReg] = useState(false);
    const [showResults, setShowResults] = useState(false)
    React.useEffect(()=>{
       axios.get( paymentVarify + slug + '&user_id='+obj._id ).then(( response )=>{
           setPost( response.data );
           if( response.data.order_status === 'PAID'){
            axios.get( planactivate+obj._id+'&order_id='+slug ).then(( response )=>{
                setShowReg(true);       
            })
           }else{
            setShowResults(true);
           }
       })
    }, []);
  console.log(post)
    return (
        <> 
        { showResults ? 
         <Failure_sec  orderId={slug}/>
         : "" }
         { showReg ? 
          <Success_sec orderId={slug} /> 
         : "" }
          
           <Footer />
        </>
    )
}

export default Success;
