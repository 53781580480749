

import React from "react";

const Contacttop_bg = () => {
    return (
        <>
            <section className='bg-voilet hireform_sec'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-12'>
                            <div className="whysbs_top">
                                <div class="txt_img">
                                    <h2 className="sitehdng">Contact us</h2>
                                    <p>Career Eduversity emphasizes a holistic approach to education, integrating traditional academic knowledge with practical experiences. This is achieved through various initiatives, including Internship Programs, Industry Collaborations, Skill-based Training, and Mentorship and Guidance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contacttop_bg;