import { React, useEffect, useState} from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { enquirysubmit } from "./Api_list";
let color = {};
const Hire_form = () => {

      const [name, setName] = useState("");
      const [mobileNumber, setMobileNumber] = useState("");
      const [emailId, setEmailId] = useState("");
      const [companyName, setCompanyName] = useState("");
      const [message, setMessage] = useState("");
      
      let handleSubmit = async (e) => {
        e.preventDefault();
       
        const post = {
          full_name: name,
          email_id: emailId,
          company_name : companyName,
          type : 'hire_from_sbs',
          mobile_no: mobileNumber,
        }
      if((name.length != 0) && (mobileNumber.length === 10)){
        try {

          var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 

          const res = await axios.post(enquirysubmit, post, headerData)
          if (res.status === 200) {
            color.color = 'green';
            setName("");setMobileNumber("");setEmailId("");setCompanyName("");
                setMessage('Thank you, your call back request has been submitted successfully!');
                setTimeout(() => {
                  setMessage('');
                }, 5000); 
              } else {
                color.color = 'red';
                setMessage("Some error occured");
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              }
        } catch (e) {
          color.color = 'red';
          setMessage("Some error occured");
          setTimeout(() => {
            setMessage('');
          }, 5000);
        }
      }else{
        color.color = 'red';
        setMessage("Please enter valid mobile number"); 
        setTimeout(() => {
          setMessage('');
        }, 5000);
      }
      };

  return (
    <>
      <div className='hirefrm_wrp'>
        <h3 className='site_hdng'>Hire Qualified Resources at Zero Cost</h3>
        <div className="message">{message ? <p style={color}>{message}</p> : null}</div> 
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="text" onChange={(e) => setName(e.target.value)} value={name} placeholder="Full Name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="email" value={emailId} onChange={(e) => setEmailId(e.target.value)}  placeholder="Enter email" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)}  placeholder="Company Name" />
          </Form.Group>

          <InputGroup className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-default">
              +91
            </InputGroup.Text>
            <Form.Control
              aria-label="Default"
              maxLength={10}
              onChange={(e) => setMobileNumber(e.target.value)} 
              value={mobileNumber}
              aria-describedby="inputGroup-sizing-default"
            />
          </InputGroup>
          <Button variant="primary" type="submit">
            Get In Touch
          </Button>
        </Form>
      </div>
    </>
  );
}

export default Hire_form;