import React , { useEffect, useState } from "react";
import axios from "axios";
import skillingicon1 from "./images/skilling-icon1.png";
import skillingicon2 from "./images/skilling-icon2.png";
import skillingicon3 from "./images/skilling-icon3.png";
import skillingicon4 from "./images/skilling-icon4.png";

import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Studentslider from "../Cmpnt/Student_trust";
import { alumnilist } from "../Api_list";
let obj = JSON.parse( localStorage.getItem('loginddata') );

function MyVerticallyCenteredModal(props) {
    return (
        <Modal className="qry_modal"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="prgrm">
                    <h4 className="text-center">Have questions about the program?</h4>

                    <p>Let us help you understand the program better. Someone from our team will contact you within 48 hours. Select appropriate Course.</p>
                    <Form className='row'>
                        <Col className="col-sm-5">
                            <CalendarMonthOutlinedIcon />
                            <input type="text" name="name" placeholder="Select Date" />
                        </Col>
                        <Col className=" col-sm-4">
                            <AccessTimeOutlinedIcon />
                            <input type="text" name="name" placeholder="Select Time" />
                        </Col>
                        <Col className="col-sm-3 text-center">
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Col>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
}



export default function UpSkill() {
    const [modalShow, setModalShow] = React.useState(false);
    const [ post, setPost ] = useState(null);
    useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}
        axios.get(alumnilist+'Alumni', headerData).then(( resData )=>{
            setPost( resData.data )
        })
    }, [])
    if( !post ) return null;
    let getData = post.data; 
    return (
        <> 
            <div className="up_skilling_container py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="up_skilling_content">
                                <div className="up_skilling_left">
                                    <h3> <span>A wide network</span> of institutions redefining <span>up-skilling</span> and <span>educations.</span></h3>
                                    <p>Empowering students with world-className certifications and degree programs. Kickstart your career 100+ job opportunies. </p>
                                    <h6>Programs in <span>collaboration</span> with <span>top universities</span> and <span>MNCs</span></h6>
                                </div>
                                <div className="up_skilling_right">
                                    <ul className="up_skilling_r_icons d-flex flex-wrap">
                                        <li>
                                            <div className="up_skilling_r_icon_box">
                                                <div className="up_skilling_r-icon">
                                                    <img src={skillingicon1} alt="" />
                                                </div>
                                                <h4>Renowned colleges and universities network</h4>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="up_skilling_r_icon_box">
                                                <div className="up_skilling_r-icon">
                                                    <img src={skillingicon2} alt="" />
                                                </div>
                                                <h4>Global hiring partners</h4>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="up_skilling_r_icon_box">
                                                <div className="up_skilling_r-icon">
                                                    <img src={skillingicon3} alt="" />
                                                </div>
                                                <h4>Dedicated mentors network</h4>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="up_skilling_r_icon_box">
                                                <div className="up_skilling_r-icon">
                                                    <img src={skillingicon4} alt="" />
                                                </div>
                                                <h4>Upto 55% career hike </h4>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="up_skilling_r_btn">
                                        <a href={(obj.plan_name) ?  '#!' : "/plans"} className="btn_pink">{(obj.plan_name) ?  obj.plan_name : "N/A"}</a>
                                        <a href="/student/applied-institute" className="btn_blue_outline">Courses</a>
                                    </div>
                                </div>

                                <div className="up_skilling_bottom">
                                    <ul className="up_skilling_b_logos">
                                    { getData.map( getData =>(
                                        <li>
                                            <div className="up_skilling_b_logo">
                                            <img src={ window.IMGURL + getData.company_logo} alt={getData.company_name}/>
                                            </div>
                                        </li>
                                        )) } 
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="mt-3 text-end">
                                <a href="#!" className="request_call_btn" onClick={() => setModalShow(true)}>
                                    <LocalPhoneOutlinedIcon />
                                    <span className='d-none d-md-block'>Request call back</span>
                                </a>
                                <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </div> */}
                        </div>
                    </div>

                </div> 
            </div>

            <section className="container students_sec padd_50">
                {/* <div className="almhdng d-flex align-items-center justify-content-between">
                    <h3 className="sitehdng"> Alumni Success Story  </h3>
                    <a href="">View all</a>
                </div> */}
                <Studentslider />
            </section>
        </>
    )
}
