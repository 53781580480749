import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import intro from "./images/intro-img.png";
import icon1 from "./images/icon-1.png";
import icon2 from "./images/icon-2.png";
import icon3 from "./images/icon-3.png";
import icon4 from "./images/icon-4.png";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DataTable from 'react-data-table-component';
import { jobcountwithstatus , joblistbyuser} from "../Api_list";
let obj = JSON.parse( localStorage.getItem('loginddata') );
const columns = [
    {
        name: 'Sr. No.',
        selector: row => row.jobid,
        sortable: true,
    },
    {
        name: 'Company',
        selector: row => row.company,
        sortable: true,
    },
    {
        name: 'Job Post',
        selector: row => row.jobpost,
        sortable: true,
    },
    {
        name: 'Job Type',
        selector: row => row.jobtype,
        sortable: true,
    },
    {
        name: 'Location',
        selector: row => row.location,
        sortable: true,
    },
    {
        name: 'Posted On',
        selector: row => row.postedon,
        sortable: true,
    },
    {
        name: 'Application Deadline',
        selector: row => row.lastdate,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
];


export default function JobApp() {
     const [ allcount, setAllcount ]         = useState(0);
     const [ allViewed, setAllViewed ]       = useState(0);
     const [ allProcessed, setAllProcessed ] = useState(0);
     const [ allExpired, setAllExpired ]     = useState(0);
     const [ alljoblist, setAlljoblist ]     = useState(0);
     
     useEffect(()=>{

        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}

        axios.get(jobcountwithstatus+obj._id, headerData).then(( responsefirst )=>{
            setAllcount( responsefirst.data.data )
        })
        axios.get( jobcountwithstatus+obj._id+'&status=Viewed',headerData ).then(( responsesecond )=>{
            setAllViewed( responsesecond.data.data )
        })
        axios.get( jobcountwithstatus+obj._id+'&status=Processed', headerData ).then(( responsethird )=>{
            setAllProcessed( responsethird.data.data );
        })
        axios.get( jobcountwithstatus+obj._id+'&status=Expired',headerData ).then(( responsefourth )=>{
            setAllExpired( responsefourth.data.data )
        })
        axios.get( joblistbyuser+obj._id, headerData ).then(( responsefifth )=>{
            setAlljoblist( responsefifth.data )
        })
     }, [])
        if( !alljoblist ) return null;
        let getData = alljoblist.data;
       

    
    
    ChartJS.register(ArcElement, Tooltip, Legend);
    
     const data = {
        labels: ['Applied', 'Viewed', 'Processed', 'Expired'],
        datasets: [
            {
                label: '# of Votes',
                data: [allcount, allViewed, allProcessed, allExpired],
                backgroundColor: [
                    'rgba(131, 1, 244, 1)',
                    'rgba(255, 204, 0, 1)',
                    'rgba(38, 191, 173, 1)',
                    'rgba(255, 17, 101, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <div className="job_intro_container">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="job_intro_content">
                            <div className="job_intro_text">
                                <h3>Job Application Status</h3>
                                <p> Lorem ipsum dolor sit amet. Est alias dolorem ea quasi numquam et natus voluptate id soluta blanditiis vel consequuntur explicabo aut placeat
                                    deleniti est provident exercitationem! Non deserunt officiis qui quisquam exercitationem et minus explicabo. Rem necessitatibus architecto
                                    quo ipsa dolorem cum maiores assumenda et galisum eveniet sit fugit dicta. Et quam doloremque quo enim porro qui odit deserunt et aperiam 
                                    esse sed ipsa ipsam qui saepe neque qui incidunt possimus.</p>
                            </div>
                            <div className="job_intro_img">
                                <img src={intro} alt="" />
                            </div>
                        </div>
                        {/* Chart area */}
                        <div className="applicants_review_area">
                            <div className="applicants_review_list d-flex flex-wrap">
                                <div className="applicants_review_item">
                                    <div className="applicants_review_box">
                                        <h6>{allcount} <span>Applied</span></h6>
                                        <div className="applicants_review_icon">
                                            <img src={icon1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="applicants_review_item">
                                    <div className="applicants_review_box">
                                        <h6>{allViewed} <span>Viewed</span></h6>
                                        <div className="applicants_review_icon">
                                            <img src={icon2} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="applicants_review_item">
                                    <div className="applicants_review_box">
                                        <h6>{allProcessed} <span>Processed</span></h6>
                                        <div className="applicants_review_icon">
                                            <img src={icon3} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="applicants_review_item">
                                    <div className="applicants_review_box">
                                        <h6>{allExpired} <span>Expired</span></h6>
                                        <div className="applicants_review_icon">
                                            <img src={icon4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='applicants_chartBox cardbox'>
                                <h5>Application Status</h5>
                                <Doughnut  data={data} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row cardbox'>
                    <h5>Application List</h5>
                    <DataTable
                        columns={columns}
                        data={ 
                            getData.map( 
                                (jobdata,index) => (
                            { 
                                jobid: index+1,
                                company: jobdata.company_name,
                                jobpost: jobdata.job_designation,
                                jobtype: jobdata.job_type,
                                location: jobdata.city,
                                postedon: moment(jobdata.added_on).format("DD/MMM/YYYY"),
                                lastdate: moment(jobdata.application_end).format("DD/MMM/YYYY"),
                                status: jobdata.status,
                            }
                            )
                            ) 
                        }
                    />
                </div>
            </div>
        </div>
    )
}