import React from 'react';
import axios from 'axios';
import { advymbrslist } from '../Api_list'


const Advisory = () => {
    const[ post, setPost] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json", 
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(advymbrslist, headerData).then(( response )=>{
            setPost( response.data )
        })
    },[]);
    if( !post ) return null;
    var getData = post.data;

    return (
         getData.map((getData, index) => (
            (index % 2  === 0 ) ? (
            <>
           <section className="ml-sct clr-light noalign">  
                <div className="container">
                    <div className="row cstmorder">
                        <div className="col-md-4">
                            <div className="ml-dls">
                                <img src={  window.IMGURL + getData.profile_pic } alt={getData.name} />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="ml-dls">
                                <h3>{getData.name}, {getData.desigantion}</h3>
                                <div className="qualnsocial d-flex justify-content-between">
                                    <div className="qualftext">
                                        <div className="qual">
                                            <h6>Organization</h6>
                                            <p>{getData.organization}</p>
                                        </div>
                                        <div className="qual">
                                            <h6>Experience</h6>
                                            <p>{getData.experience}</p>
                                        </div>
                                    </div>
                                    <div className="socialwrp">
                                        <div className="conct-detils">
                                            <div className="cnct-dtils-bx">
                                                <div className="cnct-dtils-icn"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
                                                <div className="cnct-dtils-text">
                                                    <p>E-mail</p>
                                                    <h4>  <a href={getData.email_id}>{getData.email_id}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <p className="un-line"> {getData.shortcontent}</p>
                                <h4 className="uni-h">{getData.qualification}</h4>

                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
            </>
             ) : (
                <>
                <section className="ml-sct noalign">
                <div className="container">
                    <div className="row cstmorder">
                    <div className="col-md-8">
                            <div className="ml-dls">
                                <h3>{getData.name}, {getData.desigantion}</h3>
                                <div className="qualnsocial d-flex justify-content-between">
                                    <div className="qualftext">
                                        <div className="qual">
                                            <h6>Organization</h6>
                                            <p>{getData.organization}</p>
                                        </div>
                                        <div className="qual">
                                            <h6>Experience</h6>
                                            <p>{getData.experience}</p>
                                        </div>
                                    </div>
                                    <div className="socialwrp">
                                        <div className="conct-detils">
                                            <div className="cnct-dtils-bx">
                                                <div className="cnct-dtils-icn"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
                                                <div className="cnct-dtils-text">
                                                    <p>E-mail</p>
                                                    <h4>  <a href={getData.email_id}>{getData.email_id}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <p className="un-line"> {getData.shortcontent}</p>
                                <h4 className="uni-h">{getData.qualification}</h4>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ml-dls">
                                <img src={  window.IMGURL + getData.profile_pic } alt={getData.name} />
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
                </>
             )
 ))
            
            
       
    )
}

export default Advisory;