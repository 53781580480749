import React, { useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import FileUploader from "./File_upload";
import { registersecond } from "./Api_list";
let color = {};
const Choose_course = () => {

    const [message, setMessage]     = useState("");
    const [formData, setFormData] = useState({
        qualification   : '',
        course_name     : '',
        institute       : '',
        start_from      : '',
        end_to          : '',
        course_type     : '',
        semester        : '',
        file            : null
      });
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        const data = new FormData();
        data.append('id', localStorage.getItem('userid'));
        data.append('qualification', formData.qualification);
        data.append('course_name', formData.course_name);
        data.append('college_institute_name', formData.institute);
        data.append('course_start_from', formData.start_from);
        data.append('course_end_to', formData.end_to);
        data.append('course_type', formData.course_type);
        data.append('institute', formData.institute);
        data.append('semester', formData.semester);
        data.append('filename', formData.file);
        // console.log(formData.file);
        // console.log(data);
        try {
            const res = await axios.post(registersecond, data)
            //console.log(res.data.data);
            if (res.status === 200) {
                localStorage.removeItem("userid");
                localStorage.removeItem("mobileno");
                localStorage.setItem( 'loginddata', JSON.stringify(res.data.data) );
                color.color = 'green';
                  setMessage(res.data.message);
                  setTimeout(() => {
                    setMessage('');
                  }, 5000);
                  var page_url =  localStorage.getItem('page_url') ? localStorage.getItem('page_url') : '/';
                  window.location.href = page_url;
      
                } else {
                  color.color = 'red';
                  setMessage(res.data.message);
                  setTimeout(() => {
                    setMessage('');
                  }, 5000);
                }
          } catch (e) {
            color.color = 'red';
            setMessage("Some error occured");
            setTimeout(() => {
              setMessage('');
            }, 5000);
          }
      }

    return (
        <>
            <section className="loginsec">
                <div className="container padd_50">
                    <div className="profileform">
                        <h3>Course Details</h3>
                        <Form className="row" onSubmit={handleSubmit}>
                           
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Degree</Form.Label>
                                <Form.Select value={formData.qualification} onChange={(e) => setFormData({...formData, qualification: e.target.value})} name="qualification" aria-label="Default select example">
                                    <option value="Under Graduate">Under Graduate</option>
                                    <option value="Post Graduate">Post Graduate</option>
                                    <option value="Master Degree">Master Degree</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Course</Form.Label>
                                <Form.Control  value={formData.course_name} onChange={(e) => setFormData({...formData, course_name: e.target.value})} type="text" placeholder="Enter Course" />
                                {/* <Form.Select value={formData.course_name} onChange={(e) => setFormData({...formData, course_name: e.target.value})}  name="course_name" aria-label="Default select example">
                                    <option>Select course</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select> */}
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Institute/College</Form.Label>
                                <Form.Control name="college_institute_name" value={formData.institute} onChange={(e) => setFormData({...formData, institute: e.target.value})} type="text" placeholder="Enter College" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-3" controlId="formBasicEmail">
                                <Form.Label>From</Form.Label>
                                <Form.Control value={formData.start_from} onChange={(e) => setFormData({...formData, start_from: e.target.value})} type="date" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-3" controlId="formBasicEmail">
                                <Form.Label>To</Form.Label>
                                <Form.Control value={formData.end_to} onChange={(e) => setFormData({...formData, end_to: e.target.value})} type="date" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Semester</Form.Label>
                                <Form.Control value={formData.semester} onChange={(e) => setFormData({...formData, semester: e.target.value})} type="text" placeholder="Enter current semester" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Course Type</Form.Label>
                                <Form.Select value={formData.course_type} onChange={(e) => setFormData({...formData, course_type: e.target.value})} aria-label="Default select example">
                                        <option value="Online">Online</option>
                                        <option value="Distance">Distance </option>
                                        <option value="Part Time">Part Time </option>
                                        <option value="Regular">Regular </option>
                                </Form.Select>
                            </Form.Group>
                            <div className="App">
                            {/* <input
                            type="file"
                            value={selectedFile}
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            /> */}
                            <input type="file" onChange={(e) => setFormData({...formData, file: e.target.files[0]})} />
                        </div>

                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Choose_course;
