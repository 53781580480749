import React from 'react';
import j1 from './images/j1.png';
import j2 from './images/j2.png';
import j3 from './images/j3.png';
import j4 from './images/j4.png';

const Job = () => {
    return (
        <>
            <div className="container job_sect padd_50">
                <div className="row text-center">
                    <h3 className='site_hdng'>A Job for everyone</h3>
                </div>
                <div className="row jobtypes">
                    <div className="col-md-3 col-6 ">
                        <div className='jobbox'>
                            <div className="imgs"><img src={j1} /></div>
                            <p>Full Time Jobs</p>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className='jobbox'>
                            <div className="imgs"><img src={j2} /></div>
                            <p>Part Time Jobs</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-6">
                        <div className='jobbox'>
                            <div className="imgs"><img src={j3} /></div>
                            <p>Work From Home</p>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className='jobbox'>
                            <div className="imgs"><img src={j4} /></div>
                            <p>Internship</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Job;