import React from "react";
import Blog_box from "./Blog_box";

const Blog_list_sec = () => {
    return (
        <>
            <section className="home_course">
                <div className="container">
                    <div className="row centr_hdng_txt">
                        <h2 className="sitehdng"> Our Blogs </h2>
                        <p>Lorem ipsum dolor sit amet. Est voluptatem odio et tenetur eveniet.</p>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <Blog_box />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Blog_list_sec;