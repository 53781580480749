import React, { useState } from "react";
import axios from "axios";
import { courselist } from "../Api_list";

const Crs_box = ({CourseName}) => {
    
    const [post, setPost] = useState("");
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get( courselist+CourseName, headerData).then((response)=>{
            setPost( response.data )
        })
    }, [])

    if( !post ) return null;
    let getData2 = post.data;
    
    return ( 
        <>      
                 {getData2.map( (getData2, num) =>(
                            <div className="col-sm-4">
                                <div className="crs_box">
                                    <div className="crsimg">
                                        <img src={window.IMGURL + getData2.univ_slider[0].slider} />
                                    </div>
                                    <div className="crs_cont">
                                        <div className="crs_ovrview">
                                            <h3>{ getData2.course_name }</h3>
                                            <span className="duration">{ getData2.course_duration } Months</span>
                                            <span className="mode">Mode : { getData2.course_formate }</span>
                                            <div className="frm_univ">
                                                <span>From</span>
                                                <img src={window.IMGURL + getData2.univ_logo} />
                                            </div>
                                        </div>
                                        <a href={getData2.univ_slug+'/'+getData2.slug} className="cta_btn crs_btn"> View Details</a>
                                    </div>
                                </div>
                            </div> 
                            ))}

        </>
    )
}

export default Crs_box