import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const Faq = (props) => {
    const [ post, setPost ] = React.useState( props.data );
    let getData = post.univ_faq;
    return (
        <>
            <div className="container padd_50">
                <div className="row text-center">
                    <h3 className='site_hdng'>Frequently Asked Questions</h3>
                </div>
                <div className='row faq_accrdion'>
                    <Accordion defaultActiveKey="0" className=''>
                        <div className='faq_left'>
                        {   getData.map( (getData, num) =>(
                                <Accordion.Item eventKey={( (num === 0) ? "0" : num)}>
                                <Accordion.Header>{ getData.question} </Accordion.Header>
                                <Accordion.Body>
                                { getData.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                           ))}
                            
                        </div>
                    </Accordion>
                </div>
            </div>

        </>
    )
}

export default Faq;