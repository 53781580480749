

import React from "react";

const Plantop_bg = () => {
    return (
        <>
            <section className='bg-voilet  hireform_sec'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-12'>
                            <div className="whysbs_top">
                                <div class="txt_img">
                                    <h2 className="sitehdng">Career Eduversity Services </h2>
                                    <p>We are changing the dynamics in hiring and making students seasoned freshers. Better Tech-Better Career. Dedicate Portal, Elite Coaches, Connect with mentors on chat, 
                                    audio, and Video. Unlimited job inventory applies with one click. Stay one step ahead with top companies' internships.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Plantop_bg;