import React from "react";
import axios from "axios";
import Course_filter from "./Courses_fil";
import Crs_box from "./courses/Course_box";
import crs1 from "../src/images/crs1.png";
import crs2 from "../src/images/crs2.png";
import u1 from "../src/images/u1.png";
import u2 from "../src/images/u2.png";
import Accordion from 'react-bootstrap/Accordion'
import { univcourselist } from "./Api_list";
const Course_uni = (props) => {
    const [ post, setPost ] = React.useState( props.data );
    const [component, setComponent] = React.useState(null);

    

    let arr = post.univ_courses;
    const res = Array.from(arr.reduce((m, {stream_name,course_name}) => 
            m.set(stream_name, [...(m.get(stream_name) || []), course_name]), new Map
        ), ([stream_name, course_name]) => ({stream_name, course_name})
        );
    //console.log(res[0].course_name[0]) 
    React.useEffect(() => {
        getaxiosdata( res[0].course_name[0])
    }, []);
     
    function getaxiosdata( c){

        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH, 
          }} 
          
            axios.get( univcourselist+post.slug+'&name='+c, headerData).then((responses)=>{
                      let  getData2  = responses.data.data;
                       let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                        <div className="col-sm-4">
                            <div className="crs_box">
                                <div className="crsimg">
                                    <img src={post.univ_slider ? window.IMGURL + post.univ_slider[0].slider : ""} />
                                </div>
                                <div className="crs_cont">
                                    <div className="crs_ovrview">
                                        <h3>{ getData2.c_course_name }</h3>
                                        <span className="duration">{ getData2.c_course_duration } Months</span>
                                        <span className="mode">Mode : { getData2.c_course_formate }</span>
                                        <div className="frm_univ">
                                            <span>From</span>
                                            <img src={window.IMGURL + post.univ_logo} />
                                        </div>
                                    </div>
                                    <a href={post.slug+'/'+getData2.c_slug} className="cta_btn crs_btn"> View Details</a>
                                </div>
                            </div>
                        </div>
                        ))) : "";
                       
                        setComponent(storecorse);
                   })
        }

   
    return (
        <>
            <section className="home_course pb-50">
                <div className="container">
                    <div className="row centr_hdng_txt zone_wrp">
                        <h2 className="sitehdng"> Programs <span>Offered</span> </h2>
                        <h5>Explore Programs</h5>
                        <p>Persue UG PG Program at leading institute and create your success story.</p>
                    </div>
                    <div className="row">
                        <div className="col-sm-3"> 
                        <div className="filwrap">
                                <Accordion defaultActiveKey="0" flush className="filt_container">
                                {   res.map( (getData, num) =>(
                                    <Accordion.Item eventKey={(num === 0) ? "0" : num}>
                                        <Accordion.Header>{ getData.stream_name } </Accordion.Header>
                                        <Accordion.Body className="box_sh_inner">
                                            <ul>
                                            { getData.course_name.map( (coursedata) =>(  
                                                <li  onClick={() => getaxiosdata(coursedata)}>{ coursedata }</li>
                                            ))}
                                            </ul>
                                        </Accordion.Body> 
                                    </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div> 
                        </div>
                        <div className="col-sm-9">
                           
                            <div className="row">
                                {component}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Course_uni;