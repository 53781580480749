import React from "react";

const Advisorytop_bg = () => {
    return (
        <>
            <section className="advisory_topbg whysbs_top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div class="txt_img">  
                                <h2 className="sitehdng">Our Advisory Board</h2>
                                <p>Lorem ipsum dolor sit amet. Qui totam atque sit dolorem internos qui explicabo nemo qui labore asperiores qui possimus tempora in ipsam.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Advisorytop_bg;