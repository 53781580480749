import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import notf from "./images/notf.png";

export default function Notfication_details() {


    return (
        <>
            <div className='container ptb_50'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="job_intro_content">
                            <div className="job_intro_text">
                                <h3>Internship Form</h3>
                                <p>Lorem ipsum dolor sit amet. Est alias dolorem ea quasi numquam et natus voluptate id soluta blanditiis vel consequuntur explicabo aut
                                    placeat deleniti est provident exercitationem! Non deserunt officiis qui quisquam exercitationem et minus explicabo. Rem necessitatibus
                                    architecto quo ipsa dolorem cum maiores assumenda et galisum eveniet sit fugit dicta. Et quam doloremque quo enim porro qui odit deserunt
                                    et aperiam esse sed ipsa ipsam qui saepe neque qui incidunt possimus.</p>
                            </div>
                            <div className="job_intro_img">
                                <img src={notf} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="job_list_sectn">
                    <div className="job_list_type">
                        <ul>
                            <li>All</li>
                            <li className="active">Admin</li>
                            <li>HR</li>
                            <li>Career Coach</li>
                        </ul>
                    </div>
                </div>

                <Accordion className='notfction_accrd' defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Lorem ipsum dolor sit amet. Et galisum ipsum aut sint expedita eos totam.
                            <p className='by_date'> <span>Admin</span>  <span>Now</span></p>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            Lorem ipsum dolor sit amet. Et galisum ipsum aut sint expedita eos totam
                            <p className='by_date'> <span>Admin</span>  <span>21 Feb 2023</span></p>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            Lorem ipsum dolor sit amet. Et galisum ipsum aut sint expedita eos totam
                            <p className='by_date'> <span>Admin</span>  <span>21 Feb 2023</span></p>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </div>
        </>
    )
}