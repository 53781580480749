import React from "react";
import axios from "axios";
import { countonus } from "../Api_list";
const Count = () => { 
  const [post, setPost] = React.useState(null);
  React.useEffect(() => {
    var headerData = {"headers":{
      "Accept": "application/json",
      "Content-Type": "application/json",
      "authorization": window.HEADERSAUTH,
    }} 
    axios.get(countonus, headerData).then((response) => {
      setPost(response.data);
    });
  }, []);
  if (!post) return null;
  var getData = post.data;
    return (
        <>
            <div className="container">
                <div className="row count_us myy-d">
                  <div className="col-sm-6">
                    <div className="p-boxx pbx1 count_txt">
                       <h2>Count On Us To  <span>Accelerate</span> Your <span>Career</span></h2>
                       <p>{getData.title1}</p>
                       <p>{getData.title2}</p>
                       <p>{getData.title3}</p>
                    </div>
                  </div>
                  <div className="col-sm-6 count_imgs">
                      <div className="cmpny_img">
                        <img src={  window.IMGURL + getData.logo1 } alt={getData.alt_tag1} />
                      </div>
                      <div className="cmpny_img">
                        <img src={  window.IMGURL + getData.logo2 } alt={getData.alt_tag2} />
                      </div>
                      <div className="cmpny_img">
                        <img src={  window.IMGURL + getData.logo3 } alt={getData.title3} />
                      </div>
                      <div className="cmpny_img">
                        <img src={  window.IMGURL + getData.logo4 } alt={getData.alt_tag4} />
                      </div>
                      <div className="cmpny_img">
                        <img src={  window.IMGURL + getData.logo5 } alt={getData.alt_tag5} />
                      </div>
                      <div className="cmpny_img">
                        <img src={  window.IMGURL + getData.logo6 } alt={getData.alt_tag6} />
                      </div>
                  </div>
                </div>
            </div>
        </>
    )
}
export default Count;