import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import NotificationSlider from "./Notification-bar"

const MyModal = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);
  const handleClose = () => setShow(false);

  return (
    <Modal className='notification_modal' show={show} onHide={handleClose} centered>
      <div className='notfication_section'>
        <Modal.Header className='notf_hdr' closeButton>
          <div className='dflexbtwn w-100'>
            <h3>Latest Notifications</h3>
            {/* <a href='#'>View all</a> */}
          </div>
        </Modal.Header>
        <Modal.Body>
          <NotificationSlider />
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default MyModal;
