import React, { useState, useEffect } from 'react';
import axios from "axios";
import PhoneIcon from '@mui/icons-material/Phone';
import bnrimg from "../../src/images/homebnr.png";
import shp1 from "../../src/images/dotsg.png";
import shp2 from "../../src/images/dotsr.png";
import { enquirysubmit, courselistdropdown, statebycountryname, citiesbystatename } from "../Api_list";
import MyModal from "./Modal-notifications";

const Home_enq = () => {
  const [name, setName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [statelist, setStatelist] = useState([]);
  const [courselist, setCourselist] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [cityDropdown, setCityDropdown] = useState([]);
  const [email, setEmail] = useState("");
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerData = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: window.HEADERSAUTH,
          },
        };

        const stateResponse = await axios.get(statebycountryname, headerData);
        setStatelist(Array.isArray(stateResponse.data) ? stateResponse.data : []);
        const courseResponse = await axios.get(courselistdropdown, headerData);
        setCourselist(Array.isArray(courseResponse.data) ? courseResponse.data : []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const encodedState = encodeURIComponent(selectedState);

      axios.get(`${citiesbystatename}${encodedState}`)
        .then(response => {
          setCityDropdown(Array.isArray(response.data) ? response.data : []);
        })
        .catch(error => {
          console.error('Error fetching city data:', error);
        });
    } else {
      setCityDropdown([]);
    }
  }, [selectedState]);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let ch = isChecked ? 'Internship' : 'job';

    const post = {
      full_name: name,
      email_id: email,
      looking_for: ch,
      type: 'career_planing',
      mobile_no: mobileNumber,
      course_name: selectedCourse,
      state_name: selectedState,
      city_name: selectedCity
    };

    if (name && mobileNumber.length === 10) {
      try {
        const headerData = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: window.HEADERSAUTH,
          }
        };
        console.log(post)
        const res = await axios.post(enquirysubmit, post, headerData);

        if (res.status === 200) {
          setMessage('Thank you, your call back request has been submitted successfully!');
          setTimeout(() => setMessage(''), 5000);
          setName("");
          setMobileNumber("");
          setEmail("");
          setSelectedCourse('');
          setSelectedState('');
          setSelectedCity('');
        } else {
          setMessage("Some error occurred");
          setTimeout(() => setMessage(''), 5000);
        }
      } catch (e) {
        setMessage("Some error occurred");
        setTimeout(() => setMessage(''), 5000);
      }
    } else {
      setMessage("Please enter a valid mobile number");
      setTimeout(() => setMessage(''), 5000);
    }
  };

  return (
    <>
      <section className="homebanner">
        <div className="container">
          <div className="row j-align-center top_form_row">
            <div className="col-sm-6">
              <div className="bnrtext_form">
                <h1>
                  <span>Start</span> With India’s Most Trusted{" "}
                  <span>Best Career</span> Planning Platform
                </h1>
                <p>
                  Unlock Your Potential, Embrace Your Future!
                  <div className="message">{message && <p style={{ color: message.includes("Thank you") ? 'green' : 'red' }}>{message}</p>}</div>
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 col-12 col-lg-12 form-element">
                      <label>Name</label>
                      <input type="text" onChange={(e) => setName(e.target.value)} value={name} className="form-control" placeholder="Enter Your Full Name" />
                    </div>
                    <div className="col-md-6 col-12 col-lg-6 form-element">
                      <label>Contact Number</label>
                      <input type="text" onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} className="form-control" maxLength={10} placeholder="Enter Your Contact Number" />
                    </div>
                    <div className="col-md-6 col-12 col-lg-6 form-element">
                      <label>Email</label>
                      <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" placeholder="Enter Your Email" />
                    </div>
                    <div className="col-md-12 col-12 col-lg-12 form-element">
                      <label>Course</label>
                      <select className="form-select" onChange={handleCourseChange} value={selectedCourse}>
                        <option>--Choose Course--</option>
                        {courselist.map((course) => (
                          <option key={course.id} value={course.course_name}>
                            {course.course_name} 
                          </option> 
                        ))}
                      </select> 
                    </div>
                    <div className="col-md-6 col-12 col-lg-6 form-element">
                      <label>State</label>
                      <select className="form-select" onChange={handleStateChange} value={selectedState}>
                      <option>--Choose State--</option>

                        {statelist.map((state) => (
                          <option key={state.id} value={state.state_name}>
                            {state.state_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 col-12 col-lg-6 form-element">
                      <label>City</label>
                      <select className="form-select" onChange={handleCityChange} value={selectedCity}>
                      <option>--Choose State First--</option>
                        {cityDropdown.map((city) => (
                          <option key={city.id} value={city.city_name}>
                            {city.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="row flex-reverse">
                      <div className="col-md-8 col-12 col-lg-8 form-element">
                        <div className="form-check form-switch">
                          <input className="form-check-input" onChange={handleOnChange} checked={isChecked} type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Looking for Internship </label>
                        </div>
                      </div>
                      <div className="col-md-4 col-12 col-lg-4 form-element">
                        <button type="submit" className="callme sitebtn">  Submit</button>
                      </div>
                    </div>
                    <div className="col-md-12 col-12 col-lg-12">
                      <p>By submitting this form, you accept and agree to our <a href="/terms-and-conditions">Terms of Use</a></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="bnr_shpwrpr">
                <img src={shp1} className="shp1 movingup" />
                <img src={bnrimg} className="bnrimg" alt="Career Planning" />
                <img src={shp2} className="shp2 moving" />
              </div>
            </div>
          </div>
        </div>
        <MyModal />
      </section>
    </>
  );
}

export default Home_enq;
