import React from 'react';
import axios from 'axios';
import { websetting } from '../Api_list'
const Query = () => {

    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(websetting, headerData).then(( response )=>{
            setPost( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data;

    return (
        <>

            <section className="abs-picture" id="collsection">
                <div className="container abs-bg">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>For Queries, Feedback &amp; Assistance</h4>
                            <h3><strong>Contact us any time 24x7</strong></h3>
                            <h3>(7 AM - 12 AM)</h3>
                            <p>For Indian Nationals</p>
                            <a href={ 'tel:'+getData.mobile_no } className="button-bg btn-primary">
                                <i className="fa fa-phone" aria-hidden="true"></i>  <span>Call Us</span> </a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Query;