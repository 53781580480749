import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import j2 from "./images/j2.png";
import { jobtypeactivelist, jobformlistforweb , jobdetails, appliedstudent} from "../Api_list"; 
import FmdGoodIcon from '@mui/icons-material/FmdGood';
let obj = JSON.parse( localStorage.getItem('loginddata') );
let color = {};
 
function MyVerticallyCenteredModal(props) {
    const [jobdetail, setJobdetail] = useState("");
    const [message, setMessage]     = useState("");
    const [propsdata, setpropsdata] = useState("")
    var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
      }}
        if((propsdata != props.data) && (props.data.length > 0)){
             
        axios.get( jobdetails+props.data, headerData).then((responses)=>{
            setJobdetail(responses.data.data);
            setpropsdata(props.data)
        })
    
    }
    let SubmitForm = async (e) => {
        const postdata = {
            student_name  : obj.full_name, 
            student_id    : obj._id, 
            job_id        : e
        }
        try {
        const res = await axios.post(appliedstudent, postdata, headerData)
        if (res.status === 200) {
            color.color = 'green';
                setMessage(res.data.message);
                setTimeout(() => {
                setMessage('');
                }, 5000);
            } else {
                color.color = 'red';
                setMessage(res.data.message);
            }
        } catch (e) {
       
        }
    }
     return (
         <Modal className="job_modal"
             {...props}
             size="lg"
             aria-labelledby="contained-modal-title-vcenter"
             centered
         >
             <Modal.Header closeButton>
             <div className="messagex">{message ? <p style={color}>{message}</p> : null}</div> 
                 <div className="jbimg">
                     <img src={(jobdetail) ? window.IMGURL+jobdetail.company_logo : j2} />
                 </div>
                 <a href="#!" onClick={() => SubmitForm(props.data)} className="applybtn"> Apply Now </a>
               
             </Modal.Header>
             <Modal.Body>
                 <div className="row">
                     <div className="col-sm-8">
                         <h5>
                         {(jobdetail) ? jobdetail.job_title : ""}
                         </h5> 
                         <div className='blgdtl_txt' dangerouslySetInnerHTML={{ __html: (jobdetail) ? jobdetail.about_job : "" }} />
                     </div>
                     <div className="col-sm-4">
                         <div className="job_popupright">
                             <h4>Job Type</h4>
                             <p>{(jobdetail) ? jobdetail.job_type : ""}</p>
                             <h4>Job location</h4>
                             <p><FmdGoodIcon/> {(jobdetail) ? jobdetail.city : ""}</p>
                             <h4>Application Closes</h4>
                             <p>{(jobdetail) ? moment(jobdetail.application_end).format("DD MMM’YYYY h:mm a") : ""}</p> 
                             <h4>Package</h4>
                             <p className="job_prc">{ (jobdetail) ? jobdetail.package_currency : ""} {  (jobdetail) ?  jobdetail.package_range_start : ""} - { (jobdetail) ?  jobdetail.package_range_end : "" } per month</p>
                         </div>
                         
                     </div>
                 </div>
 
             </Modal.Body>
         </Modal>
     );
 }


const Job_oppurtunity = () => {
    const [modalShow, setModalShow] = useState('');
    const [ post, setPost ] = useState(null);
    const [ jobs, setJobs ] = useState(null);
    const [actclass, setactClass] = useState('');
    const [component, setComponent] = useState(null);
    var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
      }}
     React.useEffect(()=>{
        axios.get(jobtypeactivelist, headerData).then(( response )=>{
            setPost( response.data )
        })
        axios.get(jobformlistforweb, headerData).then(( response )=>{
            setJobs( response.data )
        })
     }, [])
     if( !post ) return null;
     let jobtypsdata = post.data;

     if( !jobs ) return null;
     let jobdata = jobs.data;
     jobdata = jobdata.slice(0, 10);
     function LoadComponent(id) {
        setactClass(id);
        document.getElementById('coursedata').innerHTML = "";
        getaxiosdata( id );
      }

      function getaxiosdata( c ){
        axios.get( jobformlistforweb+'?jobtype='+c, headerData).then((responses)=>{
                    let  getData2  = responses.data.data;
                    getData2 = getData2.slice(0, 10);
                    let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                        <div className="job_list">
                            <img src={window.IMGURL + getData2.company_logo} />
                            <div className="job_text">
                                <div className="job_lr">
                                    <div className="job-left" >
                                        <h4>{ getData2.job_title }</h4>
                                        <ul>
                                            <li><FmdGoodIcon /> { getData2.city }</li>
                                            <li><span className="c_dot"></span> { getData2.job_type }</li>
                                        </ul>
                                        <p className="job_prc"> { getData2.package_currency } { getData2.package_range_start } - { getData2.package_range_end } per month </p>
                                    </div>
                                    <div className="job-right">
                                        <a href="" className="applybtn">Read More</a>
                                    </div>
                                </div>
                                <div className="jb_close">
                                    <p>Application closes on: {moment(getData2.added_on).format("DD MMM’YYYY h:mm a")}</p>
                                </div>
                            </div>
                        </div>
                    ))) : "";
                    setComponent(storecorse);
               })
    }
    return ( 
        <>
            <div className="opprtnty_sectn">
                <h3 className="dash_hdng">Jobs Opportunities</h3>
                <div className="oppurtnty_box">
                    <div className="job_type">
                        <ul>
                        <li onClick={() => LoadComponent('')} className={actclass === '' ? "active" : ""  } >All</li>
                        { jobtypsdata.map( jobtypsdata => (
                             <li  onClick={() => LoadComponent(jobtypsdata.job_type)}  className={actclass === jobtypsdata.job_type ? "active" : ""  }>{jobtypsdata.job_type}</li>   
                             )) }
                        </ul> 
                    </div>
                    <div className="job_display" >
                        <div  id="coursedata">
                            { jobdata.map( jobdata => (
                                <div className="job_list">
                                    <img src={window.IMGURL + jobdata.company_logo} alt={ jobdata.job_title }/>
                                    <div className="job_text">
                                        <div className="job_lr">
                                            <div className="job-left" >
                                                <h4>{ jobdata.job_title }</h4>
                                                <ul>
                                                    <li><FmdGoodIcon /> { jobdata.city }</li>
                                                    <li><span className="c_dot"></span> { jobdata.job_type }</li>
                                                </ul>
                                                <p className="job_prc"> { jobdata.package_currency } { jobdata.package_range_start } - { jobdata.package_range_end } per month </p>
                                            </div>
                                            <div className="job-right">
                                                <a className="dtl_btn" onClick={() => setModalShow(jobdata._id)} className="applybtn">Read More</a>
                                            </div>
                                        </div>
                                        <div className="jb_close">
                                            <p>Application closes on: {moment(jobdata.added_on).format("DD MMM’YYYY h:mm a")}</p>
                                        </div>
                                    </div>
                                </div>
                            )) } 
                        </div>

                        {component}
                    </div>

                    <div className="vw_all">
                        <a className="vwall_btn" href="job-listing"> View All</a>
                    </div> 
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                data={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Job_oppurtunity;


