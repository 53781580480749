import React from "react";
import { NavLink } from "react-router-dom";
import logo from "./images/logo.png";
import profile_img from "./images/profile.png";

// import LockIcon from "@mui/icons-material/Lock";
// import PersonIcon from "@mui/icons-material/Person";
import styled from 'styled-components';
import Burger from './Dash-burger';
// import { NavLink } from "react-router-dom";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Bottom_fixed from "./Bottom-fixed";

const Nav = styled.nav`
  width:100%;
  padding:0 20px;
  display:flex;
  justify-content: space-between;
  align-items:center;

  .logo {
    padding: 15px 0;
  }
`

const Navbar_dash = () => {
    let obj = JSON.parse( localStorage.getItem('loginddata') );

    return (
        <>
            <div className="bgblack">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 dashmenu">
                            <Nav> 
                                <div className="logo_prof_wrp">
                                    <div className="logo">
                                        <NavLink className="navbar-brand" to="/">
                                            <img src={logo} className="logoimg" alt="Logo" />
                                        </NavLink>
                                    </div>
                                    <div className="prof_notf d-flex">
                                        <NotificationsNoneIcon />
                                        <div className="head_prf  d-flex">
                                            <a className="d-flex prfl_link"  href="/student/profilepage">
                                                <div className="hdrprf_txt">
                                                    <h5>{obj.full_name}</h5>
                                                    <p>Available</p>
                                                </div>
                                                <img className="profileimg" src={(obj.profile_pic) ? window.IMGURL + obj.profile_pic : profile_img} />
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                                <Burger /> 
                                <div className="packg_active">
                                    <p>{(obj.plan_name) ?  "Active" : "N/A"} :</p>
                                    <p className="pkgname"> {(obj.plan_name) ?  obj.plan_name : "N/A"} </p>
                                </div> 
                            </Nav>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom_fixed />

        </>
    );
};


export default Navbar_dash;
