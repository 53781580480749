import React from 'react'
import jbl from "./images/jbl_img.png";
import JobListingSection from "./Job-list-sec";

export default function JobListing() {
  return (
    <>
        <div className="job_intro_container pt-0">
            <div className="container"> 
                <div className="row">
                    <div className="col-md-12">
                        <div className="job_intro_content">
                            <div className="job_intro_text">
                                <h3>Job Listing</h3>
                                <p>Lorem ipsum dolor sit amet. Est alias dolorem ea quasi numquam et natus voluptate id soluta blanditiis vel consequuntur explicabo aut 
                                    placeat deleniti est provident exercitationem! Non deserunt officiis qui quisquam exercitationem et minus explicabo. Rem necessitatibus
                                   architecto quo ipsa dolorem cum maiores assumenda et galisum eveniet sit fugit dicta. Et quam doloremque quo enim porro qui odit deserunt
                                   et aperiam esse sed ipsa ipsam qui saepe neque qui incidunt possimus.</p>
                            </div>
                            <div className="job_intro_img">
                                <img src={jbl} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <JobListingSection/>
                </div>
            </div>
        </div>
    </>
  )
}
