import React, { useState } from 'react';
import axios from 'axios';
import { approvedlist } from './Api_list';
const Approved = () => {
     const [ post, setPost ] = useState(null);
     React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}
        axios.get(approvedlist, headerData).then(( response )=>{
            setPost( response.data )
        })
     }, [])
     if( !post ) return null;
     let getData = post.data;
    return (
        <>
            <div className="container">
                <div className="row text-center">
                    <h3 className='site_hdng'>Approved. Respected. Trusted.</h3>
                    <p>At Career Eduversity | The Shift in Hiring Priorities | Internship Programs | Industry Collaborations | Skill-based Training | Mentorship and Guidance | Building a Strong Professional Network | Continuous Learning and Development | Scholarships and Financial Aid | Affordability as a Priority | Efficient Resource Allocation</p>
                </div>
                <div className="row">
                    { getData.map( getData => (
                        <div className="col-md-3 col-6">
                        <div className="imgs"><img src={ window.IMGURL + getData.image } alt={getData.alt_tag} /></div>
                    </div>
                    )) }
                    
                </div>
            </div>

        </>
    )
}

export default Approved;