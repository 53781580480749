import React from "react";
import axios from "axios";
import PhoneIcon from '@mui/icons-material/Phone';
import Ind_tra_logo from "./Industry_training_logo";
// import interns from "../src/images/interns.png";
import { studentbenfit, websetting } from "./Api_list";

  const Industry_training = () => {
  const [ post, setPost ]= React.useState( null );
  const [settingData, setsettingData] = React.useState(null);

  React.useEffect(()=>{
    var headerData = {"headers":{
      "Accept": "application/json",
      "Content-Type": "application/json",
      "authorization": window.HEADERSAUTH,
    }} 
    axios.get( studentbenfit + 'training_content', headerData ).then(( response )=>{
      setPost( response.data );
    })
    axios.get(websetting, headerData).then(( response )=>{
      setsettingData( response.data );
  })
  }, []);
  if ( !post ) return null;
  let getData = post.data;

  if (!settingData) return null;
    var SettingDatas = settingData.data;
    return (
        <>
            <section className="indstry_sec padd_50">
                <div className="container">
                    <div className="row centr_hdng_txt">
                       <h2 className="sitehdng">Industry <span>Training</span> and <span>Internship</span> Solution</h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-sm-5 indstr_txt">
                          <h3>Curriculum tailored to your need</h3>
                          <div className="currclm">
                          {getData.map(getData => (
                            <div className="d-flex crclm_txt"> 
                              <img src={ window.IMGURL + getData.icon } alt={ getData.alt_tag} />
                              <p>{ getData.heading }</p>
                            </div>
                          ))}
                          </div>
                          <a href={ 'tel:'+SettingDatas.mobile_no } className="sitebtn"> <PhoneIcon /> Talk To Our Councelor</a>                           
                        </div>
                        <div className="col-sm-7">
                           <Ind_tra_logo/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}


export default Industry_training;
