import React from "react";
import axios from "axios";
import YoutubeEmbed from "../Cmpnt/Youtube_iframe";
import { cmsdetail } from "../Api_list";
const About_top_bg = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}
        axios.get(cmsdetail+'about-us', headerData).then(( response )=>{
            setPost( response.data )
        })
    },[])
    if( !post ) return null;
    var getData = post.data;
    return (
        <> 
            <section className='bg-voilet abt_topsec hireform_sec'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-8'>
                            <div className="whysbs_top">
                                <div class="txt_img">
                                    <h2 className="sitehdng">About</h2>
                                    <p>{getData.shortcontent}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="vdosec">
                                <YoutubeEmbed embedId={getData.youtube_id} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About_top_bg;