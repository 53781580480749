import React from 'react';
import unimg from './images/uni-img.png';
import uiw1 from './images/uni-icons/uiw1.png';
import uiw2 from './images/uni-icons/uiw2.png';
import uiw3 from './images/uni-icons/uiw3.png';
import uiw4 from './images/uni-icons/uiw4.png';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
const Uni_brief = (props) => {
    const [ post, setPost ] = React.useState( props.data );
    let settingData = props.setting;
    return (
        <>
            <section className='unibrfsec coursebrf_sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='crs_brftxt'>
                                <h2>{post.univ_name}, {post.state}</h2>
                            </div>
                            <div className='univ_name d-flex flex-wrap'>
                                <div className='uni_short'>
                                    <div className='d-flex flex-wrap'>
                                        <div className='unvshrt_d'>
                                            <img src={uiw1} />
                                            <span>{post.address}</span>
                                        </div>
                                        <div className='unvshrt_d'>
                                            <img src={uiw2} />
                                            <span>{post.univ_type}</span>
                                        </div>
                                        <div className='unvshrt_d'>
                                            <img src={uiw3} />
                                            <span>{post.univ_est_year}</span>
                                        </div>
                                        <div className='unvshrt_d'>
                                            <img src={uiw4} />
                                            <span>{post.univ_acred_by.join(", ")}</span>
                                        </div> 
                                    </div> 
                                    <div className='d-flex soc_icn'>
                                        <p>Share This:</p>
                                        <a target="_blank" href={settingData.facebook_link}><FacebookOutlinedIcon /></a>
                                        <a target="_blank" href={settingData.instagram_link}><InstagramIcon /></a>
                                        <a target="_blank" href={settingData.twitter_link}><TwitterIcon /></a>
                                        <a target="_blank" href={settingData.youtube_link}><YouTubeIcon /> </a>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className='col-sm-6'>
                            <div className='imgsec'>
                                <img src={(post.univ_slider) ? window.IMGURL+post.univ_slider[0].slider :   unimg} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Uni_brief;