let APIURL          = "https://sindonbschool.com/api/v1/";
let cmslist         = APIURL + "cmslist?type=";
let homeslider      = APIURL + "homeslider";
let test_recruiter  = APIURL + "testimonial?type=recruiter";
let reapbenf        = APIURL + 'reapbenf';
let countonus       = APIURL + 'countonus';
let advymbrslist    = APIURL + 'advymbrslist';
let trustedstudent  = APIURL + 'trustedstudent';
let cmsdetail       = APIURL + 'cmsdetail?slug=';
let teamlist        = APIURL + 'teamlist?type=';
let studentbenfit   = APIURL + 'studentbenfit?type=';
let common_faq      = APIURL + 'faqlist?type=';
let planlist        = APIURL + 'planlist';
let enquirysubmit   = APIURL + 'enquirysave';
let zonelist        = APIURL + 'zonelist';
let branchwisecourse = APIURL + 'branchwisecourse';
let courselist = APIURL + 'coursewisedata?course_name=';
let admissionstep = APIURL + 'admissionstep';
let approvedlist = APIURL + 'approvedlist'; 
let univcourse   = APIURL + 'univcourse?univ_slug='
let coursedetails = APIURL + 'coursedetails?univ_slug=';
let univdetails = APIURL + 'univdetails?slug=';
let univcourselist = APIURL + 'univcourselist?univ_slug=';

let accreditation = APIURL + 'acred_univ?name=';
let statelist = APIURL + 'stateslist';
let citieslist = APIURL + 'cities?name=';

let login         = APIURL + 'step_1';
let otpmatchresend           = APIURL + 'step_2';
let registerfirst   = APIURL + 'step_3';
let registersecond   = APIURL + 'step_4';
let payment_session  = APIURL + 'payment';
let websetting = APIURL + 'websetting';
let addtocart  = APIURL  + 'addtocart'
let cartdata = APIURL + 'cartdata?user_id=';
let couponlist = APIURL + 'couponlist?min_amount=';
let validatereferal = APIURL + 'validatereferal?referalcode=';
let validatecoupon = APIURL + 'validatecoupon';
let paymentVarify = APIURL + 'paymentVarify?order_id=';
let student_plan = APIURL + 'student_plan?user_id='
let  planactivate = APIURL + 'planactivate?user_id=';

let  activecitylist = APIURL + 'activecitylist';
let  zoneactivelist = APIURL + 'zoneactivelist';
let  jobtypeactivelist = APIURL + 'jobtypeactivelist';
let  jobformlistforweb = APIURL + 'jobformlistforweb';
let  bloglist = APIURL + 'bloglist?type=';
let  jobdetails = APIURL + 'jobformdetailforweb?id=';
let  appliedstudent = APIURL + 'appliedstudent';
let  userDetails = APIURL + 'userDetails?id=';

let jobcountwithstatus = APIURL + 'jobcountwithstatus?id=';
let joblistbyuser = APIURL + 'joblistbyuser?id=';

let timeslot = APIURL + 'timeslot?id=';
let booktimeslot = APIURL + 'booktimeslot';
let notiflistbyuser = APIURL + 'notiflistbyuser?addby='
let studentprofileupdate = APIURL + 'studentprofileupdate'
let notiflist = APIURL + 'notiflist?addby='
let studentappointmentlist = APIURL + 'studentappointmentlist?id='
let appointmentdetails = APIURL + 'appointmentdetails?id='
let studentmeetingfeedback = APIURL + 'studentmeetingfeedback'

let alumnilist = APIURL + 'alumnilist?type=';
let internshipSavedata = APIURL + 'internshipSavedata'
let chatlist = APIURL + 'chatlist?sender='
let chatSavedata = APIURL + 'chatSavedata'
let zonewiseuniversity = APIURL + 'zonewiseuniversity'
let courselistdropdown = APIURL + 'courselistdropdown'
let statebycountryname = APIURL + 'statebycountryName?name=India'
let citiesbystatename = APIURL + 'citiesbystateName?name='
let webnotification = APIURL + 'webnotification'

export {websetting, cmslist, homeslider, test_recruiter, reapbenf, countonus, advymbrslist, trustedstudent, cmsdetail, teamlist, studentbenfit, common_faq, planlist, enquirysubmit, zonelist, branchwisecourse, courselist, admissionstep, approvedlist, coursedetails, univcourse, accreditation,login, otpmatchresend, statelist, citieslist, registerfirst,registersecond, payment_session, addtocart, cartdata, couponlist, validatereferal, validatecoupon, paymentVarify, student_plan, planactivate, activecitylist, zoneactivelist, jobtypeactivelist, jobformlistforweb, bloglist, jobdetails, appliedstudent, userDetails,
    jobcountwithstatus, joblistbyuser, timeslot, notiflistbyuser, booktimeslot, studentprofileupdate, notiflist, studentappointmentlist, appointmentdetails, studentmeetingfeedback, alumnilist, internshipSavedata, chatlist, chatSavedata, zonewiseuniversity, univdetails, univcourselist, courselistdropdown,statebycountryname, citiesbystatename, webnotification };
 