import React from "react";
import axios from "axios";
import Slider from "react-slick";
import SouthIcon from '@mui/icons-material/South';
import { trustedstudent } from '../Api_list'

const Studentslider = () => {
   const[ post, setPost] = React.useState( null );
   React.useEffect(()=>{
    var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
      }} 
       axios.get(trustedstudent, headerData).then(( response )=>{
           setPost( response.data )
       })
   },[]);
   if( !post ) return null;
   var getData = post.data;
   let count = 4;
   if( count > getData.length ){
       count = getData.length;
   }
   const settings = {
       dots: true,
       infinite: true,
       arrows: false,
       speed: 500,
       slidesToShow: count,
       slidesToScroll: count,
       responsive: [
         {
           breakpoint: 767,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 2
           }
         },
      ]
     };

    return (
        <>
        <section className="container students_sec padd_50">
                <div className="row centr_hdng_txt">
                    <h2 className="sitehdng"> 850+ Students have trusted Us </h2>
                </div>
        <div className="student_slider">
            <Slider {...settings}>
            {getData.map((getData, index) => (
                <div className="student_slide">
                    <div className="stud_img">
                       <img src={  window.IMGURL + getData.profile_pic } alt={getData.full_name}/>
                       </div>
                       <p>{getData.full_name}</p>
                       <SouthIcon />
                       <div className="cmp_img">
                          <img src={  window.IMGURL + getData.com_logo } alt={getData.full_name}/>
                       </div>
                       <span className="postn">{getData.designation}</span>
                </div>
            ))}
            </Slider>
        </div>
        </section>
        </>
    )
}

export default Studentslider;