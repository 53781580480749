import React from "react";
import Footer from "./partial/Footer";
import Query from "./Cmpnt/Query_sec";
import Admission from "./Admission_process";
import Studentslider from "./Cmpnt/Student_trust";
// import Interest_dtltext from "./services/Interest_details_text";
import Blog_dtltext from "./blog/Blog_details_text";


const Know_interest = () => {
    return (
        <>
            
            {/* <Interest_dtltext /> */}
            <Blog_dtltext />
            <Admission />
            <Studentslider />
            <Query />
            <Footer />
        </> 
    )
}

export default Know_interest;






