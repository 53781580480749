import React, { useState } from 'react';
import axios from 'axios';
import intrn from "./images/intrn.png";
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { internshipSavedata } from '../Api_list';

let obj = JSON.parse( localStorage.getItem('loginddata') );
let color = {};
export default function Internship_form_enrolled() {
    const [message, setMessage]                 = useState("");
    const [University, setUniversity]           = useState("");
    const [Course, setCourse]                   = useState("");
    const [Semester, setSemester]               = useState("");
    const [Specialization, setSpecialization]   = useState("");
    const [Introduction, setIntroduction]       = useState("");
 
    let handleSubmit = async (e) => {
        e.preventDefault();
        const postData = {
            student_id          : obj._id,
            full_name           : obj.full_name,
            email_id            : obj.email_id, 
            mobile_no           : obj.mobile_no,
            university_name     : University,
            course_name         : Course, 
            semester_year       : Semester,
            specialization      : Specialization, 
            brief_introduction  : Introduction,
        } 
      if((University.length != 0) && (Course.length != 0)){
        try {
            var headerData = {"headers":{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "authorization": window.HEADERSAUTH,
              }}
          const res = await axios.post(internshipSavedata, postData, headerData)
          if (res.status === 200) {
            color.color = 'green';
            setUniversity('');setCourse('');setSemester('');
            setSpecialization('');setIntroduction('');
                setMessage(res.data.message);
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              } else {
                color.color = 'red';
                setMessage("Some error occured");
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              }
        } catch (e) {
          color.color = 'red';
          setMessage("Some error occured");
          setTimeout(() => {
            setMessage('');
          }, 5000);
        }
      }else{
        color.color = 'red';
        setMessage("Please enter mandatory fields!"); 
        setTimeout(() => {
          setMessage('');
        }, 5000);
      }
      };


    return (
        <>
            <div className='container ptb_50'>
                {/* <div className="row">
                    <div className="col-md-12">
                        <div className="job_intro_content">
                            <div className="job_intro_text">
                                <h3>Internship Form</h3>
                                <p>Lorem ipsum dolor sit amet. Est alias dolorem ea quasi numquam et natus voluptate id soluta blanditiis vel consequuntur explicabo aut
                                    placeat deleniti est provident exercitationem! Non deserunt officiis qui quisquam exercitationem et minus explicabo. Rem necessitatibus
                                    architecto quo ipsa dolorem cum maiores assumenda et galisum eveniet sit fugit dicta. Et quam doloremque quo enim porro qui odit deserunt
                                    et aperiam esse sed ipsa ipsam qui saepe neque qui incidunt possimus.</p>
                            </div>
                            <div className="job_intro_img">
                                <img src={intrn} alt="" />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='formbox'>
                    <div className='frmhdng'>
                        <h4>Internship Application</h4>
                        <div className="message">{message ? <p style={color}>{message}</p> : null}</div> 

                    </div>
                    <div className='personal_info_from'>
                        <Form onSubmit={handleSubmit}>
                            <div className="row gx-3">
                                <div className="col-md-6">
                                    <Form.Group className="form-group">
                                        <Form.Label>University</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setUniversity(e.target.value)} value={University}  required/>
                                    </Form.Group>
                                </div> 
                                <div className="col-md-6">
                                    <Form.Group className="form-group">
                                        <Form.Label>Course</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setCourse(e.target.value)} value={Course} required/>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="form-group">
                                        <Form.Label>Semester/Year</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setSemester(e.target.value)} value={Semester} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="form-group">
                                        <Form.Label>Specialisation</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setSpecialization(e.target.value)} value={Specialization} required/>
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Brief Introduction</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            onChange={(e) => setIntroduction(e.target.value)} value={Introduction}
                                            style={{ height:'150px' }}
                                        />
                                    </Form.Group>
                                </div>
                                <Button variant="primary" type="submit"> Submit </Button>
                            </div>
                        </Form>
                    </div>
                </div>

            </div>
        </>
    )
}