import React from "react";
import axios from "axios"; 
import PhoneIcon from '@mui/icons-material/Phone';
import mob_councelor  from "../../src/images/mob_councelor.png";
import { websetting } from '../Api_list';

const Councelor = () => {
    const [settingData, setsettingData] = React.useState(null);
    React.useEffect(() => {
 
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
 
      axios.get(websetting, headerData).then(( response )=>{ 
        setsettingData( response.data );
    })
    }, []); 
    if (!settingData) return null;
    var SettingDatas = settingData.data;

    return (
        <>
            <section className="container councelor_sec" >
                <div className="row">
                    <div className="col-sm-6">
                        <div class="txt_img">
                            <h2 className="sitehdng"><span>Build</span> career <span>clarity</span> and become part of a <span>1000+ student </span>
                                member community</h2>
                            <p>We are revolutionising the way skills, experiences, and job placements outcomes are delivered.
                                Join the largest community and fast forward your career with Career Eduversity.</p>
                            <a href={ 'tel:'+SettingDatas.mobile_no }  className="sitebtn"> <PhoneIcon /> Talk To Our Councelor</a>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mob_show">
                          <img src={mob_councelor} />
                        </div> 
                        <div className="pckges">
                            <div className="flt_box bx1">
                                <h5>43 LPA</h5>
                                <p>Highest Salary</p>
                            </div>
                            <div className="flt_box bx2">
                                <h5>100%</h5>
                                <p>Placement</p>
                            </div>
                            <div className="flt_box bx3 movingup">
                                <h5>254 MNC</h5>
                                <p>Hired From Us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Councelor;
