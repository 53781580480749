import React from 'react';
import avatar from "./images/avatar.png";
let obj = JSON.parse( localStorage.getItem('loginddata') );
export default function Profile_intro() {
  return (
    <>
        <div className="profile_left_box">
            <div className="profile_left_head">
                <div className="profile_left_img">
                    <img src={(obj.profile_pic) ?  window.IMGURL+obj.profile_pic : avatar} alt={obj.plan_name} />
                </div>
                <h5>{obj.full_name}</h5>
            </div>
            <div className="profile_left_body">
                <ul className="profile_left_list">
                    <li><strong>Plan:</strong>{(obj.plan_name) ?  obj.plan_name : "N/A"}</li>
                    <li><strong>Full Name:</strong>{obj.full_name}</li>
                    <li><strong>Email:</strong>{obj.email_id}</li>
                    <li><strong>Mobile Number:</strong>+91 {obj.mobile_no}</li>
                    <li><strong>Location:</strong>{obj.city_name +', '+obj.state_name}</li>
                    {/* <li><strong>Bio:</strong><br /><p>Hi I'm Johnathn Deo,has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p></li> */}
                </ul>
            </div>
        </div>
    </>
  )
}
