import React, { useState } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Profile_intro from './Profile_intro'
import {Tab, Tabs} from 'react-bootstrap'
import {Form, InputGroup} from 'react-bootstrap'
import moment from 'moment-timezone'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { studentprofileupdate } from '../Api_list';
let color = {};
let obj = JSON.parse( localStorage.getItem('loginddata') );
var splan = (obj) ? obj.student_plan : [];
const getplan = splan.find(objs => {
    return objs._id === obj.plan_id;
  });
  
let curentdate = new Date().toISOString();
  let remaildays = (getplan) ? ( new Date(getplan.valid_to) - new Date(curentdate)) / (1000 * 60 * 60 * 24) : 0;
  remaildays = Math.floor(remaildays); 
  
export default function ProfilePage() {

    const [message, setMessage]   = useState("");
    const [formData, setFormData] = useState({
        full_name       : obj.full_name,
        email_id        : obj.email_id,
        facebook_link   : obj.facebook_link,
        twitter_link    : obj.twitter_link,
        instagram_link  : obj.instagram_link,
        linkedin_link   : obj.linkedin_link,
        file            : null
      });
      const handleSubmit = async (e) => {
        e.preventDefault();
       
        const data = new FormData();
        data.append('id', obj._id);
        data.append('full_name', formData.full_name);
        data.append('email_id', formData.email_id);
        data.append('facebook_link', formData.facebook_link);
        data.append('twitter_link', formData.twitter_link);
        data.append('instagram_link', formData.instagram_link);
        data.append('linkedin_link', formData.linkedin_link);
        data.append('filename', formData.file);
        data.append('oldpic', obj.profile_pic);
        //  console.log(formData.file);
        //  console.log(data);
        try {
            const res = await axios.post(studentprofileupdate, data)
            //console.log(res.data.data); 
            if (res.status === 200) {
                console.log(res.data.data)
                localStorage.setItem( 'loginddata', JSON.stringify(res.data.data) );
                color.color = 'green';
                  setMessage(res.data.message);
                  setTimeout(() => {
                    setMessage('');
                  }, 5000);
                } else { 
                  color.color = 'red';
                  setMessage(res.data.message);
                  setTimeout(() => {
                    setMessage('');
                  }, 5000);
                }
          } catch (e) {
            color.color = 'red';
            setMessage("Some error occured");
            setTimeout(() => {
              setMessage('');
            }, 5000);
          }
      }

  return (
    <> 
       	<div className="prfile_container">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className='prfile_left'>
                            <Profile_intro />
                            <div className="prfile_plane_content  mt-2">
                                <ul className="prfile_plane_list">
                                    <li className="prfile_plane_item">
                                        <div className="prfile_plane_box">
                                            <h6>Plan:</h6>
                                            <h4>{(obj.plan_name) ?  obj.plan_name : "N/A"}</h4>
                                        </div>
                                    </li>
                                    <li className="prfile_plane_item">
                                        <div className="prfile_plane_box">
                                            <h6>Duration Days:</h6>
                                            <h4>{getplan.plan_features[0].plan_validity}</h4>
                                        </div>
                                    </li>
                                    <li className="prfile_plane_item">
                                        <div className="prfile_plane_box">
                                            <h6>Remaining Days:</h6>
                                            <h4>{remaildays} Days</h4>
                                        </div>
                                    </li>
                                    <li className="prfile_plane_item">
                                        <div className="prfile_plane_box">
                                            <h6>Expires On:</h6>
                                            <h4>{(obj) ? moment(obj.valid_to).format("DD/MM/YYYY") : ""}</h4>
                                        </div>
                                    </li>
                                    
                                    
                                    <li className="prfile_plane_item">
                                        <div className="prfile_plane_box">
                                            <h6>Status</h6>
                                            <h4>{ (getplan.plan_features[0].plan_validity - remaildays  <= getplan.plan_features[0].plan_validity) ? "Active" : "Inactive" }</h4>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className='prfile_right mt-4 mt-lg-0'>
                           <Tabs defaultActiveKey="about" transition={false} id="prfile_right_tabs" className="mb-3">
                                <Tab eventKey="about" title="About">
                                    <div className="prfile_content">
                                        <div className="prfile_content_title">
                                            <img src="images/education-icon.png" alt="" />
                                            <h4>Education Details:</h4>
                                        </div>
                                        <div className="education_details_content">
                                            <ul className="education_details_list">
                                                <li> 
                                                    <h5>{obj.course_name}</h5>
                                                    <ul className="education_details_inn_list d-flex flex-wrap">
                                                        <li>{obj.college_institute_name}</li>
                                                        <li>Year: {moment(obj.course_start_from).format("YYYY")}-{moment(obj.course_end_to).format("YYYY")}</li>
                                                    </ul>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="setting" title="Setting">
                                    <div className="prfile_content">
                                        <div className="prfile_content_title">
                                            <img src="images/education-icon.png" alt="" />
                                            <h4>Personal Info</h4>
                                            <div className="message">{message ? <p style={color}>{message}</p> : null}</div> 

                                        </div>
                                        <div className='personal_info_from'>
                                            <Form onSubmit={handleSubmit}>
                                                <div className="row gx-3">
                                                    <div className="col-md-6">
                                                       <Form.Group className="form-group">
                                                            <Form.Label>Full Name</Form.Label>
                                                            <Form.Control value={formData.full_name} onChange={(e) => setFormData({...formData, full_name: e.target.value})} type="text" />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                       <Form.Group className="form-group">
                                                            <Form.Label>Mobile Number</Form.Label>
                                                            <Form.Control  value={obj.mobile_no} readOnly={true} type="text" />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                       <Form.Group className="form-group">
                                                            <Form.Label>Email ID</Form.Label>
                                                            <Form.Control value={formData.email_id} onChange={(e) => setFormData({...formData, email_id: e.target.value})} type="email" />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                       <Form.Group className="form-group">
                                                            <Form.Label>Profile Pic</Form.Label>
                                                            <Form.Control onChange={(e) => setFormData({...formData, file: e.target.files[0]})} type="file" />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h4 className="personal_info_title">Social Links</h4>
                                                    </div>
                                                    <div className="col-md-6">
                                                       <Form.Group className="form-group">
                                                            <Form.Label>facebook</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text id="basic-addon1"><FacebookIcon /></InputGroup.Text>
                                                                <Form.Control value={formData.facebook_link} onChange={(e) => setFormData({...formData, facebook_link: e.target.value})}
                                                                aria-label="facebook"
                                                                aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                       <Form.Group className="form-group">
                                                            <Form.Label>Twitter</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text id="basic-addon1"><TwitterIcon /></InputGroup.Text>
                                                                <Form.Control value={formData.twitter_link} onChange={(e) => setFormData({...formData, twitter_link: e.target.value})}
                                                                aria-label="Twitter"
                                                                aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                       <Form.Group className="form-group">
                                                            <Form.Label>Instagram</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text id="basic-addon1"><InstagramIcon /></InputGroup.Text>
                                                                <Form.Control value={formData.instagram_link} onChange={(e) => setFormData({...formData, instagram_link: e.target.value})}
                                                                aria-label="instagram"
                                                                aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                       <Form.Group className="form-group">
                                                            <Form.Label>LinkedIn</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text id="basic-addon1"><LinkedInIcon /></InputGroup.Text>
                                                                <Form.Control value={formData.linkedin_link} onChange={(e) => setFormData({...formData, linkedin_link: e.target.value})}
                                                                aria-label="linkedIn"
                                                                aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <Button variant="primary" type="submit">
                                Submit
                            </Button>
                                            </Form>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <UpSkill />
        <Chat /> */}
    </>
  )
}
