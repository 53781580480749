import React from 'react';
import axios from 'axios';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { websetting } from './Api_list'
const Contact_left = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(websetting, headerData).then(( response )=>{
            setPost( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data;
    return (
        <>
            <div className=''>
                <div className='cont_bx d-flex'>
                    <div className='cont_icn'>
                        <PhoneIcon />
                    </div>
                    <div className='conttxt'>
                        <span>Call</span>
                        <p>{ getData.mobile_no }</p>
                    </div>
                </div>
                <div className='cont_bx d-flex'>
                    <div className='cont_icn'>
                        <PinDropIcon />
                    </div>
                    <div className='conttxt'>
                        <span>Address</span>
                        <p>{ getData.head_office }</p>
                    </div>
                </div>
                <div className='cont_bx d-flex'>
                    <div className='cont_icn'>
                        <MailIcon />
                    </div>
                    <div className='conttxt'>
                        <span>Call</span>
                        <a>{ getData.care_email }</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact_left;