import React from "react";
import Footer from "./partial/Footer";
import Query from "./Cmpnt/Query_sec"; 
import Admission from "./Admission_process";

import Studentslider from "./Cmpnt/Student_trust";
 
import Benefit from "./Cmpnt/Benefits"; 
import Faq from "./courses/Course_faq";
import Count from "./Cmpnt/Count_us";
import Course_brief from "./courses/Course_brief"; 
import Councelor from "./Cmpnt/Councelor";
import Course_tabs from "./courses/Course_tabs"; 
import Query_form from "./Query_form"; 
  
  
const Course_details = () => {
    return (
        <>
            <Course_brief />  
            <Councelor />
            <div className="container padd_50">
                <div className="row">
                    <div className="col-sm-8">
                       <Course_tabs/> 
                    </div>
                    <div className="col-sm-4">
                       <Query_form/>
                    </div>
                </div>
            </div>
            <Count />
            <Benefit />
            <Studentslider />
            <Faq />
            <Admission />
            <Query />
            <Footer />

        </>
    )
}

export default Course_details;
