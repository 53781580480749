import React from "react";
import axios from "axios";
import moment from "moment-timezone";
import { cmslist } from "../Api_list";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const Trending_blogs = ({ cmstype }) => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect( () => {
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(cmslist + cmstype, headerData).then(( response )=>{
            setPost( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data;
   // alert(cmstype);
   let showdata = "";
   if(cmstype == 'blog'){
    showdata =   'Trending Blogs';
    } 
    if(cmstype == 'home-about'){
        showdata = 'More of our services';
    } 
    return (
        <>
           <div className="trndingbox">
           <h5>  { showdata }</h5>
              
                { getData.map( getData => (
               <div className="trnd_row d-flex">
                   <div className="trnd_img"> 
                       <img src={window.IMGURL+getData.image}  alt={getData.titleorheading}/>
                   </div>
                   <div className="trnd_txt">
                       <h6>{getData.titleorheading.slice(0, 20)} ...  </h6>
                       <p>{getData.shortcontent.slice(0, 50)} ...</p>
                       {
                        (cmstype == 'blog') ?  
                       
                        <div class="by_date">
                            <span> <PersonIcon /> {(getData.add_by != '') ?  getData.add_by.split('#')[1] : 'Admin'}</span>
                            <span><CalendarMonthOutlinedIcon /> {moment(getData.added_on).format("DD/MM/YYYY")} </span>
                        </div>
                        : ""
                        }
                        <a href={getData.slug}> Read More</a>
                   </div>
               </div>
                ))}
               
               
           </div>

        </>
    )
}

export default Trending_blogs;
