import React from "react";
import Footer from "./partial/Footer";
import Query from "./Cmpnt/Query_sec";
import Studentslider from "./Cmpnt/Student_trust";

import Faq from "./Cmpnt/Faq";
import Why_hire_sec from "./Why_hire_sec";
import Job_ready_candidate from "./Job_ready";

const Hire_sbs = () => {
    return (
        <>
            
            <Why_hire_sec />
            <Job_ready_candidate />
            <Studentslider />

            <Faq />
            <Query />
            <Footer />


        </>
    )
}

export default Hire_sbs;
