import React, { useState } from 'react';
import axios from "axios";
import lbimg from "./images/lbimg.png";
import moment from 'moment-timezone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { bloglist } from '../Api_list';
export default function Blogs_articles() {
    const [ post, setPost ] = useState(null);
    var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
      }}
    const [actclass, setactClass] = useState('');
    const [component, setComponent] = useState(null);
    React.useEffect(()=>{
        axios.get(bloglist, headerData).then(( results )=>{
            setPost( results.data )
        })
    },[])
    if(!post) return null;
    let getData = post.data;
    
    function LoadComponent(id) {
        setactClass(id);
        document.getElementById('blogs').innerHTML = "";
        getaxiosdata( id );
      }

    function getaxiosdata( c ){
        axios.get( bloglist+c, headerData).then((responses)=>{
                  let  getData2  = responses.data.data;
                   let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                        <div className='col-sm-3'>
                            <div className='d_blgbox'>
                                <img src={window.IMGURL+getData2.image} />
                                <div className='d_blgstxt'>
                                    <h6>{getData2.titleorheading}</h6>
                                    <div className='bydate_blg d-flex justify-content-between'>
                                        <p><PersonOutlineOutlinedIcon/> {(getData2.add_by != '') ?  getData2.add_by.split('#').slice(-1)[0] : 'Admin'}</p>
                                        <p><CalendarMonthOutlinedIcon/>{moment(getData2.appr_on).format("DD MMM’YYYY")}</p>
                                    </div>
                                    <a href={'/'+getData2.slug} className='readmr_blg'> Read More</a>
                                </div>
                            </div>
                        </div> 
                    ))) : <div className='col-sm-3'>No Record Found!</div> ;
                    
                    setComponent(storecorse);
                  
               })
    }

    return (
        <>
            <div className='container ptb_50'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="job_intro_content">
                            <div className="job_intro_text">
                                <h3>Latest Blog and Articles</h3>
                                <p>Lorem ipsum dolor sit amet. Est alias dolorem ea quasi numquam et natus voluptate id soluta blanditiis vel consequuntur explicabo aut
                                    placeat deleniti est provident exercitationem! Non deserunt officiis qui quisquam exercitationem et minus explicabo. Rem necessitatibus
                                    architecto quo ipsa dolorem cum maiores assumenda et galisum eveniet sit fugit dicta. Et quam doloremque quo enim porro qui odit deserunt
                                    et aperiam esse sed ipsa ipsam qui saepe neque qui incidunt possimus.</p>
                            </div>
                            <div className="job_intro_img">
                                <img src={lbimg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="job_list_sectn">
                    <div className="job_list_type">
                    <ul>
                            <li onClick={() => LoadComponent('')} className={actclass === '' ? "active" : ""  }>All</li>
                            <li onClick={() => LoadComponent('Administrator')} className={actclass === 'Administrator' ? "active" : ""  } >Admin </li>
                            <li onClick={() => LoadComponent('hr')} className={actclass === 'hr' ? "active" : ""  }>HR</li>
                            <li onClick={() => LoadComponent('career_coach')} className={actclass === 'career_coach' ? "active" : ""  }>Career Coach</li>
                        </ul>
                    </div>
                </div>

                <div className='row' id='blogs'>
                { getData.map( getData => (
                    <div className='col-sm-3'>
                        <div className='d_blgbox'>
                            <img src={window.IMGURL+getData.image} />
                                <div className='d_blgstxt'>
                                    <h6>{getData.titleorheading}</h6>
                                    <div className='bydate_blg d-flex justify-content-between'>
                                        <p><PersonOutlineOutlinedIcon/> {(getData.add_by != '') ?  getData.add_by.split('#').slice(-1)[0] : 'Admin'}</p>
                                        <p><CalendarMonthOutlinedIcon/>{moment(getData.appr_on).format("DD MMM’YYYY")}</p>
                                    </div>
                                    <a href={'/'+getData.slug} className='readmr_blg'> Read More</a>
                                </div>
                        </div>
                    </div>
                    ) ) }
                </div>
                <div className='row'>
                {component}
                </div>

            </div>
        </>
    )
}