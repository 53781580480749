import React from 'react';
import intrn from "./images/intrn.png";
import UpSkill from './UpSkill' 
import Internship_form_enrolled from './Intership-enrolled-form'
 
export default function Internship_form() {
    return (
        <>
            <div className='container ptb_50'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="job_intro_content">
                            <div className="job_intro_text">
                                <h3>Internship Form</h3>
                                <p>Lorem ipsum dolor sit amet. Est alias dolorem ea quasi numquam et natus voluptate id soluta blanditiis vel consequuntur explicabo aut
                                    placeat deleniti est provident exercitationem! Non deserunt officiis qui quisquam exercitationem et minus explicabo. Rem necessitatibus
                                    architecto quo ipsa dolorem cum maiores assumenda et galisum eveniet sit fugit dicta. Et quam doloremque quo enim porro qui odit deserunt
                                    et aperiam esse sed ipsa ipsam qui saepe neque qui incidunt possimus.</p>
                            </div>
                            <div className="job_intro_img">
                                <img src={intrn} alt="" />
                            </div>
                        </div>
                    </div> 
                </div>
                <UpSkill/>
                <Internship_form_enrolled />
            </div>
        </>
    )
}