import React from 'react';
import axios from 'axios';
import { teamlist } from '../Api_list';

const Core_team = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(  teamlist + 'core_team', headerData ).then(( response )=>{
            setPost( response.data )
        })
    }, [])
    if (!post) return null;
    var getData = post.data;

    return (
        <>
            <div className="container job_sect padd_50">
                <div className="row hdngtxt text-left">
                    <h3 className='site_hdng'>Our Core Team</h3>
                </div>
                <div className="row jobtypes">
                {getData.map(getData => (
                    <div className="col-md-4">
                        <div className='corebox'>
                            <div className='img_social d-flex'>
                                <img src={ window.IMGURL + getData.profile_pic } alt={ getData.alt_tag } />
                                <div className='name_links'>
                                    <p>{ getData.full_name }</p>
                                    <span>{ getData.designation }</span>
                                </div>
                            </div>
                            <div className='ct_txt'>
                                <p>{ getData.about_us }</p>
                            </div>
                        </div>
                    </div>
                ))}
                    
                </div>
            </div>

        </>
    )
}

export default Core_team;