import React, { useState, useEffect } from 'react';
import axios from "axios";
import Slider from 'react-slick';
import moment from "moment-timezone";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { webnotification } from "../Api_list";

 

const NotificationSlider = () => {

    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH, 
          }} 
        axios.get(webnotification).then(( response )=>{
            setPost( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data;


    const settings = {
        dots: false,
        arrows: false,
        infinite: true, 
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (

        <Slider className='notf_slider' {...settings}>
             {   getData.map( (getData, num) =>(
            <div className='notf_row'>
                <ArrowCircleRightIcon />
                <h5>{ getData.heading} </h5>
                {/* <p>{ getData.description} </p> */}
                <span className='dates'>Date:  {moment(getData.added_on).format("DD/MM/YYYY")} </span>
            </div>
             ))}
            
        </Slider>
    );
};

export default NotificationSlider;
