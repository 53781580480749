import React from 'react'
import intro2 from "./images/intro-img-2.png";
import clg1 from "./images/clg-logo-1.jpg";
import clg2 from "./images/clg-logo-2.jpg";
import clg3 from "./images/clg-logo-3.jpg";

export default function AppliedInstitute() {
  return (
    <>
        <div className="job_intro_container pt-0">
            <div className="container"> 
                <div className="row">
                    <div className="col-md-12">
                        <div className="job_intro_content">
                            <div className="job_intro_text">
                                <h3>Applied Institute Track</h3>
                                <p>Lorem ipsum dolor sit amet. Est alias dolorem ea quasi numquam et natus voluptate id soluta blanditiis vel consequuntur explicabo aut 
                                placeat deleniti est provident exercitationem! Non deserunt officiis qui quisquam exercitationem et minus explicabo. Rem necessitatibus
                                 architecto quo ipsa dolorem cum maiores assumenda et galisum eveniet sit fugit dicta. Et quam doloremque quo enim porro qui odit deserunt
                                  et aperiam esse sed ipsa ipsam qui saepe neque qui incidunt possimus.</p>
                            </div>
                            <div className="job_intro_img">
                                <img src={intro2} alt="" />
                            </div>
                        </div>
                        <div className="AppliedInstitute_wapper">
                            <h3>Institutes You Applied For</h3>
                            <div className="AppliedInstitute_tabs_list">
                                <div className="AppliedInstitute_tabs_item">
                                    <button className="AppliedInstitute_tabs_link active" type="button">North</button>
                                </div>
                                <div className="AppliedInstitute_tabs_item">
                                    <button className="AppliedInstitute_tabs_link" type="button">South</button>
                                </div>
                                <div className="AppliedInstitute_tabs_item">
                                    <button className="AppliedInstitute_tabs_link" type="button">East</button>
                                </div>
                                <div className="AppliedInstitute_tabs_item">
                                    <button className="AppliedInstitute_tabs_link" type="button">West</button>
                                </div>
                                <div className="AppliedInstitute_tabs_item">
                                    <button className="AppliedInstitute_tabs_link" type="button">Central</button>
                                </div>
                            </div>
                            <div className="institutes_card_list d-flex flex-wrap">
                                <div className="institutes_card_item">
                                    <div className="institutes_card_box">
                                        <div className="institutes_card_icon">
                                            <img src={clg1}  alt="" />
                                        </div>
                                        <div className="institutes_card_content">
                                            <h5>Lovely Professional University</h5>
                                            <ul className="institutes_card_inn d-flex flex-wrap">
                                                <li>
                                                    <strong>Course</strong>MBA
                                                </li>
                                                <li>
                                                    <strong>Duration</strong>24 Months
                                                </li>
                                                <li>
                                                    <strong>Type</strong>Regular
                                                </li>
                                                <li>
                                                    <strong>Fee</strong>5,29,000
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="institutes_card_item">
                                    <div className="institutes_card_box">
                                        <div className="institutes_card_icon">
                                            <img src={clg2} alt="" />
                                        </div>
                                        <div className="institutes_card_content">
                                            <h5>Sharda University</h5>
                                            <ul className="institutes_card_inn d-flex flex-wrap">
                                                <li>
                                                    <strong>Course</strong>MBA
                                                </li>
                                                <li>
                                                    <strong>Duration</strong>24 Months
                                                </li>
                                                <li>
                                                    <strong>Type</strong>Regular
                                                </li>
                                                <li>
                                                    <strong>Fee</strong>5,29,000
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="institutes_card_item">
                                    <div className="institutes_card_box">
                                        <div className="institutes_card_icon">
                                            <img src={clg3}  alt="" />
                                        </div>
                                        <div className="institutes_card_content">
                                            <h5>Sharda University</h5>
                                            <ul className="institutes_card_inn d-flex flex-wrap">
                                                <li>
                                                    <strong>Course</strong>MBA
                                                </li>
                                                <li>
                                                    <strong>Duration</strong>24 Months
                                                </li>
                                                <li>
                                                    <strong>Type</strong>Regular
                                                </li>
                                                <li>
                                                    <strong>Fee</strong>5,29,000
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
