import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { studentmeetingfeedback } from '../Api_list';

export default function MyVerticallyCenteredModal(props) {
    const [rating, setRating] = useState(props.ratingss);
    const [hover, setHover] = useState(0);
    const [message, setMessage] = useState("");
    const [userrate, setUserrate] = useState(props.ratingss);
    const [userfeedback, setUserfeedback] = useState("");
    const [userinfo, setUserInfo] = useState({
        setUserlike: [],
      });
// alert(userlike)
    let handleSubmit = async (e) => {
        e.preventDefault();
       
       // setUserlikes(userinfo.setUserlike.toString())
        const postDatas = {
          id: props.appointmentl._id,
          time_slot_id: props.appointmentl.time_slot_id,
          student_rate : userrate,
          student_like : userinfo.setUserlike.toString(),
          student_feedback: userfeedback,
        }
        try {
          const res = await axios.post(studentmeetingfeedback, postDatas)
          if (res.status === 200) {
           console.log(postDatas)
                 setMessage('Meeting Feedback Submitted successfully!');
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              } else {
               setMessage("Some error occured");
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              }
        } catch (e) {
         console.log(e) 
        }
      };
      const handleChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { setUserlike } = userinfo;
          
       // console.log(`${value} is ${checked}`);
         
        // Case 1 : The user checks the box
        if (checked) {
          setUserInfo({
            setUserlike: [...setUserlike, value],
          });
        }
        // Case 2  : The user unchecks the box
        else {
          setUserInfo({
            setUserlike: setUserlike.filter((e) => e !== value),
          });
        }
      };
      if(props.pptype === 'give'){
    return (
        <Modal className="qry_modal"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Body>
                <div className="feedbck_colm">
                <div className="message">{message ? message : null}</div> 
                    <h4 className="text-center">How was your call with {props.appointmentl.user_name}?</h4>
                    <p className="text-center"> {moment(props.appointmentl.timeslot_date).format("DD MMM YYYY") }, {props.appointmentl.timeslot_from} - {props.appointmentl.timeslot_to}</p>
                    <div className="star-rating">
                    {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on" : "off"}
                            onClick={() => setRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span className="star">&#9733;</span>
                        </button>
                        );
                    })}
                    </div>
                    <h4 className="text-center">What did you like?</h4>
                    <Form className='feedbckrow row justify-content-around' onSubmit={handleSubmit}>
                        <Form.Control type="hidden" name='id' id="fid"  value={props.appointmentl._id} />
                        <Form.Control type="hidden" name='time_slot_id' id="ftime_slot_id"  value={props.appointmentl.time_slot_id} />
                        <Form.Control type="hidden" id="user_rate" name="user_rate" onChange={(e) => setUserrate(e.target.value)} value={rating} />

                        <div className="catg_like">
                            <label>  
                                <input type="checkbox" onChange={handleChange} name="user_like[]" value="Great conversation" /><span>Great conversation</span>
                            </label>
                        </div>
                        <div className="catg_like">
                            <label>
                                <input type="checkbox" onChange={handleChange} name="user_like[]" value="Professional attitude" /><span>Professional attitude</span>
                            </label>
                        </div>
                        <div className="catg_like">
                            <label>
                                <input type="checkbox" onChange={handleChange} name="user_like[]" value="Clear audio quality" /><span>Clear audio quality</span>
                            </label>
                        </div>
                        <div className="catg_like">
                            <label>
                                <input type="checkbox" onChange={handleChange} name="user_like[]" value="Very helpful" /><span>Very helpful</span>
                            </label>
                        </div>
                        <div className="tellmore">
                            <h4 className="text-center">Tell us more</h4>
                            <Form.Group className="form-group">
                                <Form.Control name="user_feedback" onChange={(e) => setUserfeedback(e.target.value)}
                                    as="textarea"
                                    style={{ height:'150px' }}
                                />
                            </Form.Group>
                            <Col className="col-sm-12 text-center">
                                <Button variant="primary" type="submit">
                                    Submit Feedback
                                </Button>
                                {/* <a href="#!" className="nocall">Didn’t receive call ?</a> */}
                            </Col>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
        
    );
   }
   
   if(props.pptype === 'view'){
    var myArray = (props.appointmentl.student_like) ? props.appointmentl.student_like.split(',') : [];
   
    return (
        <Modal className="qry_modal"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Body>
                <div className="feedbck_colm">
                <div className="message">{message ? message : null}</div> 
                    <h4 className="text-center">How was your call with {props.appointmentl.user_name}?</h4>
                    <p className="text-center"> {moment(props.appointmentl.timeslot_date).format("DD MMM YYYY") }, {props.appointmentl.timeslot_from} - {props.appointmentl.timeslot_to}</p>
                    <div className="star-rating">
                    {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                        <button
                            type="button"
                            key={index}
                            className={index <= ( props.ratingss) ? "on" : "off"}
                            
                        >
                            <span className="star">&#9733;</span>
                        </button>
                        );
                    })}
                    </div>
                    <h4 className="text-center">What did you like?</h4>
                    <Form className='feedbckrow row justify-content-around' onSubmit={handleSubmit}>
                        
                        <div className="catg_like">
                            <label>  
                                <input type="checkbox" checked={((myArray.find(o => o === 'Great conversation')) ? "checked" : "")}  /><span>Great conversation</span>
                            </label>
                        </div>
                        <div className="catg_like">
                            <label>
                                <input type="checkbox" checked={((myArray.find(o => o === 'Professional attitude')) ? "checked" : "")}/><span>Professional attitude</span>
                            </label>
                        </div>
                        <div className="catg_like">
                            <label>
                                <input type="checkbox" checked={((myArray.find(o => o === 'Clear audio quality')) ? "checked" : "")}  /><span>Clear audio quality</span>
                            </label>
                        </div>
                        <div className="catg_like">
                            <label>
                                <input type="checkbox"  checked={((myArray.find(o => o === 'Very helpful')) ? "checked" : "")} /><span>Very helpful</span>
                            </label>
                        </div>
                        <div className="tellmore">
                            <h4 className="text-center">Tell us more</h4>
                            <Form.Group className="form-group">
                                <Form.Control name="user_feedback" value={props.appointmentl.student_feedback}
                                    as="textarea" disabled
                                    style={{ height:'150px' }}
                                />
                            </Form.Group>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
        
    );
   }
}