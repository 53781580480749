import React, { useState } from "react";
import axios from "axios";
import moment from 'moment-timezone';
import { notiflist } from '../Api_list';

const Announcements = () => {
    var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
      }}
    const [ post, setPost ] = useState(null); 
    const [actclass, setactClass] = useState('');
    const [components, setComponents] = useState(null);
    React.useEffect(()=>{
        axios.get(notiflist, headerData).then(( results )=>{
            setPost( results.data )
        })  
    },[])
    if(!post) return null;
    let getData = post.data;
    getData = getData.slice(0, 10);
    function LoadComponentanounc(id) {
        setactClass(id);
        document.getElementById('anounc').innerHTML = "";
        getaxiosdata( id );
      }

    function getaxiosdata( c ){
        axios.get( notiflist+c, headerData).then((responses)=>{
                  let  getData2  = responses.data.data;
                  getData2 = getData2.slice(0, 10);
                   let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                    <div className="notf_list">
                        <div className="notf_text"> 
                            <div className="notf-hdng-row">
                                <div className="notf_hdng">
                                    <h4>{getData2.heading}</h4>
                                    <span>{(getData2.add_by != '') ?  getData2.add_by.split('#').slice(-1)[0] : 'Admin'}</span>
                                </div>
                                <p className="n_date">{moment(getData2.appr_on).format("DD MMM’YYYY")}</p>
                            </div> 
                            <p className="ntf_txt"> {getData2.description.slice(0, 140)}... </p>
                        </div>
                    </div>
                    ))) : "";
                    setComponents(storecorse);
               })
    }
    return (
        <>
            <div className="opprtnty_sectn">
                <h3 className="dash_hdng">Announcement & Notifications</h3>
                <div className="oppurtnty_box">
                    <div className="notf_type job_type">
                        <ul>
                        <ul>
                            <li onClick={() => LoadComponentanounc('')} className={actclass === '' ? "active" : ""  }>All</li>
                            <li onClick={() => LoadComponentanounc('Administrator')} className={actclass === 'Administrator' ? "active" : ""  } >Admin </li>
                            <li onClick={() => LoadComponentanounc('hr')} className={actclass === 'hr' ? "active" : ""  }>HR</li>
                            <li onClick={() => LoadComponentanounc('career_coach')} className={actclass === 'career_coach' ? "active" : ""  }>Career Coach</li>
                        </ul>
                        </ul>
                    </div> 
                    <div className="notf_display">
                    <div id='anounc'>
                    { getData.map( getData => (
                        <div className="notf_list">
                            <div className="notf_text"> 
                                <div className="notf-hdng-row">
                                    <div className="notf_hdng">
                                        <h4>{getData.heading}</h4>
                                        <span>{(getData.add_by != '') ?  getData.add_by.split('#').slice(-1)[0] : 'Admin'}</span>
                                    </div>
                                    <p className="n_date">{moment(getData.appr_on).format("DD MMM’YYYY")}</p>
                                </div> 
                                <p className="ntf_txt"> {getData.description.slice(0, 140)}... </p>
                            </div>
                        </div>
                         ) ) }
                        </div>
                        {components}
                        



                    </div>

                    <div className="vw_all">
                        <a className="vwall_btn" href="#!"> View All</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Announcements;


