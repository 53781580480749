import React from "react";
import axios from 'axios';
import logo from "../../src/images/logo.png";
import { NavLink } from "react-router-dom";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { zonewiseuniversity, branchwisecourse, websetting } from '../Api_list';
   
const Footer = () => {
    const [ post, setPost ] = React.useState( null );
    const [token1, setToken1] = React.useState("");
    const [settingData, setSetting] = React.useState("");
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH, 
          }} 
        axios.get(zonewiseuniversity, headerData).then(( response )=>{
            setPost( response.data );
        })
        axios.get( branchwisecourse, headerData ).then((response)=>{
            setToken1( response.data )
        })
        axios.get(websetting, headerData).then(( response )=>{
          
            setSetting( response.data );
        })
    }, [])
    if( !post ) return null;
    let zoneuniData = post.data;

    if( !token1 ) return null;
    let getData1 = token1.data;

    
    if( !settingData ) return null;
    let settingRecord = settingData.data;
    console.log(settingRecord.facebook_link)
    function getcourse(branch, course) {
       
        localStorage.setItem('course', [branch+'|'+course]);
      
      window.location.href='/college-listing'
      }
    return ( 
        <>
            <section className="pt-50">
                <div className="footer-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3 col-lg-3 col-md-3 col-12">
                            {   getData1.map( (getData1, num) =>(
                                <div className="foot_box">
                                    <h5>{ getData1.branch_name }</h5>
                                    <ul>
                                    { getData1.courses.map( (coursedata) =>(  
                                        <li><a  onClick={() => getcourse( num, coursedata.course_name)}  className="nav-link"> { coursedata.course_name } </a></li>
                                        ))}
                                    </ul> 
                                </div>
                                 ))}
                                 
                            </div>
                            <div className="col-sm-6 col-lg-6 col-md-6 col-12">
                                <h5>Our Presence</h5>
                                <div className="zones">
                                {zoneuniData.map(getData => (
                                    <div className="foot_box">
                                        <h5>{getData.zone_name}</h5>
                                        <ul>
                                        {getData.university.map(uniData => (
                                            <li><a href={uniData.slug} className="nav-link"> {uniData.univ_name} </a></li>
                                        ))}  
                                        </ul> 
                                    </div>
                                 ))} 
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-md-3 col-12">
                                <div className="foot_box foot_fulbox">
                                    <h5>About Sindon</h5>
                                    <ul>
                                        <li><a href="/about-us" className="nav-link"> Who We Are </a></li>
                                        {/* <li><a href="#" className="nav-link"> Our Councelors</a></li> */}
                                        <li><a href="/advisory-board" className="nav-link"> Advisory Board</a></li>
                                        {/* <li><a href="" className="nav-link"> Placement Team </a></li> */}
                                        {/* <li><a href="#" className="nav-link"> Academic Advisory Board</a></li> */}
                                        <li><a href="/blog-list" className="nav-link"> Blog </a></li>
                                        <li><a href="/contact-us" className="nav-link"> Contact Us </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer_bg_ylw">
                        <ul>
                            <li>
                                <a href="/terms-and-conditions">Terms & Conditions</a>
                            </li>
                            <li>
                                <a href="/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/refund-policy">Refund Policy</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer_btm">
                        <div className="container">
                            <NavLink className="navbar-brand" to="/">
                                <img src={logo} className="logoimg" alt="Logo" />
                            </NavLink>
                            <p className="credit">© 2023 Copyright Sindon. All Rights Reserved.</p>
                            <div class="social_media">
                                <ul>
                                    {/* <li><a href="https://www.facebook.com/sindoninternationaleducation"> <FacebookOutlinedIcon/> </a></li>
                                    <li><a href="https://www.instagram.com/sindonbschool/"><InstagramIcon/> </a></li>
                                    <li><a href="https://twitter.com/SchoolSindon"><TwitterIcon/> </a></li>
                                    <li><a href="https://www.youtube.com/channel/UC3zAUDrWSPFrkH3F3EuqMqg"> <YouTubeIcon/></a></li> */}
                                    <li><a target="_blank" href={settingRecord.facebook_link}> <FacebookOutlinedIcon/> </a></li>
                                    <li><a target="_blank" href={settingRecord.instagram_link}><InstagramIcon/> </a></li>
                                    <li><a target="_blank" href={settingRecord.twitter_link}><TwitterIcon/> </a></li>
                                    <li><a target="_blank" href={settingRecord.youtube_link}> <YouTubeIcon/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
        </>
    )
}

export default Footer;