import React, {useState } from "react";
import axios from "axios";
import login_img from "../src/images/benefit.png";
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
// import OTPBox from "./register/Otpp";

import { login, otpmatchresend } from "./Api_list";
let color = {};
const Login = () => { 
  var headerData = {"headers":{
    "Accept": "application/json",
    "Content-Type": "application/json", 
    "authorization": window.HEADERSAUTH,
  }}
    const [otp, setOtp] = useState(new Array(4).fill(""));
    
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const [mobileNumber, setMobileNumber] = useState("");
    const [message, setMessage] = useState("");
    const [otpDisplay, setotpDisplay] = useState("");
    const [loginDisplay, setlogniDisplay] = useState("block");
    const [userId, setUserid] = useState('');
    let handleSubmit = async (e) => {
        e.preventDefault();
        const post = {
          mobile_no: mobileNumber,  
        }
      if((mobileNumber.length === 10)){
        try {

          const res = await axios.post(login, post, headerData)
          if (res.status === 200) {
            if(typeof res.data.user_id === 'undefined'){
              setUserid(res.data.data);
            }else{
              setUserid(res.data.user_id);
             }
            
            setlogniDisplay("");
            setotpDisplay("block");
            color.color = 'green';
            setMobileNumber("");
                setMessage(res.data.message);
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              } else {
                color.color = 'red';
                setMessage(res.data.message);
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              }
        } catch (e) {
          color.color = 'red';
          setMessage("Some error occured");
          setTimeout(() => {
            setMessage('');
          }, 5000);
        }
      }else{
        color.color = 'red';
        setMessage("Please enter valid mobile number"); 
        setTimeout(() => {
          setMessage('');
        }, 5000);
      }
      };


      let handleSubmitOTP = async (e) => {
        e.preventDefault();
        let otp0 = document.getElementById('otp0').value;
        let otp1 = document.getElementById('otp1').value;
        let otp2 = document.getElementById('otp2').value;
        let otp3 = document.getElementById('otp3').value;
        const postData = {
            id: userId,  
            action: "match",  
            otp: otp0+otp1+otp2+otp3,  
        }
        // console.log(postData)
        try {
          const res = await axios.post(otpmatchresend, postData, headerData)
          //console.log(res.data.data.adm_step)
          if(res.data.data.adm_step == 1){
            localStorage.setItem('userid', userId);
            localStorage.setItem('mobileno', res.data.data.mobile_no);
            window.location.href = '/register';
          }else{
            localStorage.removeItem("userid");
            localStorage.removeItem("mobileno");
            //console.log(res.data.data)
            localStorage.setItem( 'loginddata', JSON.stringify(res.data.data) );

            // let obj = JSON.parse( localStorage.getItem('loginddata') );
            // console.log( obj );
            // console.log( obj._id)
            var page_url =  localStorage.getItem('page_url') ? localStorage.getItem('page_url') : '/';
            window.location.href = page_url;
          }
        } catch (e) {
          
        }
      };

      
function resendOtp(){
    try {
        axios.post( otpmatchresend, {"id":userId,"action":"resend"}, headerData).then((responses)=>{
            if (responses.status === 200) {
                setMobileNumber("");
                    setMessage(responses.data.message);
                    setTimeout(() => {
                      setMessage('');
                    }, 5000);
                  } else {
                    setMessage(responses.data.message);
                    setTimeout(() => {
                      setMessage('');
                    }, 5000);
                  }
        })
       
      } catch (e) {
        setMessage("Some error occured");
        setTimeout(() => {
          setMessage('');
        }, 5000);
      }
}

    return (
        <>
        { loginDisplay ?  
            <section className="loginsec" style={{display: { loginDisplay} }}>
                <div className="container padd_50">
                    <div className="row loginrow align-items-center">
                        <div className="col-sm-6 logintxt text-center">
                            <h3>Login</h3>
                            <p>Please Enter Your Mobile Number To Create Your Account</p>
                            <div className="message">{message ? <p style={color}>{message}</p> : null}</div> 
                            <div className='loginform'>
                                <Form className='row' onSubmit={handleSubmit}>
                                    <Col className="mb-3 col-sm-12">
                                        <FloatingLabel controlId="floatingInputGrid" label="Phone Number">
                                            <Form.Control onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} maxLength={10} type="text" placeholder="Phone Number" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col className="mb-3 col-sm-12">
                                        <Button variant="primary" type="submit">
                                            Sign In
                                        </Button>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                        <div className="col-sm-6 bnft_img ">
                            <img src={login_img} />
                        </div>
                    </div>
                </div>
            </section>
    : "" }
        {otpDisplay ? 
            <section className="otp_section" style={{display: { otpDisplay} }}>
                <div className="container padd_50">
                    <div className="row otp_row">
                        <div className="col-md-6 login-box-mrgn">
                            <div className="login-box text-center otpbox_hide">
                                <h2><strong>OTP Verification </strong></h2>
                                <p>Please Enter The Four-Digit OTP, Sent To Your Submitted Mobile Number</p>
                                <div className="message">{message ? <p style={color}>{message}</p> : null}</div> 
                                <form action="" className="ottp" onSubmit={handleSubmitOTP}>
                                <div className="row">
                <div className="col text-center">
                    <h2>Welcome to the channel!!!</h2>
                    <p>Enter the OTP sent to you to verify your identity</p>

                    {otp.map((data, index) => {
                        return (
                            <input
                                className="otp-field"
                                type="text"
                                name={'otp'+index}
                                id={'otp'+index}
                                maxLength="1"
                                key={index}
                                value={data}
                                onChange={e => handleChange(e.target, index)}
                                onFocus={e => e.target.select()}
                            />
                        );
                    })}
                </div>
            </div>
                                    <div className="otpbtn_wrp">
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button> <br/>
                                        <a href="javascript:void(0)" onClick={() => resendOtp()} className="customBtn1">Resend OTP</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6 bnft_img">
                            <div className="login-img">
                                <img src={login_img} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            : "" }
        </>
    )
}
export default Login;