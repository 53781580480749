import React from "react";
import axios from "axios";
import Footer from "./partial/Footer";
import Query from "./Cmpnt/Query_sec";
import Advisory from "./advycmnt/Advisory_members";
import Advisorytop_bg from "./advycmnt/Advisory_topbg";
import { cmsdetail } from "./Api_list";
import { Helmet } from "react-helmet";

const Advisory_board = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}  
        axios.get(cmsdetail+'advisory-board', headerData).then(( response )=>{
            setPost( response.data );
        }) 
    }, []) 
    if( !post ) return null;
    let getData = post.data;
    return (
        <>
        <Helmet>
            <title>{(getData != null) ? getData.meta_title : 'Careereduversity'}</title> 
            <meta name="title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta name="description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta name="keywords" content={(getData != null) ? getData.meta_keyword : 'Careereduversity'} />
            <meta property="og:title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta property="og:image" content={(getData != null) ?  window.IMGURL + getData.image : 'Careereduversity'}/>
            <meta property="og:description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta property="og:url" content={window.APPURL} />
        </Helmet>
        <Advisorytop_bg/>
            <Advisory />
            <Query /> 
            <Footer />


        </>
    )
}

export default Advisory_board;
