import React, { useState } from "react";
import axios from "axios";
// import Course_filter from "../Courses_fil";
import Accordion from 'react-bootstrap/Accordion'

import Crs_box from "./Course_box"; 
import { zonelist, branchwisecourse, courselist } from "../Api_list";
const Course_home = () => {
    const [component, setComponent] = useState(null);
    const [coursename, setcourseName] = useState("");
    const [token, setToken] = useState("");
    const [token1, setToken1] = useState("");
    const [option,setOption] = useState(); 
    React.useEffect(()=>{   
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json", 
            "authorization": window.HEADERSAUTH,
          }}  
        axios.get(zonelist, headerData).then(( response )=>{
            setToken( response.data );
        })
        axios.get( branchwisecourse, headerData ).then((response)=>{
            setToken1( response.data )
        })
    }, [setToken])
    if( !token ) return null; 
    let getData = token.data;
 
    if( !token1 ) return null;
    let getData1 = token1.data;

    let d = localStorage.getItem('course'); 
    let defaltkey = "";
    let defaltkeys  = (d) ? parseInt(d.split("|")[0]) : "";
    if( isNaN(defaltkeys) === false){
        defaltkey = defaltkeys;
    }
    let defaltsub  = (d) ? d.split("|")[1] : '';
    let  cname = (defaltsub) ? defaltsub : coursename;
    function getaxiosdata( c, z ){
        localStorage.setItem('course', ['|']);
        
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH, 
          }} 

            axios.get( courselist+c+'&zone='+z, headerData).then((responses)=>{
                      let  getData2  = responses.data.data;
                       let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                        <div className="col-sm-4">
                            <div className="crs_box">
                                <div className="crsimg">
                                    <img src={getData2.univ_slider ? window.IMGURL + getData2.univ_slider[0].slider : ""} />
                                </div>
                                <div className="crs_cont">
                                    <div className="crs_ovrview">
                                        <h3>{ getData2.course_name }</h3>
                                        <span className="duration">{ getData2.course_duration } Months</span>
                                        <span className="mode">Mode : { getData2.course_formate }</span>
                                        <div className="frm_univ">
                                            <span>From</span>
                                            <img src={window.IMGURL + getData2.univ_logo} />
                                        </div>
                                    </div>
                                    <a href={getData2.univ_slug+'/'+getData2.slug} className="cta_btn crs_btn"> View Details</a>
                                </div>
                            </div>
                        </div>
                        ))) : "";
                       
                        setComponent(storecorse);
                   })
        }
        function handleChange(event){
            
            document.getElementById('coursedata').innerHTML = "";
            setOption(event.target.value)
            let  options = ""; 
            if (typeof event.target.value != 'undefined') {
                options = event.target.value;
              }
              let name = document.getElementById('coursename').value;
              getaxiosdata( name, options )  ;
        }
        function LoadComponent(id) {
           
            document.getElementById('coursedata').innerHTML = "";
            setcourseName(id);
            let options = "";
            if (typeof option != 'undefined') {
                options = option;
            }
            getaxiosdata( id, options );
          }

   return (
        <>
            <section className="home_course">
                <div className="container">
                    <div className="row centr_hdng_txt zone_wrp">
                        <h2 className="sitehdng"> Education - <span>Learning</span> - Placement - <span>Growth</span> </h2>
                        <p>Persue UG PG Program at leading institute and create your success story.</p>
                        <div className="zone_filter">
                        <select name='option' onChange={handleChange}>
                              <option value="">Select Zone</option>
                                {getData.map( getData =>(
                                    <option value={getData.zone_name}>{getData.zone_name}</option>
                                ) ) }
                            </select>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-3">
                        <div className="filwrap">
                            <Accordion defaultActiveKey={(defaltkey) ? defaltkey : 0} flush className="filt_container">
                            {   getData1.map( (getData1, num) =>(
                                
                                    <Accordion.Item eventKey={num}>
                                    <Accordion.Header>{ getData1.branch_name }   </Accordion.Header>
                                    <Accordion.Body className="box_sh_inner">
                                        <ul>
                                        { getData1.courses.map( (coursedata) =>(  
                                            <li onClick={() => LoadComponent(coursedata.course_name)}>{ coursedata.course_name }</li>
                                        ))}
                                        </ul>
                                    </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                        </div>
                        <div className="col-sm-9">
                            <input type="hidden" id="coursename" value={(coursename) ? coursename : ((token1.data[0].courses[0]) ? token1.data[0].courses[0].course_name : "")}/>
                            <div className="row" id="coursedata">
                            <Crs_box CourseName={(cname) ? cname : ((token1.data[0].courses[0]) ? token1.data[0].courses[0].course_name : "")}/>
                            </div>
                            <div className="row">
                            {component}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    ) 
}
export default Course_home;