import React, { useState, useEffect } from "react";
import axios from "axios"; 
import c1 from "./images/c1.png";
import c2 from "./images/c2.png";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { userDetails } from "../Api_list";
const My_coach = () => {
    let obj = JSON.parse( localStorage.getItem('loginddata') );
    const [ hrdata, setHrdata ] = useState( null );
    const [ counselordata, setCounselordata ] = useState( null );
    useEffect(()=>{
        axios.get( userDetails+obj.hr_id ).then(( response )=>{
            setHrdata( response.data );
        })
        axios.get( userDetails+obj.counselor_id ).then(( response )=>{
            setCounselordata( response.data );
        })
    }, [])
    if( !hrdata ) return null;
    let hData = hrdata.data;
    if( !counselordata ) return null;
    let cData = counselordata.data;
    return (
        <> 
            <div className="mycoach">
                <h3 className="dash_hdng">My Coach</h3>
                <div className="cardbox">
                   <h5>HR Coach</h5>
                   <div className="d-flex cch_wrp">
                      <img src={(hData.profile_pic) ? window.IMGURL + hData.profile_pic : ""} alt={cData.full_name}/>
                      <div className="coach_txt">
                        <h4>{hData.full_name}</h4>
                        <p>{hData.qualification}</p>
                        {/* <span>Experience: 6 Years</span> */}
                        <div className="cch_btns d-flex">
                            <a href="chat/hr-coach" className="cbtn pink"> <ForumOutlinedIcon/> Chat </a>
                            <a href="book-appointment/video/hr-coach" className="cbtn blue"> <VideoCallOutlinedIcon/> Book Video Call </a>
                            <a href="book-appointment/phone-call/param1=hr-coach" className="cbtn green"> <CallOutlinedIcon/>Book Phone Call </a>
                        </div>
                      </div>
                   </div>
                </div> 
                <div className="cardbox">
                   <h5>Career Coach</h5>
                   <div className="d-flex cch_wrp">
                      <img src={(cData.profile_pic) ? window.IMGURL + cData.profile_pic : ""} alt={cData.full_name}/>
                      <div className="coach_txt">
                        <h4>{cData.full_name}</h4> 
                        {/* <p>{cData.full_name}</p> */}
                        {/* <span>Experience: 8 Years</span> */} 
                        <div className="cch_btns d-flex">
                            <a href="chat/career-coach" className="cbtn pink"> <ForumOutlinedIcon/> Chat </a>
                            <a href="book-appointment/video/career-coach" className="cbtn blue"> <VideoCallOutlinedIcon/> Book Video Call </a>
                            <a href="book-appointment/phone-call/career-coach" className="cbtn green"> <CallOutlinedIcon/>Book Phone Call </a>
                        </div>
                      </div>
                   </div>
                </div>
            </div>
        </>
    )
}

export default My_coach;
