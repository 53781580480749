import React from "react";
import axios from "axios";
import { reapbenf } from '../Api_list';
import CheckIcon from '@mui/icons-material/Check';
// import benefit from "../src/images/benefit.png";
const Benefit = () => {
    const [post, setPost] = React.useState(null);
    React.useEffect(() => {
      var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
      }} 
      axios.get(reapbenf, headerData).then((response) => {
        setPost(response.data);
      });
    }, []);
    if (!post) return null;
    var getData = post.data.benefit_data;
    return (
        <>
        <section className="benft_wrpr">
            <div className="container">
                <div className="row reap_bnft myy-d">
                <h2 className="text-center mb-4">{post.data.titleorheading}</h2>
                  <div className="col-sm-6">
                    <div className="p-boxx pbx1">
                    <div className="row">
                        {getData.map(getData => (
                        <div className="col-sm-6 text-center col-6 sm-box1">
                        <div className="icon"><CheckIcon /></div>
                        <h5>{getData.heading}</h5>
                        <p>{getData.description}</p>
                        </div>
                        ))}
                        
                    </div>
                    </div>
                  </div>
                  <div className="col-sm-6 bnft_img ">
                     <img src={  window.IMGURL + post.data.image } alt={post.data.titleorheading} />
                  </div>
                </div>
            </div> 
            </section>
        </>
    )
}
export default Benefit;