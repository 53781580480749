import React from 'react';
import intern from './images/intern.png';

const Intern_top = () => {
    return (
        <>
            <section className='intrn_top_sec'>
                <div className='container'>
                    <div className='d-flex intrn_row'>
                        <div className='intrnimg'>
                                <img src={intern} />
                        </div>
                        <div className='intrn_txt'>
                            <div className='crs_brftxt'>
                                <h2>Changing dynamics in hiring and making students a seasoned freshers.</h2>
                                <p> Our mentors and coaches aim on education that works and make you industry ready.</p>
                            </div>
                        </div>
                    </div>
                    <div className='commt_row'>
                        <div className='bgylw'>
                             <h5>We Commit to deliver</h5>
                        </div>
                        <div className='bg_vlt'>
                            <div className='cmtmnt'>
                                <h6>1:1 Dedicated Sessions</h6>
                                <p>Maximum attention to each student</p>
                            </div>
                            <div className='cmtmnt'>
                                <h6>Industry led curriculum</h6>
                                <p>Focused mentoring and trainings</p>
                            </div>
                            <div className='cmtmnt'>
                                <h6>Placements</h6>
                                <p>Student get hand-picked by top recruiters</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Intern_top;