import React from "react";
import moment from "moment-timezone";
import ci1 from "./images/d-icons/ci1.png";
import ci2 from "./images/d-icons/ci2.png";
import ci3 from "./images/d-icons/ci3.png";
import ci4 from "./images/d-icons/ci4.png";

// import No_courses from './No-course'
const My_courses = () => {
    let obj = JSON.parse( localStorage.getItem('loginddata') );
    return (
        <>
            <div className="mycrses">
                <h3 className="dash_hdng">My Courses</h3>
                <div className="cardbox">
                    <div className="crs_txt">
                        <div className="d-flex align-items-center">
                            <img src={ci1} />
                            <h5>Course</h5>
                        </div>
                        <h4>{obj.course_name}</h4>
                    </div>
                    <div className="crs_txt">
                        <div className="d-flex align-items-center">
                            <img src={ci2} />
                            <h5>College / University</h5>
                        </div>
                        <h4>{obj.college_institute_name}</h4>
                    </div>
                    <div className="crs_txt">
                        <div className="d-flex align-items-center"> 
                            <img src={ci3} />
                            <h5>Mode of Learning</h5>
                        </div>
                        <h4>{obj.course_type}</h4>
                    </div>
                    <div className="crs_txt">
                        <div className="d-flex">
                            <div className="dates align-items-center">
                                <img src={ci4} />
                                <h5>From</h5>
                            </div>
                            <div className="dates align-items-center">
                                <img src={ci4} />
                                <h5>To</h5>
                            </div>
                        </div>
                        <div className="years d-flex align-items-center">
                            <h4>{moment(obj.course_start_from).format("YYYY")}</h4>
                            <h4>{moment(obj.course_end_to).format("YYYY")}</h4>
                        </div>

                    </div>

                    <div className="crs_txt">
                        <div className="d-flex align-items-center">
                            <img src={ci1} />
                            <h5>Current Semester</h5>
                        </div>
                        <h4>{obj.current_semester}</h4>
                    </div>
                </div>
            </div>

            {/* <No_courses/> */}

        </>
    )
}

export default My_courses;
