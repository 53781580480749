import React, {useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import j2 from "./images/j2.png";

import { jobtypeactivelist,jobformlistforweb, activecitylist, zoneactivelist, jobdetails, appliedstudent } from "../Api_list"; 
let obj = JSON.parse( localStorage.getItem('loginddata') );
let color = {};

function MyVerticallyCenteredModal(props) {
    const [jobdetail, setJobdetail] = useState("");
    const [message, setMessage]     = useState("");
    const [propsdata, setpropsdata] = useState("")
        if((propsdata !== props.data) && (props.data.length > 0)){
        axios.get( jobdetails+props.data).then((responses)=>{
            setJobdetail(responses.data.data);
            setpropsdata(props.data)
        })
    
    }
    let SubmitForm = async (e) => {
        const postdata = {
            student_name  : obj.full_name, 
            student_id    : obj._id, 
            job_id        : e
        }
        try {
        const res = await axios.post(appliedstudent, postdata)
        if (res.status === 200) {
            color.color = 'green';
                setMessage(res.data.message);
                setTimeout(() => {
                setMessage('');
                }, 5000);
            } else {
                color.color = 'red';
                setMessage(res.data.message);
            }
        } catch (e) {
       
        }
    }
     return (
         <Modal className="job_modal"
             {...props}
             size="lg"
             aria-labelledby="contained-modal-title-vcenter"
             centered
         >
             <Modal.Header closeButton>
             <div className="messagex">{message ? <p style={color}>{message}</p> : null}</div> 
                 <div className="jbimg">
                     <img src={(jobdetail) ? window.IMGURL+jobdetail.company_logo : j2} alt={(jobdetail) ? jobdetail.job_title : ""}/>
                 </div>
                 <a href="#!" onClick={() => SubmitForm(props.data)} className="applybtn"> Apply Now </a>
                 
             </Modal.Header>
             <Modal.Body>
                 <div className="row">
                     <div className="col-sm-8">
                         <h5>
                         {(jobdetail) ? jobdetail.job_title : ""}
                         </h5> 
                         <div className='blgdtl_txt' dangerouslySetInnerHTML={{ __html: (jobdetail) ? jobdetail.about_job : "" }} />
                     </div>
                     <div className="col-sm-4">
                         <div className="job_popupright">
                             <h4>Job Type</h4>
                             <p>{(jobdetail) ? jobdetail.job_type : ""}</p>
                             <h4>Job location</h4>
                             <p><FmdGoodIcon/> {(jobdetail) ? jobdetail.city : ""}</p>
                             <h4>Application Closes</h4>
                             <p>{(jobdetail) ? moment(jobdetail.application_end).format("DD MMM’YYYY h:mm a") : ""}</p> 
                             <h4>Package</h4>
                             <p className="job_prc">{ (jobdetail) ? jobdetail.package_currency : ""} {  (jobdetail) ?  jobdetail.package_range_start : ""} - { (jobdetail) ?  jobdetail.package_range_end : "" } per month</p>
                         </div>
                         
                     </div>
                 </div>
 
             </Modal.Body>
         </Modal>
     );
 }



export default function JobListingSection() {
    const [component, setComponent]     = useState(null);
    const [showResults, setShowResults] = useState(false)
    const [ post, setPost ]             = useState(null);
    const [actclass, setactClass]       = useState(''); 
    const [ jobs, setJobs ]             = useState(null);
    const [zonedata, setZonedata]       = useState('');
    const [citydata, setCitydata]       = useState('');
    const [modalShow, setModalShow]     = useState('');
    const handleClick = event => { 
        setShowResults(current => !current);
    };
     
     useEffect(()=>{
        axios.get(jobformlistforweb).then(( response )=>{
            setJobs( response.data )
        })
        axios.get(jobtypeactivelist).then(( response )=>{
            setPost( response.data )
        })
        axios.get(zoneactivelist).then(( response )=>{
            setZonedata( response.data )
        })
        axios.get(activecitylist).then(( response )=>{
            setCitydata( response.data )
        })
     }, [])
     if( !post ) return null;
     let jobtypsdata = post.data;
     if( !zonedata ) return null;
     let getzone = zonedata.data;

     if( !citydata ) return null;
     let getcity = citydata.data;
     if( !jobs ) return null;
     let jobdata = jobs.data;

     function LoadComponent(id) {
        setactClass(id);
        //  document.getElementById('showjobdata').innerHTML = "";
        getaxiosdata(id,"","")
      } 
    function GetFormData(){
        let cityid = document.getElementById('city_id').value;
        let zoneid = document.getElementById('zone_id').value;
        getaxiosdata( actclass,cityid,zoneid );
        setShowResults("");
    }
    function ResetFormData(){
        setShowResults("")
        getaxiosdata( actclass,"","" )
    }
    function getaxiosdata( j,c,z ){
            axios.get( jobformlistforweb+'?jobtype='+j+'&city='+c+'&zone='+z).then((responses)=>{
                let  getData2  = responses.data.data;
                            let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                                <div className='col-sm-4'>
                                <div className="joblistbox">
                                    <div className="job_list">
                                        <img src={window.IMGURL + getData2.company_logo} alt={ getData2.job_title }/>
                                        <div className="job_text">
                                            <div className="job_lr">
                                                <div className="job-left" >
                                                    <h4>{ getData2.job_title }</h4>
                                                    <ul>
                                                        <li><FmdGoodIcon /> { getData2.city }</li>
                                                        <li><span className="c_dot"></span> { getData2.job_type }</li>
                                                    </ul>
                                                    <p className="job_prc"> { getData2.package_currency } { getData2.package_range_start } - { getData2.package_range_end } per month </p>
                                                </div>
                                            </div>
                                            <div className="jb_close">
                                                <p>Application closes on: {moment(getData2.application_end).format("DD MMM’YYYY h:mm a")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="dtl_btn" onClick={() => setModalShow(getData2._id)}> Apply Now</button>
                                </div>
                                </div>
                            ))) : "";
                            setComponent(storecorse);
            })
        }
    return (
        <>
            <div className="job_list_sectn">
                <div className="job_list_type">
                    <ul>
                    <li onClick={() => LoadComponent('')} className={actclass === '' ? "active" : ""  } >All</li>
                        { jobtypsdata.map( jobtypsdata => (
                            <li  onClick={() => LoadComponent(jobtypsdata.job_type)}  className={actclass === jobtypsdata.job_type ? "active" : ""  }>{jobtypsdata.job_type}</li>   
                        )) }
                    </ul>
                </div>

                <div className='searchrow'>
                    <Form className='srchform'>
                        <input type="text" name="destination" className="form-control" placeholder="Add Keywords" />
                    </Form>
                    <div className='zone_selectbx'>
                        <div className='zoneslct' onClick={handleClick} >
                            <LocationOnIcon />
                            <p>Zone</p>
                            <ExpandMoreIcon />
                        </div>
                    </div>
                    {showResults && (
                        <div className='zone_drpdwn'>
                            {/* <Form> */}
                                <Form.Group as={Row} className="mb-3" controlId="">
                                    <Form.Label column sm={4}>
                                        Select Zone
                                    </Form.Label>
                                    <Col sm={8}> 
                                        <Form.Select defaultValue="Choose..." id='zone_id'>
                                            <option value="">Choose...</option>
                                            { getzone.map( getzone =>(
                                                <option value={getzone.zone_name}>{getzone.zone_name}</option>
                                            ) ) }
                                            
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="">
                                    <Form.Label column sm={4}>
                                        City
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Select defaultValue="Choose..." id='city_id'>
                                            <option value="">Choose...</option>
                                            { getcity.map( getcity =>(
                                                <option value={getcity.city_name}>{getcity.city_name}</option>
                                            ) ) }
                                            
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3 zonebtns" controlId="">
                                    <Button className='jbapplybtn' type="submit" onClick={() => GetFormData()}>
                                        Apply
                                    </Button>
                                    <Button className='clrbtn' type="submit" onClick={() => ResetFormData()}>
                                        Clear
                                    </Button>
                                </Form.Group>
                            {/* </Form> */}
                        </div>)
                    }
                    
                    
                </div>
                    {component ? "" : 
                <div className='row ptb_50' id='showjobdata'>
                        { jobdata.map( jobdata => (
                            <div className='col-sm-4'>
                                <div className="joblistbox">
                                    <div className="job_list">
                                        <img src={window.IMGURL + jobdata.company_logo} alt={ jobdata.job_title }/>
                                        <div className="job_text">
                                            <div className="job_lr">
                                                <div className="job-left" >
                                                    <h4>{ jobdata.job_title }</h4>
                                                    <ul>
                                                        <li><FmdGoodIcon /> { jobdata.city }</li>
                                                        <li><span className="c_dot"></span> { jobdata.job_type }</li>
                                                    </ul>
                                                    <p className="job_prc"> { jobdata.package_currency } { jobdata.package_range_start } - { jobdata.package_range_end } per month </p>
                                                </div>
                                            </div>
                                            <div className="jb_close">
                                                <p>Application closes on: {moment(jobdata.application_end).format("DD MMM’YYYY h:mm a")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="dtl_btn" onClick={() => setModalShow(jobdata._id)}> Apply Now</button>
                                </div>
                                </div>
                        )) } 
                       
                         
                </div>
                }
                <div className='row ptb_50'>{component}</div>

            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                data={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}



