import React, { useState } from "react";
import refer from "./images/refer.png";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Instagram from '@mui/icons-material/Instagram';

const Refer = () => {
    const [copySuccess, setCopySuccess] = useState(null);
    let obj = JSON.parse( localStorage.getItem('loginddata') );
    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            alert('Copied!');
        } 
        catch (err) {
            alert('Failed to copy!');
        }
     };
    return (
        <> 
            <div className="refer-sec">
                <h3 className="dash_hdng">Refer & Earn</h3>
                <div className="cardbox">
                    <div className="d-flex align-items-center referbx">
                        <img src={refer} />
                        <div className="refer_txt">
                            <h5>Refer Your Friend</h5>
                            <span>Get Upto 10% on plan upgrade</span>
                            <p className="refercode"> {obj.referral_code}</p>
                            <a href='#!' onClick={(e) => copyToClipBoard(obj.referral_code)} className="copy"> Tap to copy</a>
                            <h6>Share</h6>
                            <div className="sharelist">
                              <ul className="d-flex">
                                <li><a target="_blank" href={'http://twitter.com/share?text=Referral-code'+obj.referral_code+'&url='+window.APPURL}> <TwitterIcon className="twitter"/> </a> </li>
                                <li><a target="_blank" href={'http://www.linkedin.com/shareArticle?mini=true&url='+window.APPURL+'&title=Referral-code'+obj.referral_code+'&summary=&source='}> <LinkedInIcon className="linkedin"/> </a> </li>
                                <li><a target="_blank" href={'https://www.facebook.com/sharer/sharer.php?u='+window.APPURL+'&title=Referral-code'+obj.referral_code}> <FacebookOutlinedIcon className="facebook"/></a>  </li>
                                <li><a target="_blank" href={'http://twitter.com/share?text=Referral-code'+obj.referral_code+'&url='+window.APPURL}> <Instagram className="instagram"/> </a> </li>
                                <li><a target="_blank" href={'https://web.whatsapp.com/send?text=Referral-code: '+obj.referral_code}> <WhatsAppIcon className="whatsapp"/></a>  </li>
                              </ul>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default Refer;