import React, { useState, useEffect } from 'react';
import axios from "axios";
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { enquirysubmit, courselistdropdown, statebycountryname, citiesbystatename } from './Api_list';
let color = {};
const Contact_form = () => {

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailid, setEmailid] = useState("");
  const [email, setEmail] = useState("");

  const [messageData, setMessageData] = useState("");
  const [message, setMessage] = useState("");
  const [statelist, setStatelist] = useState([]);
  const [courselist, setCourselist] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [cityDropdown, setCityDropdown] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerData = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: window.HEADERSAUTH,
          },
        };

        const stateResponse = await axios.get(statebycountryname, headerData);
        setStatelist(Array.isArray(stateResponse.data) ? stateResponse.data : []);
        const courseResponse = await axios.get(courselistdropdown, headerData);
        setCourselist(Array.isArray(courseResponse.data) ? courseResponse.data : []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (selectedState) {
      const encodedState = encodeURIComponent(selectedState);

      axios.get(`${citiesbystatename}${encodedState}`)
        .then(response => {
          setCityDropdown(Array.isArray(response.data) ? response.data : []);
        })
        .catch(error => {
          console.error('Error fetching city data:', error);
        });
    } else {
      setCityDropdown([]);
    }
  }, [selectedState]);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };


  let handleSubmit = async (e) => {
    e.preventDefault();
    const post = {
      full_name: name,
      email_id: email,
      looking_for: "",
      type: 'contact_us',
      message_data: messageData,
      mobile_no: mobileNumber,
      course_name: selectedCourse,
      state_name: selectedState,
      city_name: selectedCity
    }
    if ((name.length != 0) && (mobileNumber.length === 10)) {
      try {
        var headerData = {
          "headers": {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }
        }
        const res = await axios.post(enquirysubmit, post, headerData)
        if (res.status === 200) {
          color.color = 'white';
          setName("");
          setMobileNumber("");
          setEmail(""); setMessageData("");
          setSelectedCourse('');
          setSelectedState('');
          setSelectedCity('');
          setMessage('Thank you, your call back request has been submitted successfully!');
          setTimeout(() => {
            setMessage('');
          }, 5000);
        } else {
          color.color = 'red';
          setMessage("Some error occured");
          setTimeout(() => {
            setMessage('');
          }, 5000);
        }
      } catch (e) {
        color.color = 'red';
        setMessage("Some error occured");
        setTimeout(() => {
          setMessage('');
        }, 5000);
      }
    } else {
      color.color = 'red';
      setMessage("Please enter valid mobile number");
      setTimeout(() => {
        setMessage('');
      }, 5000);
    }
  };

  return (
    <>
      <div className='contfrm_wrp'>
        <div className="message">{message ? <p style={color}>{message}</p> : null}</div>
        <h3 className='form_hdng text-left'>Send your query</h3>
        <Form className='row' onSubmit={handleSubmit}>

          <Col className="mb-3 col-sm-6">
            <FloatingLabel controlId="floatingInputGrid" label="Full Name">
              <Form.Control type="text" onChange={(e) => setName(e.target.value)} value={name} placeholder="Full Name" />
            </FloatingLabel>
          </Col>
          <Col className="mb-3 col-sm-6">
            <FloatingLabel controlId="floatingInputGrid" label="Contact Number">
              <Form.Control type="text" onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} maxLength={10} placeholder="Contact Number" />
            </FloatingLabel>
          </Col>
          
          <Col className="mb-3 col-sm-12">
            <FloatingLabel controlId="floatingInputGrid" label="Email address">
              <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="name@example.com" />
            </FloatingLabel>
          </Col>
          <Col className="mb-3 col-sm-12">
              <Form.Control as="select"  onChange={handleCourseChange} value={selectedCourse}>
              <option>--Choose Course--</option>
                        {courselist.map((course) => (
                          <option key={course.id} value={course.course_name}>
                            {course.course_name} 
                          </option> 
                        ))}
              </Form.Control>
          </Col>
          <Col className="mb-3 col-sm-6">
          <Form.Control as="select"  onChange={handleStateChange} value={selectedState}>
          <option>--Choose State--</option>
              {statelist.map((state) => (
                <option key={state.id} value={state.state_name}>
                  {state.state_name}
                </option>
              ))}
              </Form.Control>
          </Col>
          <Col className="mb-3 col-sm-6">
          <Form.Control as="select"  onChange={handleCityChange} value={selectedCity}>
          <option>--Choose City--</option>
                        {cityDropdown.map((city) => (
                          <option key={city.id} value={city.city_name}>
                            {city.city_name}
                          </option>
                        ))}
              </Form.Control>
          </Col>
          <Col className='col-sm-12 mb-3'>
            <FloatingLabel controlId="floatingTextarea2" label="Comments">
              <Form.Control
                onChange={(e) => setMessageData(e.target.value)} value={messageData}
                as="textarea"
                placeholder="Message"
                style={{ height: '100px' }}
              />
            </FloatingLabel>
          </Col>
          <Col className="mb-3 col-sm-12">
            <Button variant="primary" type="submit">
              Apply Now
            </Button>
          </Col>
        </Form>
      </div>
    </>
  );
}

export default Contact_form;