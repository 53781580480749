import React from "react";
import axios from "axios";
import Faq from "./University_faq";
import Uni_brief from "./Uni_brief"; 
import Count from "./Cmpnt/Count_us";
import Course_uni from "./Course_uni";  
import Councelor from "./Cmpnt/Councelor";
import { Helmet } from "react-helmet";
import { univdetails, websetting} from "./Api_list";
let slug = document.URL.split("/")[3];
const Uni_details = () => {
    const [ post, setPost ] = React.useState( null );
    const [Qeury, setQeury] = React.useState(null);
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}  
        axios.get(univdetails+slug, headerData).then(( response )=>{
            setPost( response.data );
        })
        axios.get(websetting, headerData).then(( response )=>{
            setQeury( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data; 
    if (!Qeury) return null;
  let settingData = Qeury.data;

  let arr = getData.univ_courses;
console.log(arr)
    return (
        <>
        <Helmet>
            <title>{(getData != null) ? getData.meta_title : 'Careereduversity'}</title> 
            <meta name="title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta name="description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta name="keywords" content={(getData != null) ? getData.meta_keyword : 'Careereduversity'} />
            <meta property="og:title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta property="og:image" content={(getData != null) ?  window.IMGURL + getData.image : 'Careereduversity'}/>
            <meta property="og:description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta property="og:url" content={window.APPURL+slug} />
        </Helmet>
            <Uni_brief data={getData} setting={settingData}/>
            <section class="univ-texts pb-4">
                <div class="container">
                    <div class="row">
                        <h2 class="sitehdng"> About <span>College</span></h2>
                    </div>
                    <div className="row">
                    <div dangerouslySetInnerHTML={{ __html: getData.univ_about }} />
                     </div>
                </div>
            </section>
            <Councelor />
            { (arr.length > 0) ? <Course_uni data={getData}/> : "" }
            
            <Count />
            <Faq data={getData}/>

            {/* <Studentslider />
            <Admission />
            <Query />
            <Footer /> */}
        </>
    )
}
export default Uni_details;
