import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";

import Home from "./Home";
import Why_sbs from "./Why_sbs";
import College_listing from "./College_listing";
import Blog_listing from "./Blog_list";
import Blog_details from "./Blog_details";

import Course_details from "./Course_details";
import Job_internship from "./Job_internship"; 
import Hire_sbs from "./Hire_sbs";
import Plans from "./Plans";
import About_us from "./About_us";
import Know_interest from "./Know_interest";
// import Profile from "./Profile";
import Contact_us from "./Contact_us";
import Advisory_board from "./Advisory_board";
import Login from "./Login";
import Create_profile from "./Register";
import Navbar from "./Navbar"; 

//Terms
import Terms from "./Terms";
import Privacy from "./Privacy";
import Refund from "./Refund";
import Checkout from "./Checkout";
import Success from "./Success";
import Failure from "./Failure";

//Student Dashboard 
import Navbar_dash from "./Dashboard/Dash-nav";
import Dash from "./Dashboard/Dash";
import JobApp from "./Dashboard/Job-application";
import AppliedInstitute from './Dashboard/Applied-institute';
import JobListing from './Dashboard/Job-listing';
// import Joblistbox from './Dashboard/Job-list-box';
import Book_appointment from './Dashboard/Book-appointment';
import Notfication_details from './Dashboard/Notification_details';
import Blogs_articles from './Dashboard/Latest-blogs-articles';
import ProfilePage from './Dashboard/ProfilePage';
import Internship_form from './Dashboard/Internship_form';
import Chat from './Dashboard/Chat';
import Appointment_listing from './Dashboard/Appointment-listing'
import Uni_details from "./University";
import { Route, Routes, Navigate } from "react-router-dom";

window.IMGURL = "https://sindonbschool.com/uploads/";
window.APIURL = "https://sindonbschool.com/api/";
window.BASEURL = "https://sindonbschool.com/";
// window.APPURL = "http://localhost:3000/";
window.APPURL = "https://careereduversity.com/";
window.CARENO = "9800000000";
window.HEADERSAUTH = "Bearer eyJhbGciOiJIUzI1NiJ9.ew0KICAic3ViIjogImh0dHBzOi8vY2FyZWVyZWR1dmVyc2l0eS5jb20vIiwNCiAgIm5hbWUiOiAiUmFtIiwNCiAgImlhdCI6ICIxNTg5OTcyNzI2Ig0KfQ.nN1dPzK30FOHZlAGTEMcqiySkaxbb81KdOhOYVsn_5Q";
let slug = document.URL.split("/")[3];
const App = () => {
    return(
        <> 
        { ((slug != 'student') || (slug === '')) ? <Navbar /> : "" }
        { (slug === 'student') ? <Navbar_dash/> : "" }
        {/* <Navbar_dash/> */}
        <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route exact path='/why-career-eduversity' element={<Why_sbs/>} />
            <Route exact path='/college-listing' element={<College_listing/>} />
            <Route exact path='/blog-list' element={<Blog_listing/>} />
            
            <Route exact path='/:name/:name' element={<Course_details/>} /> 
            <Route exact path='/job-internship' element={<Job_internship/>} />
            <Route exact path='/hire-career-eduversity' element={<Hire_sbs/>} />
            <Route exact path='/plans' element={<Plans/>} />
            <Route exact path='/about-us' element={<About_us/>} />
            <Route exact path='/advisory-board' element={<Advisory_board/>} />
            <Route exact path='/contact-us' element={<Contact_us/>} />
            <Route exact path='/login' element={<Login/>} />
            <Route exact path='/register' element={<Create_profile/>} />
            {/* <Route exact path='/know-interest' element={<Know_interest/>} /> */}

            <Route exact path='/terms-and-conditions' element={<Terms/>} />
            <Route exact path='/privacy-policy' element={<Privacy/>} />
            <Route exact path='/refund-policy' element={<Refund/>} />
            {/* <Route exact path='/profile' element={<Profile/>} /> */}
            <Route exact path='/services/:name' element={<Know_interest/>} />
            <Route exact path='/checkout' element={<Checkout/>} />
            <Route exact path='/payment' element={<Success/>} />
            <Route exact path='/failure' element={<Failure/>} />
            {/* <Route exact path='/university' element={<Uni_details/>} /> */}
            <Route exact path='/:name' element={<Blog_details/>} />


            //Student Panel 
 
            <Route exact path='/student/dashboard' element={<Dash/>} />
            <Route exact path='/student/job-application' element={<JobApp/>} />
            <Route exact path='/student/applied-institute' element={<AppliedInstitute/>} />
            <Route exact path='/student/job-listing' element={<JobListing/>} />
            <Route exact path='/student/book-appointment/:name/:name' element={<Book_appointment/>} />
            <Route exact path='/notification_details' element={<Notfication_details/>} />
            <Route exact path='/student/latest-blogs-articles' element={<Blogs_articles/>} />
            <Route exact path='/student/profilepage' element={<ProfilePage/>} />
            <Route exact path='/student/internship-form' element={<Internship_form/>} />
            <Route exact path='/student/chat/:name' element={<Chat/>} />
            <Route exact path='/student/appointment-listing' element={<Appointment_listing/>} />
            
            {/* <Route exact path='/student/joblistbox' element={<Joblistbox/>} /> */}
          </Routes> 
          
        </>
    );
};
export default App;