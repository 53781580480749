import React , { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";

import { alumnilist } from "./Api_list";
const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4
};

const Topcompanyslider = () => {
    const [ post, setPost ] = useState(null);
    useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH, 
          }} 
        axios.get(alumnilist+'Alumni', headerData).then(( resData )=>{
            setPost( resData.data )
        })
    }, [])
    if( !post ) return null;
    let getData = post.data; 

    return (
        <>
            <div className="container alm_slider">
                <Slider {...settings}>
                { getData.map( getData =>(
                    <div className="uni_slide">
                        <div className="uni_img">
                            <img src={ window.IMGURL + getData.company_logo} alt={getData.company_name}/>
                        </div>
                    </div>
                     )) }
                    
                </Slider>
            </div>
        </> 
    )
}
export default Topcompanyslider;

