import React from "react";
import Footer from "./partial/Footer";
import Query from "./Cmpnt/Query_sec";
import About_top from "./aboutus/About_top_bg"
import Aboutsec from "./aboutus/Abt_sec";
import Admission from "./Admission_process";
import Core_team from "./Cmpnt/Core_team";
import Coachslider from "./Cmpnt/Coach-slider";
import Seo from './Cmpnt/Seo';
const About_us = ( props ) => {
    return (
        <>
        <Seo seopage='about-us' />  
        <About_top/>
        <Aboutsec />
        <Core_team/>
        <Coachslider />
        <Admission/>
        <Query />
        <Footer />
        </>
    )
}

export default About_us;
