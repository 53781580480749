import React from "react";
import axios from "axios";
import Footer from "./partial/Footer";
import Query from "./Cmpnt/Query_sec";
import Admission from "./Admission_process";

import Course_home from "./courses/Course_home";
import Approved from "./Approved";
import Studentslider from "./Cmpnt/Student_trust";
import Listtop_bg from "./College_list_topbg";

import Benefit from "./Cmpnt/Benefits";
import Unislider from "./Top_uni_slider";
import Alumnislider from "./Alumni_slider";
import { cmsdetail } from "./Api_list";
import { Helmet } from "react-helmet";

const College_listing = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}  
        axios.get(cmsdetail+'college-by-course', headerData).then(( response )=>{
            setPost( response.data );
        })
    }, []) 
    if( !post ) return null;
    let getData = post.data; 
    return (
        <>
        <Helmet>
            <title>{(getData != null) ? getData.meta_title : 'Careereduversity'}</title> 
            <meta name="title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta name="description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta name="keywords" content={(getData != null) ? getData.meta_keyword : 'Careereduversity'} />
            <meta property="og:title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta property="og:image" content={(getData != null) ?  window.IMGURL + getData.image : 'Careereduversity'}/>
            <meta property="og:description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta property="og:url" content={window.APPURL} />
        </Helmet>
            <Listtop_bg/>
            <div className="container topuni_sec">
                <div className="row centr_hdng_txt">
                    <h2 className="sitehdng"> Programs in <span>collaboration</span> with top <span>universities</span> and <span>MNCs</span> </h2>
                </div>
                <Unislider />
            </div>
            <Course_home />

            <Approved />
            <Admission />
            <Benefit />
            <Studentslider />

            <div className="container topuni_sec padd_30">
                <div className="row centr_hdng_txt">
                    <h2 className="sitehdng"> Where our alumni work </h2>
                </div>
                <Alumnislider />
            </div>

            <Query />
            <Footer />


        </>
    )
}

export default College_listing;
