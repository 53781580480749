import React from 'react';
import mentor from './images/mentor.png';
import jobportal from './images/jobportl.png';

const Job_portal = () => {
    return (
        <>
            <div className="container job_sect padd_50">
                <div className="row hdngtxt text-center">
                    <h3 className='site_hdng'>Career Eduversity dedicated portal</h3>
                    <p>Now getting job and internship easy</p>
                </div>
                <div className="row jobtypes">
                    <div className="col-md-6">
                        <div className='mentr'>
                            <h4>Connect with mentors</h4>
                            <div className="imgs"><img src={mentor} /></div>
                        </div>
                    </div>
                    <div className="col-md-6"> 
                        <div className='jobportl'>
                            <h4>Dedicated Job portal</h4>
                            <div className="imgs"><img src={jobportal} /></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Job_portal;