import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { studentappointmentlist, appointmentdetails, studentmeetingfeedback } from '../Api_list';
import MyVerticallyCenteredModal from './Feedback-popup';
import DataTable from 'react-data-table-component';
let obj = JSON.parse( localStorage.getItem('loginddata') );


const columns = [
    {
        name: 'Sno.',
        selector: row => row.sno,
        sortable: true,
    },
    {
        name: 'Student Name',
        selector: row => row.sname,
        sortable: true,
    },
    {
        name: 'HR/Councelor Name',
        selector: row => row.hrname,
        sortable: true,
    },
    {
        name: 'Appointment Date & Time',
        selector: row => row.datetime,
        sortable: true,
    },
    {
        name: 'Meeting Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Action',
        selector: row => row.action,
        sortable: true,
        // cell: (row) => (
        //     <>
        //       <button className='vw_btn'>View Feedback</button> 
        //       <button className='tblbtn'>Rate & Review</button>
        //     </>
        // )
    },
];



const AppointmentListing = () => {
// alert(studentappointmentlist+obj._id)
    const [ appoint, setAppoint ]            = useState(null);
    const [ Tabledata, setTabledata ]        = useState('');
    const [modalShow, setModalShow]          = React.useState(false);
    const [ timeslotid, setTimeslotid ]      = useState('');
    const [ appointmentl, setAppointmentl ]  = useState('');
    const [ retings, setRetings ]            = useState(4);
 

    useEffect(()=>{
        axios.get(studentappointmentlist+obj._id).then(( responsefirst )=>{
            setAppoint( responsefirst.data.data )
        })
        
     }, [])
     if( !appoint ) return null;
     let getData = appoint;
    

    let giveFeedback = async (a, b) => {
        try {
            const responses = await axios.get(appointmentdetails+a+'&time_slot_id='+b)
            if (responses.status === 200) {
                setAppointmentl(responses.data.data)
                setModalShow(true)
                setTabledata('give')
                setRetings(4)
                  } else {
                   
                  } 
          } catch (e) {
           
          }
    }
    let vieFeedback = async (a, b) => {
        try {
            const responses = await axios.get(appointmentdetails+a+'&time_slot_id='+b)
            if (responses.status === 200) {
                setAppointmentl(responses.data.data)
                setModalShow(true)
                setTabledata('view')
                setRetings(responses.data.data.student_rate)
                  } else {
                   
                  } 
          } catch (e) {
           
          }
    }
    

    
    return ( 
        <div className="job_intro_container">
            <div className="container">
                <div className="row">
                     
                </div>
                <div className='row cardbox'>
                    <h5>Appointment List</h5>
                    <DataTable
                        columns={columns}
                        data={ 
                            getData.map( 
                                (appointmentl,index) => (
                            { 
                                sno: index+1,
                                sname: appointmentl.student_name,
                                hrname: appointmentl.user_name,
                                datetime: moment(appointmentl.timeslot_date).format("DD/MMM/YYYY"),
                                status: appointmentl.meeting_status,
                                action :  (appointmentl.student_feedback) ?  <button onClick={()=>{vieFeedback(appointmentl._id, appointmentl.time_slot_id)}} className="vw_btn">View Feedback</button> : <button onClick={()=>{giveFeedback(appointmentl._id, appointmentl.time_slot_id)}} className='tblbtn'>Rate & Review</button>,
                            } )) 
                        }
                        selectableRowsHighlight
                        highlightOnHover
                    />
                   
                </div>
            </div>
            <MyVerticallyCenteredModal
                                    show={modalShow}
                                    appointmentl={appointmentl}
                                    pptype={Tabledata}
                                    ratingss = {retings}
                                    onHide={() => setModalShow(false)}
                                />
        </div>
        
    )
}

export default AppointmentListing;
