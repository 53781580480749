import React from "react";
import axios from "axios";
import Footer from "./partial/Footer";
import Query from "./Cmpnt/Query_sec";
import Admission from "./Admission_process";
import Studentslider from "./Cmpnt/Student_trust";
import Blog_dtltext from "./blog/Blog_details_text";
import University from "./University";
import { cmsdetail } from "./Api_list";
let slug = document.URL.split("/")[3];

const Blog_details = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}  
        axios.get(cmsdetail+slug, headerData).then(( response )=>{
            setPost( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data; 
    let pagetype = (getData) ? 'blog' : 'university';
    return (
        <>
        {(pagetype === 'blog') ?  <Blog_dtltext data={getData}/> : <University />}
            
            <Studentslider />
            <Admission />
            <Query />
            <Footer />
        </>
    )
}

export default Blog_details;




