import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Choose_course from "./Choose_course";
import { statelist, citieslist, registerfirst, validatereferal } from './Api_list';
let color = {};
const Create_profile = () => { 
    const [showReg, setShowReg] = useState(true)
    const [showResults, setShowResults] = useState(false)
    const [name, setname]           = useState("");
    const [email, setEmail]         = useState("");
    const [dob, setDob]             = useState("");
    const [gender, setGender]       = useState("");
    const [city, setCity]           = useState("");
    const [state, setState]         = useState("");
    const [address, setAddress]     = useState("");
    const [referral, setReferral]   = useState("");
    const [component, setComponent] = useState(null);
    const [message, setMessage]     = useState("");
    const [post, setPost]           = useState( null )


    React.useEffect(()=>{
        axios.get(statelist).then((responses)=>{
            setPost( responses.data )
        })
    },[])
    if( !post )  return null;
    let stateData = post.data;

    function handleChange(event){
        setState(event.target.value)
        let  options = ""; 
        if (typeof event.target.value != 'undefined') {
            options = event.target.value;
          }
          axios.get( citieslist+options).then((responses)=>{
            let  getData2  = responses.data.data;
             let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                <option value={getData2.city_name}>{getData2.city_name}</option>
              ))) : "";
              setComponent(storecorse);
         })
    }
    function handleGender(event){
        setGender(event.target.value)
    }
    function handleCity(event){
        setCity(event.target.value)
    }    
    let handleSubmit = async (e) => {
        e.preventDefault();
        const post = {
            id          : localStorage.getItem('userid'), 
            full_name   : name, 
            email_id    : email, 
            d_o_b       : dob,
            gender      : gender,
            city_name   : city,
            state_name  : state,
            full_address : address,
            referral    : referral
          }
         
        try {
          const res = await axios.post(registerfirst, post)
          if (res.status === 200) {
            setShowReg(false);
            setShowResults(true)
            color.color = 'green';
                setMessage(res.data.message);
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              } else {
                color.color = 'red';
                setMessage(res.data.message);
                setTimeout(() => {
                  setMessage('');
                }, 5000);
              }
        } catch (e) {
          color.color = 'red';
          setMessage("Some error occured");
          setTimeout(() => {
            setMessage('');
          }, 5000);
        }
     
      };
      const handleKey = (event) => {
        if(referral.length === 10){
            axios.get(validatereferal+referral)
            .then(res => {
             console.log(res.data);
             if(res.data.status ===  true){
                color.color = 'green';
                setMessage(res.data.message);
                setTimeout(() => {
                  setMessage('');
                }, 5000);
             }else{
                setReferral('')
                color.color = 'red';
                setMessage(res.data.message);
                setTimeout(() => {
                  setMessage('');
                }, 5000);
             }
            })

            // try {
            //     const res = axios.get(validatereferal+referral)
            //     console.log(res);
            //     if (res.status === 200) {
            //         alert('yes');
            //         } else {
            //          alert('no');
            //         }
            //   } catch (e) {
            //     color.color = 'red';
            //     setMessage("Some error occured");
            //     setTimeout(() => {
            //       setMessage('');
            //     }, 5000);
            //   }
           
        }
    }
    return (
        <>
        {/* {localStorage.getItem('userid')} */} 
        { showReg ?
            <section className="loginsec">
                <div className="container padd_50">
                    <div className="profileform">
                        <h3>Create Your Profile</h3>
                        <div className="message">{message ? <p style={color}>{message}</p> : null}</div> 

                        <Form className="row" onSubmit={handleSubmit}>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Full name</Form.Label>
                                <Form.Control onChange={(e) => setname(e.target.value)} value={name} type="text" placeholder="Enter your name" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Mobile number</Form.Label>
                                <Form.Control type="text" disabled placeholder="+91-91011011001" value={localStorage.getItem('mobileno')}/>
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Email ID</Form.Label>
                                <Form.Control onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Enter your email ID" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Date Of Birth</Form.Label>
                                <Form.Control onChange={(e) => setDob(e.target.value)} value={dob} type="date" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={handleGender}>
                                    <option>Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>State</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={handleChange}>
                                    <option>Select State</option>
                                        { stateData.map(stateData=>(
                                            <option value={stateData.state_name}>{stateData.state_name}</option>
                                        )) }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>City</Form.Label>
                                <Form.Select aria-label="Select first State" onChange={handleCity}>
                                    {component}
                                </Form.Select>
                                <Form.Label className="mt-3">Referral Code</Form.Label>
                                <Form.Control onChange={(e) => setReferral(e.target.value)} value={referral} onKeyUp={handleKey} maxLength={10} type="text" placeholder="Enter referral code (if any)" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6" controlId="formBasicEmail">
                                <Form.Label>Complete Address</Form.Label>
                                <Form.Control as="textarea"  onChange={(e) => setAddress(e.target.value)} value={address} placeholder="Complete Address here" style={{ height: '135px' }} />
                            </Form.Group>

                            
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Form>
                    </div>
                </div>
            </section> : null }
            { showResults ? <Choose_course /> : null }
            {/* <Choose_course /> */}
        </>
    )
}
export default Create_profile;
