import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import c1 from "./images/c1.png";
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import MyVerticallyCenteredModal from './Feedback-popup';  
import { timeslot, userDetails, booktimeslot } from '../Api_list';
let stdData =  JSON.parse( localStorage.getItem('loginddata') );
let phone = document.URL.split("/")[5];
let utype = document.URL.split("/")[6];
// alert(utype)
let color = {};
const Book_appointment = () => {
    const d = (phone === 'phone-call') ? 'Phone Call' : 'Video';
    const usertype  = (utype === 'career-coach') ? stdData.counselor_id : stdData.hr_id;
    const [modalShow, setModalShow]         = useState(false);
    const [meetingtype, setMeetingtype]     = useState(d);
    const [ slotData, setSlotData ]         = useState([]);
    const [value, onChange]                 = useState(new Date());
    const [component, setComponent]         = useState(null);
    const [bookingdate, setBookingdate]     = useState();
    const [ hrdata, setHrdata ]             = useState( null );
    const [Shedule, setShedule]             = useState(null);
    const [timeslotid , seTimeslotid]       = useState("");
    const [message, setMessage]             = useState("");
        useEffect(()=>{ 
            var newDate = moment(new Date(), "YYYY-MM-DD");
            var d = moment(newDate).format("YYYY-MM-DD");
            setBookingdate(d);
            axios.get(timeslot+usertype+'&&confg_date='+d).then(( resData )=>{
                setSlotData( resData.data )
            })
            axios.get( userDetails+usertype ).then(( response )=>{
                setHrdata( response.data );
            })
            
        }, [])
        if( !slotData ) return null;
        let Getdata = ( slotData.status === true ) ?  slotData.data[0].time_slot : [];
        if( !hrdata ) return null;
        let hData = hrdata.data;
        const onDateChange=(newDate)=>{
        onChange(newDate);
        var d = moment(newDate).format("YYYY-MM-DD");
        setBookingdate(d);
        settimepicker( d);
       };
       function settimepicker( d ){
        axios.get(timeslot+usertype+'&&confg_date='+d).then(( resData )=>{
            setSlotData( resData.data )
            let  getData2  = [];
            if (resData.data.status === true){
                getData2  =   resData.data.data[0].time_slot ;
            }
              
                let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                    <li>
                        <input type="radio" id="a25" name="amount" onClick={()=>{GetDetails(getData2.timeslot_from,getData2.timeslot_to,getData2._id)}} value={getData2._id} disabled= {getData2.status ? true : false}  />
                        <label for="a25">{getData2.timeslot_from}-{getData2.timeslot_to} </label>
                    </li>
                ))) : "";
                setComponent(storecorse);
        })
    }

    function GetDetails(a, b, c){
        seTimeslotid(c);
        setShedule(a+'-'+b+" "+moment(bookingdate).format("MMMM,DD,YYYY"))
    }
  
    let bookAppointment = async (e) => {
        if(timeslotid.length > 0 && meetingtype.length > 0){
        let id = slotData ? slotData.data[0]._id : "";
        let postData = {
            id : id,
            timeslotid : timeslotid,
            meeting_type : meetingtype,
            student_name : stdData.full_name,
            student_id : stdData._id
        }
        try {
            const responses = await axios.post(booktimeslot, postData)
            if (responses.status === 200) {
                color.color = 'green';
                    setMessage(responses.data.message);
                    setTimeout(() => {
                      setMessage('');
                    }, 5000);
                  } else {
                    color.color = 'red';
                    setMessage(responses.data.message);
                    setTimeout(() => {
                      setMessage('');
                    }, 5000);
                  } 
          } catch (e) {
            color.color = 'red';
            setMessage("Something Went Wrong!");
          }
        }else{
            color.color = 'red';
            setMessage("Please select all mandatory fields!");  
            setTimeout(() => {
                setMessage('');
              }, 5000);
        }

    }
    // alert(meetingtype)
    return (
        <>
            <div className='container ptb_50'>
                <div className="appointmnt_sec cardbox">
                    <div className="left_appointmnet">
                        <div className="d-flex align-items-center coachprfl">
                            <img src={(hData.profile_pic) ? window.IMGURL + hData.profile_pic : ""}  alt='data'/>
                            <div className="coach_txt">
                                <h5>{hData.full_name}</h5>
                                {(utype === 'hr-coach') ? 
                                <p>HR Coach</p>
                                : ""}
                                {(utype === 'career-coach') ? 
                                <p>Career Coach</p>
                                : ""}
                            </div>
                        </div>
                        <h5>{hData.full_name}</h5>
                                {(utype === 'hr-coach') ? 
                                <h4>HR Appointment (15 minutes)</h4>
                                : ""}
                                {(utype === 'career-coach') ? 
                                <h4>Career Appointment (15 minutes)</h4>
                                : ""}
                        
                        <p><WatchLaterIcon />15 min</p>
                        <p><VideoCallOutlinedIcon />Video/Web conferencing</p>
                        <p>Choose Call Type</p>
                        <div className='choose-av'>
                            <div className='rdbnt'>
                                { (phone === 'phone-call') ? 
                              <input type='radio' name='calltype' onClick={(e) => setMeetingtype('Phone Call')} id='audio' value="Phone Call" checked/>
                              : "" }
                              { (phone === 'video') ? 
                              <input type='radio' name='calltype' onClick={(e) => setMeetingtype('Phone Call')} id='audio' value="Phone Call" />
                              : "" }
                               <label for="audio">Audio</label>
                            </div>
                            <div className='rdbnt'>
                            { (phone === 'phone-call') ? 
                              <input type='radio' name='calltype' onClick={(e) => setMeetingtype('Video')} id='video'  value="Video" />
                              : "" }
                              { (phone === 'video') ? 
                              <input type='radio' name='calltype' onClick={(e) => setMeetingtype('Video')} id='video'  value="Video" checked/>
                              : "" }
                              <label for="video">Video</label>
                            </div>
                        </div>
                        <div className="scdul">
                            <h5> Schedule</h5>
                            <p className="scdl_time"><CalendarMonthOutlinedIcon /> {Shedule}</p>
                        </div>
                        <div className='apnttxt'>
                            <p> Lorem ipsum dolor sit amet. Cum quidem fuga et quos voluptatem et temporibus aliquam. Nam corrupti galisum et modi esse sed recusandae alias?
                                Aut nisi ducimus sed fugiat facere est voluptas delectus quo distinctio delectus? Aut perferendis saepe ut aperiam minus hic iusto odit et
                                velit sunt.
                            </p>
                        </div>
                    </div>
                    <div className="right_appointmnt">
                        <h4>Schedule</h4>{message ? <p style={color}>{message}</p> : null}
                        <h5>Select Date & Time</h5>
                        <div className="d-flex calndr_time">
                            <Calendar className="custm_calender" minDate={moment().toDate()} onChange={onDateChange} value={value} />
                            <div className="time_slot">
                                <h6>Available time slot</h6> 
                                {/* <span className="timeset">1:30 PM</span>
                                <span className="timeset">2:00 PM</span>
                                <span className="timeset">2:30 PM</span> */}
                                <ul className="time_slot">
                                {component ? "" : 
                                         Getdata.map( timelist => (
                                            <li>
                                            <input type="radio" id="a25" name="amount" onClick={()=>{GetDetails(timelist.timeslot_from,timelist.timeslot_to, timelist._id)}}  value={timelist._id} disabled= {timelist.status ? true : false} />
                                            <label for="a25">{timelist.timeslot_from}-{timelist.timeslot_to}</label>
                                            </li>
                                        )) 
                                        }

                                    {component}
                                </ul>
                                {/* <ul className="time_slot">
                               
                                </ul> */}
                            </div>
                        </div> 
                        <div className="sdlbtnn">
                        <button className="applybtn" onClick={() => bookAppointment(true)}>Schedule Appointment</button>
                            {/* <button className="applybtn" onClick={() => setModalShow(true)}>Schedule Appointment</button>
                            <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Book_appointment;
