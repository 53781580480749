import React from "react";
import cli1 from "../src/images/cli1.png";
import cli2 from "../src/images/cli2.png";
import cli3 from "../src/images/cli3.png";
import cli4 from "../src/images/cli4.png";

const Listtop_bg = () => {
    return (
        <>
            <section className="list_topbg whysbs_top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div class="txt_img">  
                                <h2 className="sitehdng"><span>A wide network </span> of institutions redefining <span>up-skilling</span> and  <span>educations.</span> </h2>
                                <p>Empowering students with world-class certifications and degree programs. Kickstart your career 100+ job opportunies. .</p>
                                <a href="" className="sitebtn"> Explore courses</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container padd_30">
                <div className="row">
                    <div className="col-sm-9 m-auto">
                       <div className="clg_listftr d-flex">
                          <div className="cl_ftr d-flex">
                             <img src={cli1} />
                             <p>Renowned colleges and universities network</p>
                          </div>
                          <div className="cl_ftr d-flex">
                             <img src={cli2} />
                             <p>Dedicated mentors network</p>
                          </div>
                          <div className="cl_ftr d-flex">
                             <img src={cli3} />
                             <p>Global hiring partners</p>
                          </div>
                          <div className="cl_ftr d-flex">
                             <img src={cli4} />
                             <p>Upto 55% career hike </p>
                          </div>
                       </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Listtop_bg;