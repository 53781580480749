import React from "react";
import axios from "axios";
import moment from "moment-timezone";
import blog1 from "../../src/images/blog1.png";
import blog2 from "../../src/images/blog2.png";
import blog3 from "../../src/images/blog3.png";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { cmslist } from "../Api_list"
const Blog_box = () => {
    
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(cmslist+'blog', headerData).then(( response )=>{
            setPost( response.data )
        })
    }, [])
    if( !post ) return null;
    let getData = post.data;
    return (
        <>
           {getData.map(getData => (
            <div className="col-sm-3">
                <div className="blogbx crs_box">
                    <div className="crsimg">
                        <img src={window.IMGURL+getData.image} />
                    </div>
                    <div className="crs_cont">
 
                        <div className="crs_ovrview">
                            <h3>{getData.titleorheading}</h3> 
                            <div class="d-flex justify-content-between by_date">
                                <span> <PersonIcon /> {(getData.add_by != '') ?  getData.add_by.split('#')[1] : 'Admin'}</span>
                                <span><CalendarMonthOutlinedIcon /> {moment(getData.added_on).format("DD/MM/YYYY")} </span>
                            </div>
                            <p>{getData.shortcontent.slice(0, 118)}</p>
                        </div>
                        <a href={getData.slug} className="cta_btn crs_btn"> Read More</a>
                    </div>
                </div>
            </div>
            ) )}
            
            
        </>
    )
}
export default Blog_box;