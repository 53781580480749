import React from 'react';
import axios from "axios";
import { cmsdetail } from '../Api_list';
const Aboutsec = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}
        axios.get(cmsdetail+'about-us', headerData).then(( response )=>{
            setPost( response.data )
        })
    },[])
  if( !post ) return null;
  let getData = post.data;
    return (
        <>
            <div className="container abt_sec padd_50">
                <div className="row abt_row">
                    <div className="col-md-4">
                        <div className='abtimg'>
                            <div className="imgs"><img src={ window.IMGURL + getData.image} alt={ getData.alt_tag} /></div>
                        </div>
                    </div>
                    <div className="col-md-8"> 
                        <div className='abttxt'>
                            <h3 className='site_hdng'>{ getData.titleorheading}</h3>
                            <div className="s" dangerouslySetInnerHTML={{ __html: getData.content }} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 abtfull_txt text-left'>
                    <div className="s" dangerouslySetInnerHTML={{ __html: getData.content_sec }} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Aboutsec;