import React from 'react';
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import { coursedetails } from "../Api_list";
let slug1 = document.URL.split("/")[3];
let slug2 = document.URL.split("/")[4];
const Faq = () => {
    const [post, setPost] = React.useState(null);
    React.useState(() => {
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios
          .get(coursedetails + slug1 + "&course_slug=" + slug2, headerData)
          .then((response) => {
            setPost(response.data);
          });
      }, []);
      if (!post) return null;
      if( typeof getData !== 'undefined' ){
      let getData = post.data.c_course_faq;
      
    return (
        <>
            <div className="container padd_50">
                <div className="row text-center">
                    <h3 className='site_hdng'>Frequently Asked Questions</h3>
                </div>
                <div className='row faq_accrdion'>
                    <Accordion defaultActiveKey="0" className=''>
                        <div className='faq_left'>
                            
                        {   getData.map( (getData, num) =>(
                            <Accordion.Item eventKey={( (num === 0) ? "0" : num)}>
                                <Accordion.Header>{ getData.question} </Accordion.Header>
                                <Accordion.Body>
                                { getData.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                           ))}
                            
                        </div>
                    </Accordion>
                </div>
            </div>

        </>
    )
                        }
}

export default Faq;