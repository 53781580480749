import React from "react";
import Topcompanyslider from "./Topcompany_slider";


const Top_companies = () => {
    return (
        <>
            <section className="container topcomp_sec padd_50">
                <div className="row">
                    <div className="col-sm-4">
                        <h2 className="sitehdng"> Top Companies <br/>being part of Career Eduversity for emerging talents </h2>
                    </div>
                    <div className="col-sm-8"> <Topcompanyslider /> </div>
                </div>
            </section>
        </>

    )
}
export default Top_companies;
