import React, { useState } from "react";
import axios from "axios";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { planlist, cartdata, couponlist, validatecoupon } from "./Api_list";  
import {load} from '@cashfreepayments/cashfree-js';
const cashfree = await load({ 
	mode: "sandbox" //or production
});

function MyVerticallyCenteredModal(props) {
    // console.log(props.totalprice)
    const [post, setPost] = useState("");
    const [copySuccess, setCopySuccess] = useState(null);
    React.useEffect(()=>{
        axios.get(couponlist+props.totalprice).then(( response )=>{
            setPost( response.data )
        })
       
    }, []);
    if( !post ) return null;
    let couponData = post.data;
      const copyToClipBoard = async copyMe => {
         try {
             await navigator.clipboard.writeText(copyMe);
             setCopySuccess('Copied!');
         } 
         catch (err) {
             setCopySuccess('Failed to copy!');
         }
      };
    return (
        <Modal className="cpn_modal"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h4>Apply Coupon</h4>
            </Modal.Header>
            <Modal.Body>
                <div className="cpn_wrpper">
                { couponData.map(couponData =>(
                    <div className="cpnbox"> 
                        <div className="cpn_amt">
                            <p>{(couponData.value_type === 'Fixed') ? 'Flat' : ""}  {couponData.cpn_value} {(couponData.value_type === 'Percentage') ? "% OFF" : ""} </p>
                        </div>
                        <div className="cpn_dtl">
                            <p className="offamnt">₹{couponData.max_discount}</p>
                            <div className="cpnbx d-flex justify-content-between">
                                <span>Code:</span>
                                <p>{couponData.coupon_code}</p>
                            </div>
                            <a href='#!' onClick={(e) => copyToClipBoard(couponData.coupon_code)}  className="apply_cpn"> Copy Coupon</a>
                        </div>
                    </div>
                   ))}
                  
                </div>
            </Modal.Body>
        </Modal>
    );
}


const Checkout_sec = () => {
    const [modalShow, setModalShow] = React.useState(false);
    let obj = JSON.parse( localStorage.getItem('loginddata') );
    const [checked, setChecked] = React.useState(true);
    const [showReg, setShowReg] = useState(false)
    const [plandata, setPlandata] = useState(null);
    const [cartlist, setCartlist] = useState("");
    const [ couponcode, setCouponcode] = useState('');
    const [ couponval, setCouponval] = useState(0);
    const [ wallate, setwallate] = useState(obj.wallet_balance);
    React.useEffect(()=>{
        axios.get(planlist).then(( response )=>{
            setPlandata( response.data )
        })
        axios.get(cartdata+obj._id).then(( response )=>{
            setCartlist( response.data )
        })
    }, []);
    if( !cartlist ) return null;
    let getData = cartlist.data;
    if( !plandata ) return null;
   
    let getPlan = plandata.data[0].plan_details;
    let getFeature =plandata.data[0].feature_master;

   const filtered = getPlan.filter(getPlan => {
     return getPlan._id === getData.plan_id;
   });
   
   let  planRecord = filtered[0];
 
   let checkCoupon = async (e) => {
    try {
        const res = await axios.get(validatecoupon+'?c_code='+couponcode+'&amount='+planRecord.plan_price)
        if (res.data.status === true) {
            setShowReg(true)
            setCouponval(res.data.cpn_value);
            } else {
                setCouponval(0);
                setShowReg(false)
            }
      } catch (e) {
        setCouponval(0);
        setShowReg(false)
      }
  }
  const handleChangeOne = () => {
    setChecked(!checked);
    if(checked == true){
        setwallate( 0 ) 
    }else{
        setwallate( obj.wallet_balance ) 
    }
  };
  let openSandbox = async (e) => {
  const planpayload = {};
  let payload = {};
//   let characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let orderid = (Math.floor(Math.random()*999999999999999+100000000000000)).toString();
 
  planpayload.user_id = obj._id; 
  planpayload.order_id = orderid;
  planpayload.plan_name = planRecord.plan_name;
  planpayload.plan_mrp = planRecord.plan_mrp;
  planpayload.plan_price = planRecord.plan_price;
  planpayload.wallet_amount = wallate;
  planpayload.coupon_amount = couponval;
  planpayload.total_amount = planRecord.plan_price - wallate - couponval;
  planpayload.payment_method = "online";
  planpayload.transaction_id = "N/A";
  planpayload.plan_features = planRecord;

  planpayload.plan_validity =  planRecord.plan_validity;
  planpayload.referrer_applied = obj.referrer_applied;
  planpayload.referrer_code = obj.referral_code;
  planpayload.plan_features = planRecord;
  planpayload.plan_features = planRecord;
  planpayload.plan_features = planRecord;
  planpayload.plan_features = planRecord;

  const customer_detail = {};
  customer_detail.customer_id= obj._id; 
  customer_detail.customer_name= obj.full_name;
  customer_detail.customer_email= obj.email_id;
  customer_detail.customer_phone= obj.mobile_no;
  const orderpayload = {};
  orderpayload.order_id         = orderid;
  orderpayload.order_amount     = planRecord.plan_price - wallate - couponval;
  orderpayload.order_currency   = 'INR';
  orderpayload.order_note       = 'Test';
  orderpayload.customer_details = customer_detail;

  payload.planpayload = planpayload;
  payload.cashfree = orderpayload ;     
//   console.log(payload)

         try {
             const res = await axios.post('https://sindonbschool.com/api/v1/payment', payload );

                if (res.status == 200) {
                    var sessionId = res.data; 
                    let checkoutOptions = {
                        paymentSessionId: sessionId,
                        returnUrl: 'https://careereduversity.com/payment?ord='+orderpayload.order_id,
                        // returnUrl: "https://test.cashfree.com/pgappsdemos/v3success.php?myorder="+postpayload.order_id,
                        
                    }
                    cashfree.checkout(checkoutOptions).then(function(result){
                        if(result.error){
                            alert(result.error.message)
                        }
                        if(result.redirect){
                            console.log("Redirection")
                        }
                    });

                    console.log( res.data );
                    }else{
                        console.log(res)
                    }
            } catch (e) { 
                console.log(e)
            }

  }
    return (
        <>
            <div className="container ptb_50">
                <div className="row chkout_row">
                    <div className="col-sm-8">
                        <div className="chkoutbox">
                            <h4>Checkout</h4>
                            <div className="chk_table">
                                <table>
                                    <tr>
                                        <th>Plan</th>
                                        <th>Price</th>
                                        <th>Subtotal</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="plntext">
                                                <h5>{ planRecord.plan_name }</h5>
                                                <h6>Key plan features</h6>
                                                <ul>
                                                    { getFeature.map(getFeature =>(
                                                        planRecord.features.filter(person => person.featurs == getFeature._id).map(filteredPerson => (
                                                            (filteredPerson.yes_no === 'Yes' ? <li> <TaskAltIcon /> {getFeature.title} </li> : "" )
                                                       
                                                       ))
                                                        
                                                    ))}
                                                
                                                </ul> 
                                            </div>
                                        </td>
                                        <td>
                                            <h5> ₹ { planRecord.plan_price }</h5>
                                        </td>
                                        <td>
                                            <h5> ₹ { planRecord.plan_price }</h5>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="ord_summary">
                            <h4>Order Summary</h4>
                            <div className="d-flex justify-content-between">
                                <p>Subtotal</p>
                                <p>₹ { planRecord.plan_price }</p>
                            </div>
                            <div className="d-flex justify-content-between">
                            <div>
                            <label>
                                <input type="checkbox" defaultChecked={checked} onChange={handleChangeOne} />
                                I want to use my wallet balance
                            </label>
                            </div>
                                <p>₹ { obj.wallet_balance }</p>
                            </div>
                            <Button className="cpncode" variant="primary" onClick={() => setModalShow(true)}>
                                Coupon Code
                            </Button>
                            <MyVerticallyCenteredModal
                                totalprice={ planRecord.plan_price }
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                            <div className="cpn_btn">
                                
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Col sm={8}>
                                            <Form.Control type="text" onChange={(e) => setCouponcode(e.target.value)} placeholder="AX123457890" />
                                        </Col>
                                        <Col sm={4} className="p0">
                                            <Button  onClick={(e) => checkCoupon('')}  type="submit">Apply</Button>
                                        </Col>
                                    </Form.Group>
                                
                            </div>
                            { showReg ?
                            <div className="d-flex justify-content-between">
                                <p>Coupon discount</p>
                                <p>(-)₹ {couponval}</p>
                            </div> : "" }
                            <div className="total_prc_row">
                                <div className="totl_prc d-flex justify-content-between">
                                    <p>Total <br /> <span>*Price inclusive of GST</span></p>
                                    <p>₹ { planRecord.plan_price - wallate - couponval }</p>
                                </div>
                                <div className="totl_btn">
                                    <Button onClick={() => openSandbox()} >Proceed To Checkout</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout_sec;
