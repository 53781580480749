import React from "react";
import axios from "axios";
import b1 from "../src/images/b1.png";
import b2 from "../src/images/b2.png";
import b3 from "../src/images/b3.png";
import b4 from "../src/images/b4.png";
import { studentbenfit } from './Api_list'
const Stu_benefit = () => {
 const [ post, setPost] = React.useState( null );
 React.useEffect(()=>{
    var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
      }} 
    axios.get( studentbenfit + 'benefits', headerData ).then(( response )=>{
        setPost( response.data );
    })
 }, []);
 if (!post) return null;
 let getData = post.data;
    return (
        <>
            <div className="container std_beneft">
                <div className="row centr_hdng_txt">
                    <h2 className="sitehdng"> Student <span>Benefits</span> </h2>
                </div>
                <div className="row stdn_bnft_row myy-d">
                    <div className="col-sm-6">
                    {getData.map(getData => (
                        <div className="stb_left d-flex">
                            <div className="std_img">
                                <img src={ window.IMGURL + getData.icon } alt={ getData.alt_tag}  />
                            </div>
                            <div className="std_txt">
                                <p>{ getData.heading}</p>
                                <ul>
                                    <li>{ getData.title_1}</li>
                                    <li>{ getData.title_2}</li>
                                </ul>
                            </div>
                        </div>
                    ))}
                    </div>
                    <div className="col-sm-6 bnft_img ">
                        <div className="stdn_bnf_icns">
                            <img src={b1} />
                            <p>24*7 Support</p>
                        </div>
                        <div className="stdn_bnf_icns">
                            <img src={b2} />
                            <p>1:1 Mentorship</p>
                        </div>
                        <div className="stdn_bnf_icns">
                            <img src={b3} />
                            <p>5X Career Transition</p>
                        </div>
                        <div className="stdn_bnf_icns">
                            <img src={b4} />
                            <p>400+ Hiring Partners</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Stu_benefit;