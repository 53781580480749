import React from "react";
import axios from "axios";
import { cmslist } from "./Api_list";
const Service_sec = () => {
    const [post, setPost] = React.useState(null);
    React.useEffect(() => {
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
      axios.get(cmslist+'home-about', headerData).then((response) => {
        setPost(response.data);
      });
    }, []);
    if (!post) return null;
    var getData = post.data;
    return (
        <>
            <section className="home_servc">
                <div className="container">
                    <div className="row centr_hdng_txt">
                        <h2 className="sitehdng"> Get back to growth with our <span>India </span> Top Ranked <span>Career Coach </span> 
                        and <span>HR Teams</span>  <br />Know all about our’s Services</h2>
                         <p>Career Eduversity actively encourages students to build a strong professional network by participating in industry events, conferences, and networking opportunities. This enables students to connect with professionals in their fields of interest, explore job opportunities, and stay updated on industry trends and advancements.</p>
                    </div>
                    <div className="row serv_wrpr">
                    {getData.map(getData => (
            <div className="col-sm-6">
                <div className="servlist d-flex">
                    <div className="srvimg">
                        <img src={  window.IMGURL + getData.image } alt={getData.titleorheading} />
                    </div>
                    <div className="srvcont">
                         <h4>{getData.titleorheading}</h4>
                         <p>{getData.shortcontent} </p>
                         <a href={getData.slug} className="cta_btn">  View Details</a>
                    </div>
                </div>
            </div> 
             ))}
                    </div>
                </div>
            </section>
        </>
    )
}
export default Service_sec;