import React from "react";
import Footer from "./partial/Footer";
import Failure_sec from "./Failure_sec";

const Failure = () => {
    return (
        <>
           <Failure_sec />



            <Footer />
        </>
    )
}

export default Failure;
