import React from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import { common_faq } from '../Api_list';

const Faq = () => {
    var slug = (document.URL).split("/")[3];
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH, 
          }} 
        axios.get(common_faq+slug, headerData).then(( response )=>{
            setPost( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data;
    // let  count = 0; 
    return (
        <>
            <div className="container padd_50">
                <div className="row text-center">
                    <h3 className='site_hdng'>Frequently Asked Questions</h3>
                </div>
                <div className='row faq_accrdion'>
                    <Accordion defaultActiveKey="0" className=''>
                        <div className='faq_left'>
                            {   getData.map( (getData, num) =>(
                                (num === 0) ?
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{ getData.question} </Accordion.Header>
                                <Accordion.Body>
                                <div dangerouslySetInnerHTML={{ __html: getData.answer }} />
                                </Accordion.Body>
                            </Accordion.Item>
                                : 
                                <Accordion.Item eventKey={num}>
                                <Accordion.Header>{ getData.question} </Accordion.Header>
                                <Accordion.Body>
                                <div dangerouslySetInnerHTML={{ __html: getData.answer }} />
                                </Accordion.Body>
                            </Accordion.Item>
                           ))}
                        </div>
                    </Accordion>
                </div>
            </div>

        </>
    )
}

export default Faq;