import React from "react";
import axios from "axios";
import PhoneIcon from '@mui/icons-material/Phone';
import Footer from "./partial/Footer";
import Query from "./Cmpnt/Query_sec";
import Next_step from "./Next_step";
import Industry_training from "./Industry_training";
import Core_team from "./Cmpnt/Core_team"; 
import Admission from "./Admission_process";

import Stu_benefit from "./Student_benefits";
import Seo from './Cmpnt/Seo';
import Coachslider from "./Cmpnt/Coach-slider";
 import { cmsdetail, websetting } from './Api_list';
const Why_sbs = () => {
    
    const [post, setPost] = React.useState(null);
    const [settingData, setsettingData] = React.useState(null);
    React.useEffect(() => {
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
      axios.get(cmsdetail+'why-sbs', headerData).then((response) => {
        setPost(response.data);
      });
      axios.get(websetting, headerData).then(( response )=>{
        setsettingData( response.data );
    })
    }, []);
    if (!post) return null;
    var getData = post.data;

    if (!settingData) return null;
    var SettingDatas = settingData.data;
    return (
        <> 
    <Seo seopage='why-sbs' />
            <section className="bg_voilet whysbs_top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div class="txt_img">
                                <h2 className="sitehdng">{getData.titleorheading}</h2>
                                <div className="s" dangerouslySetInnerHTML={{ __html: getData.content }} />;
                                <a href={ 'tel:'+SettingDatas.mobile_no } className="sitebtn"> <PhoneIcon />  Talk To Our Councelor</a>
                            </div>
                        </div> 
                        <div className="col-sm-6">
                            <div className="whysbs_img">
                                <img src={  window.IMGURL + getData.image } alt={getData.titleorheading}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
            <Next_step />
            <Industry_training />
            <Stu_benefit />
            <Core_team />
            <Coachslider />
            <Admission />
            <Query />
            <Footer />

        </>
    )
} 


export default Why_sbs;
