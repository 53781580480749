import React, { useState } from 'react';
import axios from "axios";
import moment from 'moment-timezone';
import { bloglist } from '../Api_list';
const Latest_blogs = () => {
    const [ post, setPost ] = useState(null); 
    var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
      }}
    const [actclass, setactClass] = useState('');
    const [component, setComponent] = useState(null);
    React.useEffect(()=>{
        axios.get(bloglist, headerData).then(( results )=>{
            setPost( results.data )
        })
    },[])
    if(!post) return null;
    let getData = post.data;
    getData = getData.slice(0, 10);

    
    function LoadComponent(id) {
        setactClass(id);
        document.getElementById('blogs').innerHTML = "";
        getaxiosdata( id );
      }

    function getaxiosdata( c ){
        axios.get( bloglist+c , headerData).then((responses)=>{
                  let  getData2  = responses.data.data;

                  getData2 = getData2.slice(0, 10);
                   let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                        <div className="notf_list">
                            <div className="notf_text">
                                <div className="notf-hdng-row">
                                    <div className="notf_hdng">
                                        <h4>{getData2.titleorheading}</h4> 
                                        <span>{(getData2.add_by != '') ?  getData2.add_by.split('#').slice(-1)[0] : 'Admin'}</span>
                                    </div>
                                    <p className="n_date">{moment(getData2.appr_on).format("DD MMM’YYYY")}</p>
                                </div>
                                <p className="ntf_txt"> {getData2.shortcontent} </p>
                            </div>
                        </div>
                    ))) : "";
                    setComponent(storecorse);
               })
    }
    return (
        <>
            <div className="opprtnty_sectn">
                <h3 className="dash_hdng">Latest Blog  & Article</h3>
                <div className="oppurtnty_box">
                    <div className="notf_type job_type">
                        <ul>
                            <li onClick={() => LoadComponent('')} className={actclass === '' ? "active" : ""  }>All</li>
                            <li onClick={() => LoadComponent('Administrator')} className={actclass === 'Administrator' ? "active" : ""  } >Admin </li>
                            <li onClick={() => LoadComponent('hr')} className={actclass === 'hr' ? "active" : ""  }>HR</li>
                            <li onClick={() => LoadComponent('career_coach')} className={actclass === 'career_coach' ? "active" : ""  }>Career Coach</li>
                        </ul>
                    </div>
                    <div className="notf_display">
                        <div id='blogs'>
                            { getData.map( getData => (
                                <div className="notf_list">
                                    <div className="notf_text">
                                        <div className="notf-hdng-row">
                                            <div className="notf_hdng">
                                                <h4>{getData.titleorheading}</h4> 
                                                <span>{(getData.add_by != '') ?  getData.add_by.split('#').slice(-1)[0] : 'Admin'}</span>
                                            </div>
                                            <p className="n_date">{moment(getData.appr_on).format("DD MMM’YYYY")}</p>
                                        </div>
                                        <p className="ntf_txt"> {getData.shortcontent} </p>
                                    </div>
                                </div>
                            ) ) }
                        </div> 
                        {component}
                    </div>

                    <div className="vw_all">
                        <a className="vwall_btn" href="latest-blogs-articles"> View All</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Latest_blogs;


