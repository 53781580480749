import React from 'react';

const Job_ready_candidate = () => {
    return (
        <>
            <section className='fulljobsec'>
                <div className="container padd_50">
                    <div className="row hdngtxt text-center">
                        <h3 className='site_hdng'>Get Job Ready Candidates with following skills</h3>
                    </div>
                    <div className="row jobrdy_cndt">
                        <div className="col-sm-3 col-6">
                            <div className='jrc'>
                                <p>Management</p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className='jrc'>
                                <p>Engineering</p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className='jrc'>
                                <p>Computer Science</p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className='jrc'>
                                <p>Web Development</p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className='jrc'>
                                <p>Digital Marketing</p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className='jrc'>
                                <p>Business Intelligence</p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className='jrc'>
                                <p>Testing</p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-6">
                            <div className='jrc'>
                                <p>Big Data</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Job_ready_candidate;