import React from 'react';
import axios from "axios";
// const baseURL = "https://careereduversity.com/api/admissionstep";   
import { admissionstep } from './Api_list';

const Admission = () => {
    const [post, setPost] = React.useState(null);
    React.useEffect(() => {
    var headerData = {"headers":{
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authorization": window.HEADERSAUTH,
        }} 
      axios.get(admissionstep, headerData).then((response) => {
        setPost(response.data);
      });
    }, []);
    if (!post) return null;
var getData = post.data;
    return (
        <>
          {getData.length > 0 && (
            <div className='container adms_process padd_50'>
                <div className='row text-center'>
                     <h3 className='site_hdng'> Admission Steps Or Process To Enroll </h3>
                </div>
                <div className='row justify-content-around'>
                    {getData.map(getData => (
                        <div className='col-sm-3'>
                        <div className='adm_box'>
                            <img src={ window.IMGURL + getData.image} alt={getData.titleorheading}/>
                            <p>{getData.titleorheading}</p>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
 )}
        </>
    )
};



export default Admission;