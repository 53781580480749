import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import menuprofile from "../images/menuimg.png";
import NavDropdown from 'react-bootstrap/NavDropdown';
let slug = document.URL.split("/")[3];
const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin:0; 

  li {
    padding: 18px 10px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(1000%)'};
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    padding: 2.5rem 1.5rem;
    transition: transform 0.3s ease-in-out;

   
  }
`;
let obj = JSON.parse( localStorage.getItem('loginddata') );
const RightNav = ({ open }) => {
  function logOut() {
    localStorage.removeItem("loginddata");
    if(slug === 'Checkout'){
      window.location.href="/plans";
    }else{
      window.location.reload(true)
    }
  }
  
  // console.log(localStorage.getItem('loginddata.full_name'))
  return (
    <Ul open={open}>
      <div className='mobprofl_menu'>
        <img src={menuprofile} alt='alttag'/>
        <h6>Guest User</h6>
        <p>Sign up and get job or internship assurance benefits right from start</p>
      </div>
      <li className="nav-item">
        <NavLink 
          className="nav-link"
          aria-current="page"
          to="/why-career-eduversity"
        > 
          Why CE
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/college-listing">
          College By Course
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/job-internship">
          Job And Internship
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/hire-career-eduversity">
          Hire From CE
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/plans">
          Plan
        </NavLink>
      </li>
      {/* <li className="nav-item">
        <NavLink className="nav-link" to="/contact-us">
          More
        </NavLink> 
      </li> */}
      <li className='d-flex nav-item'>
        <NavDropdown title="More" id="basic-nav-dropdown">
          <NavDropdown.Item href="/blog-list">
            Blog
          </NavDropdown.Item>
          <NavDropdown.Item href="/contact-us">
            Contact Us
          </NavDropdown.Item>
        </NavDropdown>
      </li>
      { (obj && obj._id) ? "" :  
      <li className="nav-item mob_hide">
        <NavLink className="nav-link" to="/login">
          <LockIcon /> Login/Register
        </NavLink>
      </li>
      } 
      { (obj && obj._id) ?   
      <li className='d-flex'>
      <PersonIcon /> 
        <NavDropdown title={obj.full_name} id="basic-nav-dropdown">
        <NavDropdown.Item href="/student/dashboard">
            Dashboard
          </NavDropdown.Item>
          <NavDropdown.Item href="/student/profilepage">
            Profile
          </NavDropdown.Item>
          <NavDropdown.Item href="#!" onClick={() => logOut()}>Logout</NavDropdown.Item>
        </NavDropdown>
      </li>
    : ""}
      <div className="moblmenu_btn">
        <a href="/" className="navbtn">Signup / Login</a>
      </div>
    </Ul>
    


  )
}

export default RightNav