import React, { useState, useEffect } from 'react';
import axios from "axios";

import query_img from './images/query_img.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { enquirysubmit, coursedetails, courselistdropdown, statebycountryname, citiesbystatename } from './Api_list';

const Query_form = () => {
  const headerData = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: window.HEADERSAUTH,
    }
  };

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailid, setEmailid] = useState("");
  const [message, setMessage] = useState("");
  const [post, setPost] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [statelist, setStatelist] = useState([]);
  const [courselist, setCourselist] = useState([]);
  const [cityDropdown, setCityDropdown] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const slug1 = document.URL.split("/")[3];
    const slug2 = document.URL.split("/")[4];

    axios.get(`${coursedetails}${slug1}&course_slug=${slug2}`, headerData).then((response) => {
      setPost(response.data);
    });

    const fetchInitialData = async () => {
      try {
        const stateResponse = await axios.get(statebycountryname, headerData);
        setStatelist(Array.isArray(stateResponse.data) ? stateResponse.data : []);

        const courseResponse = await axios.get(courselistdropdown, headerData);
        setCourselist(Array.isArray(courseResponse.data) ? courseResponse.data : []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const encodedState = encodeURIComponent(selectedState);

      axios.get(`${citiesbystatename}${encodedState}`, headerData)
        .then(response => {
          setCityDropdown(Array.isArray(response.data) ? response.data : []);
        })
        .catch(error => {
          console.error('Error fetching city data:', error);
        });
    } else {
      setCityDropdown([]);
    }
  }, [selectedState]);

  if (!post) return null;

  const getData = post.data;

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      full_name: name,
      email_id: emailid,
      looking_for: "",
      type: 'university',
      university_name: getData.univ_name,
      // course_name: getData.c_course_name,
      mobile_no: mobileNumber,
      course_name: selectedCourse,
      state_name: selectedState,
      city_name: selectedCity
    };

    if (name.length !== 0 && mobileNumber.length === 10) {
      try {
        const res = await axios.post(enquirysubmit, postData, headerData);
        if (res.status === 200) {
          setMessage('Thank you, your call back request has been submitted successfully!');
          setTimeout(() => {
            setMessage('');
          }, 5000);
          setName("");
          setMobileNumber("");
          setEmailid("");
          setSelectedCourse('');
          setSelectedState('');
          setSelectedCity('');
        } else {
          setMessage("Some error occurred");
          setTimeout(() => {
            setMessage('');
          }, 5000);
        }
      } catch (e) {
        setMessage("Some error occurred");
        setTimeout(() => {
          setMessage('');
        }, 5000);
      }
    } else {
      setMessage("Please enter a valid mobile number");
      setTimeout(() => {
        setMessage('');
      }, 5000);
    }
  };

  return (
    <>
      <div className='qryform'>
        <div className='qry_img'>
          <img src={query_img} alt="Query" />
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="message">{message && <p>{message}</p>}</div>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Control type="text" onChange={(e) => setName(e.target.value)} value={name} placeholder="Full Name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="email" onChange={(e) => setEmailid(e.target.value)} value={emailid} placeholder="Enter email" />
          </Form.Group>
          <InputGroup className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-default">
              +91
            </InputGroup.Text>
            <Form.Control
              onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} maxLength={10}
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
            />
          </InputGroup>
          <Form.Group className="mb-3" controlId="formBasicCourse">
            <Form.Control as="select" onChange={handleCourseChange} value={selectedCourse}>
              <option>--Choose Course--</option>
              {courselist.map((course) => (
                <option 
                key={course.id} 
                value={course.course_name} 
                selected={getData.c_course_name === course.course_name}
              >
                  {course.course_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicState">
            <Form.Control as="select" onChange={handleStateChange} value={selectedState}>
              <option>--Choose State--</option>
              {statelist.map((state) => (
                <option key={state.id} value={state.state_name}>
                  {state.state_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCity">
            <Form.Control as="select" onChange={handleCityChange} value={selectedCity}>
              <option>--Choose City--</option>
              {cityDropdown.map((city) => (
                <option key={city.id} value={city.city_name}>
                  {city.city_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
          <Form.Text className="text-muted">
            By providing your contact details, you agree to our <a href='/terms-and-conditions'> Terms of Use</a> & <a href='/privacy-policy'>Privacy Policy</a>
          </Form.Text>
        </Form>
      </div>
    </>
  );
}

export default Query_form;
