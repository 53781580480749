import React from 'react';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'; 

import Plantop_bg from "./Plans_topbg";
import { planlist, addtocart } from "../Api_list"

const Plan_sec = () => {
    localStorage.setItem('page_url', 'plans');
    const [ post, setPost ] = React.useState( null );
    const [message, setMessage]     = React.useState("");
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(planlist, headerData).then(( response )=>{
            setPost( response.data )
        })
    }, []);
    if( !post ) return null;
    let getPlan = post.data[0].plan_details;
    let getFeature =post.data[0].feature_master;
    let obj = JSON.parse( localStorage.getItem('loginddata') );
    
    let openSandbox = async (e) => {
        let post = {"user_id": obj._id,"plan_id": e}
        try {
            const res = await axios.post(addtocart, post)
            if (res.status === 200) {
                window.location.href = '/Checkout';
                } else {
                  setMessage(res.data.message);
                  setTimeout(() => {
                    setMessage('');
                  }, 5000);
                }
          } catch (e) {
            setMessage("Some error occured");
            setTimeout(() => {
              setMessage('');
            }, 5000);
          }

      }
    return (
        <>
            <Plantop_bg />

            <section className='plan_sec'>
                <div className="container padd_50">
                    <div className="row hdngtxt text-center">
                        <h3 className='site_hdng'>Our Plans</h3>
                        <div className="message">{message ? <p>{message}</p> : null}</div> 
                    </div>
                    <div className='row only_desktop'>
                        <div className='plan_table'>
                            <table>
                                <tr>
                                    <th className='width_500'>
                                        <div className='ftr_hdng'>
                                            <h4>Our Features</h4>
                                            <p>Choose our services as per their significance and features.</p>
                                        </div>
                                    </th>
                                    { getPlan.map(getPlan =>(
                                    <th className='width_250'>
                                        <div className='ftr_type bg_lblue'>
                                            <p className='off'>{(((getPlan.plan_mrp-getPlan.plan_price)/getPlan.plan_mrp)*100).toFixed(2)}% OFF</p>
                                            <h3>{getPlan.plan_name}</h3>
                                            <h4>Rs {getPlan.plan_price}</h4>
                                            <h6 className='cut'>Rs {getPlan.plan_mrp}</h6>
                                            { (obj === null ) ? 
                                            <a className='btn_ftr' href='/login'> Buy Now </a>
                                             :  
                                            <a className='btn_ftr' onClick={() => openSandbox(getPlan._id)}  href='#!'> Buy Now </a>
                                            }
                                        </div> 
                                    </th>
                                  )) }
                                </tr>
                                { getFeature.map(getFeature =>(
                                <tr>
                                    <td>
                                        <div className='featrs'>
                                            <h4>{getFeature.title}</h4>
                                            <p>{getFeature.sort_desc}</p>
                                        </div>
                                    </td>
                                    { getPlan.map(getPlan =>(
                                        getPlan.features.filter(person => person.featurs == getFeature._id).map(filteredPerson => (
                                    <td align="center" className={(filteredPerson.yes_no === 'Yes' ? '' : 'red' )} > {(filteredPerson.yes_no === 'Yes' ? <CheckCircleIcon /> : <CancelIcon /> )} <span>{filteredPerson.title}</span></td>
                                    ))
                                    )) }
                                </tr>
                                ))}
                            </table>
                        </div>
                    </div>

                    <div className='row only_mobile'>
                        <div className='ftr_hdng'>
                            <h4>Our Features</h4>
                            <p>Choose our services as per their significance and features.</p>
                        </div>
                        { getPlan.map(getPlan =>(
                        <div className='planbox bg_lblue'>
                            <div className='ftr_type'>
                                <p className='off'>{(((getPlan.plan_mrp-getPlan.plan_price)/getPlan.plan_mrp)*100).toFixed(2)}% OFF</p>
                                <div className='ftr_nameprice'>
                                    <h3>{getPlan.plan_name}</h3>
                                    <h4>Rs {getPlan.plan_price}</h4>
                                    <h6 className='cut'>Rs {getPlan.plan_mrp}</h6>
                                </div>
                            </div>
                            <div className='ftr_pln_dtl'>

                            { getFeature.map(getFeature =>(
                                <div className='ftr_lists'>
                                    { getPlan.features.filter(person => person.featurs == getFeature._id).map(filteredPerson => (
                                    (filteredPerson.yes_no === 'Yes' ? <CheckCircleIcon /> : <CancelIcon className='red' /> )
                                    ))
                                    }
                                    
                                    <div className='ftr_dtext'>
                                        <h4>{getFeature.title}</h4>
                                        <p>{getFeature.sort_desc}</p>
                                    </div>
                                </div>
                            ))}
                            { (obj === null ) ? 
                            <a className='btn_ftr' href='/login'> Buy Now </a>
                                :  
                            <a className='btn_ftr' onClick={() => openSandbox(getPlan._id)}  href='#!'> Buy Now </a>
                            }
                            </div>
                        </div>
                        )) }

                        {/* <div className='planbox bg_pink'>
                            <div className='ftr_type'>
                                <p className='off'>40% OFF</p>
                                <div className='ftr_nameprice'>
                                    <h3>Standard</h3>
                                    <h4>Rs 7499</h4>
                                    <h6 className='cut'>Rs 14999</h6>
                                </div>
                            </div>
                            <div className='ftr_pln_dtl'>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Career Eduversity Support</h4>
                                        <p>You can contact us anytime if you need any assistance related to your education or career.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Course Counceling</h4>
                                        <p>Get Guidance from our education experts to choose a suitable course as per recommended career options.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Live Sessions With Career Experts</h4>
                                        <p>Our career counsellors will provide you right career recommendations based on your career assessment report.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>College Counseling & Admission Assistance</h4>
                                        <p>Find a best-fit College based on your course choice, budget, and location. Also, get complete admission assistance.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Live Sessions With a Dedicated Career Coach</h4>
                                        <p>1:1 Dedicated Sessions. Our career Coach will provide you with good career recommendations based on your interest.
                                            Get effective career tips and personalized guidance sessions.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Dedicated HR Coach During the Course</h4>
                                        <p>Connect with our dedicated HR Team. 1:1 Dedicated Live  Sessions with HR Coach. Our HR Coach provides Industry Analytical and discussions every month and will help you to make a career in that direction.
                                            They help you prepare for the interview session according to the industry..</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CancelIcon className='red' />
                                    <div className='ftr_dtext'>
                                        <h4>Free Internship</h4>
                                        <p>Complete your Internship at reputed organizations & on the latest technology and level up your CV with us.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>100% Off-Campus placement</h4>
                                        <p>Rest assured to get placed in a reputed organization through us in case your college doesn't provide you with a job placement.
                                          <span>(Will schedule at least 5 interview)</span>
                                        </p>

                                    </div>
                                </div>
                                <a className='btn_ftr' href=''> Buy Now </a>
                            </div>
                        </div> */}
                        {/* <div className='planbox bg_yellow'>
                            <div className='ftr_type'>
                                <p className='off'>50% OFF</p>
                                <div className='ftr_nameprice'>
                                    <h3>Standard</h3>
                                    <h4>Rs 12499</h4>
                                    <h6 className='cut'>Rs 24999</h6>
                                </div>
                            </div>
                            <div className='ftr_pln_dtl'>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Career Eduversity Support</h4>
                                        <p>You can contact us anytime if you need any assistance related to your education or career.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Course Counceling</h4>
                                        <p>Get Guidance from our education experts to choose a suitable course as per recommended career options.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Live Sessions With Career Experts</h4>
                                        <p>Our career counsellors will provide you right career recommendations based on your career assessment report.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>College Counseling & Admission Assistance</h4>
                                        <p>Find a best-fit College based on your course choice, budget, and location. Also, get complete admission assistance.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Live Sessions With a Dedicated Career Coach</h4>
                                        <p>1:1 Dedicated Sessions. Our career Coach will provide you with good career recommendations based on your interest.
                                            Get effective career tips and personalized guidance sessions.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>Dedicated HR Coach During the Course</h4>
                                        <p>Connect with our dedicated HR Team. 1:1 Dedicated Live  Sessions with HR Coach. Our HR Coach provides Industry Analytical and discussions every month and will help you to make a career in that direction.
                                            They help you prepare for the interview session according to the industry..</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon/>
                                    <div className='ftr_dtext'>
                                        <h4>Free Internship</h4>
                                        <p>Complete your Internship at reputed organizations & on the latest technology and level up your CV with us.</p>
                                    </div>
                                </div>
                                <div className='ftr_lists'>
                                    <CheckCircleIcon />
                                    <div className='ftr_dtext'>
                                        <h4>100% Off-Campus placement</h4>
                                        <p>Rest assured to get placed in a reputed organization through us in case your college doesn't provide you with a job placement.
                                          <span>(Will schedule at least 10 interview)</span>
                                        </p>

                                    </div>
                                </div>
                                <a className='btn_ftr' href=''> Buy Now </a>
                            </div>
                        </div> */}
                    </div>


                </div>
            </section>

        </>
    )
}

export default Plan_sec;