import React from "react";
// import axios from "axios";
import Footer from "./partial/Footer";
import Checkout_sec from "./Checkout_sec";
// import { cartdata } from "./Api_list";
const Checkout = () => {
    return (
        <>
           <Checkout_sec />
            <Footer />
        </>
    )
}

export default Checkout;
