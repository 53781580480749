import React, { useState, useEffect } from "react";
import axios from "axios"; 
import moment from "moment-timezone";
import "./Chat.css"
import CallIcon from '@mui/icons-material/Call';
import VideocamIcon from '@mui/icons-material/Videocam';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import SendIcon from '@mui/icons-material/Send';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Dropdown } from 'react-bootstrap';
import avatar from "./images/avatar.png";
import { userDetails, chatlist, chatSavedata } from "../Api_list";
let obj = JSON.parse( localStorage.getItem('loginddata') );
let usertype = document.URL.split("/")[5];
// usertype = usertype.replace('#!', '');


export default function Chat() {
    const [showTopBtn, setShowTopBtn] = useState(false);

    let obj = JSON.parse( localStorage.getItem('loginddata') );
    const [ component, setComponent ]             = useState("");
    const [ hrdata, setHrdata ]                 = useState( null );
    const [ counselordata, setCounselordata ]   = useState( null );
    const [ chatData, setChatData ]             = useState( null );
    const [sendMessage, setSendMessage]         = useState("");
    const [message, setMessage]                 = useState("");
    useEffect(()=>{
        axios.get( userDetails+obj.hr_id ).then(( response )=>{
            setHrdata( response.data );
        })
        axios.get( userDetails+obj.counselor_id ).then(( response )=>{
            setCounselordata( response.data );
        })
        if(usertype === 'hr-coach') {
            axios.get( chatlist+obj._id+'&receiver='+obj.hr_id ).then(( response )=>{
                setChatData( response.data );
            })
        }
        if(usertype === 'career-coach') {
            axios.get( chatlist+obj._id+'&receiver='+obj.counselor_id ).then(( response )=>{
                setChatData( response.data );
            })
        }
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []) 
    if( !hrdata ) return null;
    let hData = hrdata.data;
    if( !counselordata ) return null;
    let cData = counselordata.data;

    if( !chatData ) return null;
    let chatDatas = chatData.data;
   // function submitForm (){
        let submitForm = async (e) => {
         let reciverid = "";
        if(usertype === 'hr-coach') {
            reciverid = obj.hr_id;
        } 
        if(usertype === 'career-coach') {
            reciverid = obj.counselor_id;
        }
        let postData = {
            sender   : obj._id,
            receiver : reciverid,
            message  : sendMessage,
        }
        if((sendMessage.length != 0)){
        try {
            const responses = axios.post(chatSavedata, postData)
            document.getElementById('showmessage').innerHTML = "";
            getChat( obj._id, reciverid );
            getChat( obj._id, reciverid );
                setSendMessage("");
                setMessage('Message Send!');
                setTimeout(() => {
                  setMessage('');
                }, 5000);
          } catch (e) {
            setMessage("Something Went Wrong!");
          }
        }
    }

    function getChat( s, r ){
        axios.get( chatlist+s+'&receiver='+r).then((responses)=>{
            let  getData2  = responses.data.data;
            let storecorse =  getData2 ? (getData2.map( (getData2) =>(
                (getData2.sender === obj._id) ?
                <li className="chat_b_item">
                    <div className="chat_b_by">
                        <div className="chat_b_by_img">
                        <img src={window.IMGURL+obj.profile_pic} alt={obj.full_name} />
                        </div>
                        <h5>You</h5>
                    </div>
                    <div className="chat_b_content">
                        <div className="chat_b_c_item">{getData2.message}</div>
                    </div>
                    <div className="chat_b_time">
                        <AccessTimeIcon /> {moment(getData2.added_on).format("h:mm a")}
                    </div> 
                </li>
             : <li className="chat_b_item by_user">
             <div className="chat_b_by">
                 <div className="chat_b_by_img">
                 { (usertype === 'hr-coach') ?  
                 <img src={(hData.profile_pic) ? window.IMGURL + hData.profile_pic : ""} alt={hData.full_name} />
                : <img src={(cData.profile_pic) ? window.IMGURL + cData.profile_pic : ""} alt={cData.full_name} />
                }
                 </div>
                 { (usertype === 'hr-coach') ?  
                 <h5>{hData.full_name}</h5>
                :  <h5>{cData.full_name}</h5>
                }
                
             </div>
             <div className="chat_b_content">
                 <div className="chat_b_c_item">{getData2.message}</div>
             </div>
             <div className="chat_b_time">
                 <AccessTimeIcon /> {moment(getData2.added_on).format("h:mm A")}
             </div> 
            </li>
             ))) : "";
            
             setComponent(storecorse);
        })
    }
  return (
    <>
        <div className="chat-section pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="chart_content_outer d-flex flex-wrap">
                            <div className="chatlist">
                                <div className="chatlist_header">
                                    <ul className="chatlist_h_nav d-flex flex-wrap">
                                        {/* <li className="chatlist_h_nav_item">
                                            <a href="#!" className="chatlist_h_nav_link active">New</a>
                                        </li> */}
                                        <li className="chatlist_h_nav_item">
                                            <a href="#!" className="chatlist_h_nav_link">Chat</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="chatlist_body custom_scroll">

                                {  (usertype === 'hr-coach') ?  
                                    <ul className="chatlist_b_list">
                                        <li className="chatlist_b_item active">
                                            <a href="hr-coach" className="chatlist_b_box">
                                                <div className="chatlist_b_img">
                                                    <img src={(hData.profile_pic) ? window.IMGURL + hData.profile_pic : ""} alt={hData.full_name} />
                                                </div>
                                                <h4>{hData.full_name}</h4>
                                                <h6>HR Coach</h6>
                                            </a>
                                        </li>
                                        <li className="chatlist_b_item">
                                            <a href="career-coach" className="chatlist_b_box">
                                                <div className="chatlist_b_img">
                                                    <img src={(cData.profile_pic) ? window.IMGURL + cData.profile_pic : ""} alt={cData.full_name} />
                                                </div>
                                                <h4>{cData.full_name}</h4>
                                                <h6>Career Coach</h6>
                                            </a>
                                        </li>
                                    </ul>
                                    : 
                                    <ul className="chatlist_b_list">
                                        <li className="chatlist_b_item ">
                                            <a href="hr-coach" className="chatlist_b_box">
                                                <div className="chatlist_b_img">
                                                    <img src={(hData.profile_pic) ? window.IMGURL + hData.profile_pic : ""} alt={hData.full_name} />
                                                </div>
                                                <h4>{hData.full_name}</h4>
                                                <h6>HR Coach</h6>
                                            </a>
                                        </li>
                                        <li className="chatlist_b_item active">
                                            <a href="career-coach" className="chatlist_b_box">
                                                <div className="chatlist_b_img">
                                                    <img src={(cData.profile_pic) ? window.IMGURL + cData.profile_pic : ""} alt={cData.full_name} />
                                                </div>
                                                <h4>{cData.full_name}</h4>
                                                <h6>Career Coach</h6>
                                            </a>
                                        </li>
                                    </ul>
                                    }

                                </div>
                            </div>
                            <div className="chat_outer_body">
                                <div className="chat_head">
                                    { (usertype === 'hr-coach') ?  
                                    <div className="chat_h_profile">
                                        <div className="chat_h_p_img">
                                        <img src={(hData.profile_pic) ? window.IMGURL + hData.profile_pic : ""} alt={hData.full_name} />
                                        </div>
                                        <h4>{hData.full_name}</h4>
                                        <h5>HR Coach</h5>
                                        {/* <p>last Seen 10:30pm ago</p> */}
                                    </div>
                                    : <div className="chat_h_profile">
                                    <div className="chat_h_p_img">
                                    <img src={(cData.profile_pic) ? window.IMGURL + cData.profile_pic : ""} alt={cData.full_name} />
                                    </div>
                                    <h4>{cData.full_name}</h4>
                                    <h5>HR Coach</h5>
                                    {/* <p>last Seen 10:30pm ago</p> */}
                                    </div> 
                                    }
                                    {message ? <p >{message}</p> : null}
                                    <div className="chat_h_action">
                                        <a href="#!" className="chat_h_a_btn"> <CallIcon /> </a>
                                        <a href="#!" className="chat_h_a_btn"> <VideocamIcon /> </a>
                                        {/* <Dropdown className="chat_h_a_drop">
                                            <Dropdown.Toggle variant="chat_h_a_btn" id="chat_h_a_more">
                                                <MoreHorizIcon />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="end">
                                                <Dropdown.Item href="#!">Action</Dropdown.Item>
                                                <Dropdown.Item href="#!">Action</Dropdown.Item>
                                                <Dropdown.Item href="#!">Action</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                    </div>
                                </div>
                                <div className="chat_body custom_scroll">
                                    <ul className="chat_b_list" id="showmessage">
                                    {  chatDatas.map( getData => ( 
                                        (getData.sender === obj._id) ?
                                        <li className="chat_b_item">
                                    		<div className="chat_b_by">
                                    			<div className="chat_b_by_img">
                                                <img src={window.IMGURL+obj.profile_pic} alt={obj.full_name} />
                                    			</div>
                                    			<h5>You</h5>
                                    		</div>
                                    		<div className="chat_b_content">
                                    			<div className="chat_b_c_item">{getData.message}</div>
                                    		</div>
                                    		<div className="chat_b_time">
                                    			<AccessTimeIcon /> {moment(getData.added_on).format("h:mm a")}
                                    		</div> 
                                    	</li>
                                     : <li className="chat_b_item by_user">
                                     <div className="chat_b_by">
                                         <div className="chat_b_by_img">
                                         { (usertype === 'hr-coach') ?  
                                         <img src={(hData.profile_pic) ? window.IMGURL + hData.profile_pic : ""} alt={hData.full_name} />
                                        : <img src={(cData.profile_pic) ? window.IMGURL + cData.profile_pic : ""} alt={cData.full_name} />
                                        }
                                         
                                         </div>
                                         { (usertype === 'hr-coach') ?  
                                         <h5>{hData.full_name}</h5>
                                        :  <h5>{cData.full_name}</h5>
                                        }
                                        
                                     </div>
                                     <div className="chat_b_content">
                                         <div className="chat_b_c_item">{getData.message}</div>
                                     </div>
                                     <div className="chat_b_time">
                                         <AccessTimeIcon /> {moment(getData.added_on).format("h:mm A")}
                                     </div> 
                                    </li>
                                            ))
                                        }
                                    	
                                    </ul>
                                    <ul className="chat_b_list">
                                    {component}
                                    </ul>
                                </div>
                                <div className="chat_footer">
                                    <div className="chat_footer_box">
                                    	<div className="chat_footer_input">
                                    		<input type="text" onChange={(e) => setSendMessage(e.target.value)} value={sendMessage}  placeholder="Say something..." />
                                    	</div>
                                    	<div className="chat_footer_action">
                                    		<a href="#!" className="chat_footer_attach">
                                    			<CachedOutlinedIcon />
                                    		</a> 
                                    		<button className="chat_footer_sent" onClick={()=>{submitForm()}} type="button">
                                    			<SendIcon />
                                    		</button>
                                    	</div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
