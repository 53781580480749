import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';




const Calenderr = () => {
  

  const [value, onChange] = useState(new Date());

  return (
    <div className='calndsec'>
      <h3 className="dash_hdng">Calendar</h3>
      <Calendar className="custm_calender" onChange={onChange} value={value}  />
    </div>
  );
}

export default Calenderr;
