import React from "react";

const Bloglisttop_bg = () => {
    return (
        <>
            <section className="bloglistbg list_topbg whysbs_top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div class="txt_img">  
                                <h2 className="sitehdng">A wide network of institutions redefining up-skilling  and educations.</h2>
                                <p>Empowering students with world-class certifications and degree programs. Kickstart your career 100+ job opportunies. .</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Bloglisttop_bg;