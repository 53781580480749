import React from 'react';
import axios from 'axios';
import YoutubeEmbed from "../Cmpnt/Youtube_iframe";
import { coursedetails } from '../Api_list';
import { Helmet } from 'react-helmet';

let slug1 = document.URL.split("/")[3];
let slug2 = document.URL.split("/")[4];
const Course_brief = () => {
  //  alert(coursedetails+slug1+'&course_slug='+slug2);
    const [ post, setPost ] = React.useState( null );
    React.useState(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(coursedetails+slug1+'&course_slug='+slug2, headerData).then((response)=>{
            setPost( response.data )
        }) 
    }, []);
    if( !post ) return null;
    let getData = post.data;
    if(typeof getData !== 'undefined'){
    let course_fee = ( typeof getData !== 'undefined' ) ? getData.c_course_fee_details : [];
    let specialization = ( typeof getData !== 'undefined' ) ? getData.specialization : [];
    specialization = ( typeof specialization !== 'undefined' ) ? specialization.split(",") : "";

    
    return ( 
        <>
        <Helmet>
    <title>{(getData != null) ? getData.c_meta_title : 'Careereduversity'}</title> 
    <meta name="title" content={(getData != null) ? getData.c_meta_title : 'Careereduversity'} />
    <meta name="description" content={(getData != null) ? getData.c_meta_description : 'Careereduversity'} />
    <meta name="keywords" content={(getData != null) ? getData.c_meta_keyword : 'Careereduversity'} />
    <meta property="og:title" content={(getData != null) ? getData.c_meta_title : 'Careereduversity'} />
    <meta property="og:image" content={(getData != null) ?  window.IMGURL + getData.univ_logo : 'Careereduversity'}/>
    <meta property="og:description" content={(getData != null) ? getData.c_meta_description : 'Careereduversity'} />
    <meta property="og:url" content={window.APPURL+slug1+'/'+slug2} />
    </Helmet> 
            <section className='coursebrf_sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-8'>
                            <div className='crs_brftxt'>
                                <h2>{ getData.c_titleorheading }</h2>
                                <p> { getData.c_shortcontent }</p>
                            </div>
                            <div className='crs_brfbox'>
                                <div className='univ_name d-flex'>
                                    <img src={ window.IMGURL+getData.univ_logo } />
                                    <h5>{ getData.univ_name }</h5>
                                </div>
                                <div className='crs_ftrs d-flex'>
                                    <div className='ftrs'>
                                        <p>Learning Format</p>
                                        <h5>{ getData.c_course_formate }</h5>
                                    </div>
                                    <div className='ftrs'>
                                        <p>Duration</p>
                                        <h5>{ getData.c_course_duration } Months</h5>
                                    </div>
                                    <div className='ftrs'>
                                        <p>Degree</p>
                                        <h5>{ getData.c_course_degree }</h5>
                                    </div>
                                    <div className='ftrs'>
                                        <p>Eligibility</p>
                                        <h5>{ getData.course_eligibility }</h5>
                                    </div>
                                </div>
                                { specialization ?  
                                <div className='specialztion'>
                                    <h5>Specialization</h5>
                                    <ul>
                                        { specialization ?  specialization.map(specialization=>(
                                            <li>{specialization}</li>
                                        )) : ""}
                                        
                                    </ul>
                                </div>
                                : "" }
                                <div className='crs_fee d-flex'>
                                    <div className='totlfee'>
                                        <p>Course Fee</p>
                                        <h5>₹ { getData.c_course_course_fee }/-</h5>
                                    </div>
                                    <div className='semvise_fee  d-flex'>
                                        { course_fee.map( course_fee =>(
                                            <div className='sem_fee'>
                                            <p>{ course_fee.semester }</p>
                                            <h6>₹ { course_fee.fees }/-</h6>
                                        </div>
                                        ))}
                                        
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-4'>
                            <div className='vdosec'>
                                <YoutubeEmbed embedId={getData.c_course_youtube} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
}

export default Course_brief;