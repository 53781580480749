import React from "react";
import axios from "axios";
import Slider from "react-slick";
import { test_recruiter } from '../Api_list';
import StarIcon from '@mui/icons-material/Star';
// import rec1 from "../src/images/recrtr.png";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';


const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

const Recruiterslider = () => {

    const [post, setPost] = React.useState(null);
  React.useEffect(() => {
    var headerData = {"headers":{
      "Accept": "application/json",
      "Content-Type": "application/json",
      "authorization": window.HEADERSAUTH,
    }} 
    axios.get(test_recruiter, headerData).then((response) => {
      setPost(response.data);
    });
  }, []);
  if (!post) return null;
  var getData = post.data;

    return (
        <>
        <section className="recruiter_sec">
        <div className="container">
           <div className="row text-center">
              <h3 className="rcrtr_hdng"><StarIcon/> Recruiters Speaks <StarIcon/> </h3>
           </div>
        <div className="rec_slider">
            <Slider {...settings}>
            {getData.map(getData => (
                <div className="rec_slide d-flex">
                    <div className="rec_img">
                       <img src={  window.IMGURL + getData.profile_pic } alt={ getData.full_name }/>
                    </div>
                    <div className="rec_txt">
                       <FormatQuoteIcon/>
                       <h6>{ getData.description } </h6>
                       <div className="abt_rec">
                          <p>{ getData.full_name }</p>
                          <span>{ getData.company_name }</span>
                       </div>
                    </div>
                </div>
                ))}
            </Slider>
        </div>
        </div>
      </section>
        </>
    )
}

export default Recruiterslider;