import React from "react";
// import Navbar_dash from "./Dash-nav";
import My_courses from "./My_course"; 
import Refer from "./Refer";
import My_coach from "./My-coach";
import Job_oppurtunity from "./Job_opportunity";
import Announcements from "./Announcements";
import No_courses from "./No-course"; 
import Latest_blogs from "./Latest-blogs";
 
import Calenderr from "./Calender";
let obj = JSON.parse( localStorage.getItem('loginddata') );
const Dash = () => {
  return (
    <> 
      {/* <Navbar_dash /> */}

      <div className="container">
        <div className="row ptb_50">
          <div className="col-sm-5">
            <Job_oppurtunity />
          </div>
          <div className="col-sm-3">
            <My_courses /> 
            <Refer />
          </div>
          <div className="col-sm-4">
            {(obj.plan_name) ? <My_coach /> : <No_courses/>}
          </div>
        </div>
        <div className="row">
           <div className="col-sm-5">
            <Announcements/>
           </div> 
           <div className="col-sm-5">
            <Latest_blogs/>  
           </div>
           <div className="col-sm-2">
            <Calenderr/>
           </div>
        </div>
      </div>
    </>
  )
}

export default Dash;