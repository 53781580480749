import React, { Suspense } from "react";
import axios from "axios";
import Service_sec from "./Service_sec";
import Goalslider from "./Cmpnt/Goals";
import Benefit from "./Cmpnt/Benefits";
import Count from "./Cmpnt/Count_us";
import Recruiterslider from "./Cmpnt/Recruiter_speak";
import Studentslider from "./Cmpnt/Student_trust";
import Query from "./Cmpnt/Query_sec";
import Footer from "./partial/Footer";
import Councelor from "./Cmpnt/Councelor";
import Homeenq from "./Cmpnt/Home_enq"; 
// import Seo from './Cmpnt/Seo';
import { cmsdetail } from "./Api_list";
import { Helmet } from "react-helmet";
import loader from './images/loading.gif'

const Course_home = React.lazy(()=>import('./courses/Course_home'))

const Home = () => { 
  const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }}  
        axios.get(cmsdetail+'home', headerData).then(( response )=>{
            setPost( response.data );
        })
    }, [])
    if( !post ) return null;
    let getData = post.data; 
  return (
   <>
    <Helmet>
            <title>{(getData != null) ? getData.meta_title : 'Careereduversity'}</title> 
            <meta name="title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta name="description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta name="keywords" content={(getData != null) ? getData.meta_keyword : 'Careereduversity'} />
            <meta property="og:title" content={(getData != null) ? getData.meta_title : 'Careereduversity'} />
            <meta property="og:image" content={(getData != null) ?  window.IMGURL + getData.image : 'Careereduversity'}/>
            <meta property="og:description" content={(getData != null) ? getData.meta_description : 'Careereduversity'} />
            <meta property="og:url" content={window.APPURL} />
        </Helmet>
     {/* <Seo seopage='' />  */}
      <Homeenq/>  
      <Councelor />
      <Service_sec />  
      <div className="pt-3 pb-3">
      <Goalslider />
 
      </div>
      
      <Suspense fallback={<div><img src={loader}></img></div>}>
      <Course_home /> 
      </Suspense>
      <Benefit/>
      <Count />
      <Recruiterslider/>
      <div className=" pb-3">
      <Studentslider />
      </div>
      <Query /> 
      <Footer />
    </>
  );
};

export default Home;
