import React from "react";
import axios from "axios";
import { studentbenfit } from "./Api_list";

  const Industry_training = () => {
  const [ post, setPost ]= React.useState( null );

  React.useEffect(()=>{
    var headerData = {"headers":{
      "Accept": "application/json",
      "Content-Type": "application/json",
      "authorization": window.HEADERSAUTH,
    }} 
    axios.get( studentbenfit + 'training_logos', headerData ).then(( response )=>{
      setPost( response.data );
    })
  }, []);
  if ( !post ) return null;
  let getData = post.data;

    return (
        <>
        <div className="trng_img">
            { getData.map( getData => (
                <img src={ window.IMGURL + getData.icon } alt={ getData.alt_tag} />
            ))}
            
        </div>
        </>
    )
}


export default Industry_training;
