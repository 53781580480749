import React from "react";
import axios from "axios";
// import a1 from "../src/images/accreditation/a1.jpg";
// import a2 from "../src/images/accreditation/a2.jpg";
// import a3 from "../src/images/accreditation/a3.jpg";
import { accreditation } from "./Api_list";


const Accreditation_box = ({Accrd}) => {
    const [post, setPost] = React.useState(null);
    React.useState(() => {
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
      axios
        .get(accreditation + Accrd, headerData)
        .then((response) => {
          setPost(response.data);
        }); 
    }, []);
    if (!post) return null;
    let getData = post.data;


    return (
        <>      
        { getData.map(getData =>(
                <div className="col-sm-4">
                    <div className="accrdt_box">
                        <div className="acrdt_img">
                            <img src={  window.IMGURL+getData.logo } alt={getData.acred_name}/>
                        </div>
                        <div className="acrdt_cont">
                                <h5> {getData.acred_name}</h5>
                                <p>{getData.description.slice(0, 200)}</p>
                        </div>
                    </div>
                </div>
                ))}

        </>
    )
}

export default Accreditation_box