import React from "react";
import axios from "axios";
import { Tabs, Tab } from "react-bootstrap";
// import u1 from "../images/u1.png";
import Slider from "react-slick";
// import us1 from '../images/us1.png';
import ui1 from "../images/uni-icons/ui1.png";
import ui2 from "../images/uni-icons/ui2.png";
import ui3 from "../images/uni-icons/ui3.png";
import ui4 from "../images/uni-icons/ui4.png";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Crs_box from "./Univ_course";
import Accreditation_box from "../Accreditation"; 
// import Faq from "./Course_faq.js";
import { coursedetails, websetting } from "../Api_list";
import Accordion from 'react-bootstrap/Accordion';

let slug1 = document.URL.split("/")[3];
let slug2 = document.URL.split("/")[4];
const Course_tabs = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  
  const [post, setPost] = React.useState(null);
  const [Qeury, setQeury] = React.useState(null);
  React.useState(() => {

    var headerData = {"headers":{
      "Accept": "application/json",
      "Content-Type": "application/json",
      "authorization": window.HEADERSAUTH,
    }} 
    axios
      .get(coursedetails + slug1 + "&course_slug=" + slug2, headerData)
      .then((response) => {
        setPost(response.data);
      });
      axios.get(websetting, headerData).then(( response )=>{
          setQeury( response.data );
      })
  }, []);
  if (!post) return null;
  let getData = post.data;
  if (!Qeury) return null;
  let settingData = Qeury.data;
  if( typeof getData !== 'undefined' ){
  let univ_slider = getData.univ_slider;
  let univ_faq = getData.univ_faq;
  return (
    <>
      <Tabs defaultActiveKey="home" id="my-tabs">
        <Tab eventKey="home" title="About College">
          <div className="univ_name d-flex flex-wrap">
            <img src={window.IMGURL + getData.univ_logo} alt={getData.univ_name}/>
            <div className="uni_short">
              <h5>{getData.univ_name}</h5>
              <div className="d-flex flex-wrap">
                <div className="unvshrt_d">
                  <img src={ui1} alt={getData.univ_name} />
                  <span>{getData.address}</span>
                </div>
                <div className="unvshrt_d">
                  <img src={ui2} alt={getData.univ_name} />
                  <span>{getData.univ_type}</span>
                </div>
                <div className="unvshrt_d">
                  <img src={ui3} alt={getData.univ_name} />
                  <span>{getData.univ_est_year}</span>
                </div>
                <div className="unvshrt_d">
                  <img src={ui4} alt={getData.univ_name} />
                  <span>{getData.univ_acred_by.join(", ")}</span>
                </div>
              </div>
              <div className="d-flex soc_icn">
                <p>Share This:</p>
                <a target="_blank" href={settingData.facebook_link}><FacebookOutlinedIcon /></a>
                <a target="_blank" href={settingData.instagram_link}><InstagramIcon /></a>
                <a target="_blank" href={settingData.twitter_link}><TwitterIcon /></a>
                <a target="_blank" href={settingData.youtube_link}><YouTubeIcon /> </a>
              </div>
            </div>
          </div>
          <Slider {...settings}>
          {univ_slider.map((univ_slider) => (
          <div>
            <img src={window.IMGURL + univ_slider.slider}  alt={getData.univ_name}/>
          </div>
          ))}
          
        </Slider>
          
          <div className="tabtxt">
            <h5>{getData.univ_name} </h5>
            {/* <p>{getData.univ_about}</p> */}
            <div className='blgdtl_txt' dangerouslySetInnerHTML={{ __html: getData.univ_about }} /> 
          </div> 
        </Tab>
        <Tab eventKey="profile" title="Course">
          <div className="row">
            <Crs_box UnivName={slug1} />
          </div>
        </Tab>
        <Tab eventKey="accreditation" title="College Accreditation">
          <div className="row">
            <Accreditation_box Accrd={getData.univ_acred_by}/>
          </div>
        </Tab>
        <Tab eventKey="faq" title="FAQ">
        <div className="container padd_50">
                <div className="row text-center">
                    <h3 className='site_hdng'>Frequently Asked Questions</h3>
                </div>
                <div className='row faq_accrdion'>
                    <Accordion defaultActiveKey="0" className=''>
                        <div className='faq_left'>
                        {   univ_faq.map( (univ_faq, num) =>(
                                (num === 0) ?
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{ univ_faq.question} </Accordion.Header>
                                <Accordion.Body>
                                { univ_faq.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                                : 
                                <Accordion.Item eventKey={num}>
                                <Accordion.Header>{ univ_faq.question} </Accordion.Header>
                                <Accordion.Body>
                                { univ_faq.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                           ))}
                        </div>
                        
                    </Accordion>
                </div>
            </div>
        </Tab>
      </Tabs>
    </>
  );
 }
};

export default Course_tabs;
