import React from "react";
import axios from "axios";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ReplayIcon from '@mui/icons-material/Replay';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from "moment-timezone";
import { student_plan, planlist } from "./Api_list"

const Failure_sec =  (orderId) => {
    let obj = JSON.parse( localStorage.getItem('loginddata') );
    const [ post, setPost] = React.useState( null );
    const [plandata, setPlandata] = React.useState(null);
    React.useEffect(()=>{
        axios.get(planlist).then(( response )=>{
            setPlandata( response.data )
        })
       axios.get( student_plan + obj._id+'&order_id='+orderId.orderId ).then(( response )=>{
           setPost( response.data );
       })
    }, []);
    if( !post ) return null;
    let getData = post.data[0]
    if( !plandata ) return null;
    let getFeature =plandata.data[0].feature_master;
    let planRecord = post.data[0].plan_features[0].features;
    return (
        <>
            <div className="container ptb_50">
                <div className="row chkout_row">
                    <div className="col-sm-5">
                        <div className="succs_box failure">
                            <CancelIcon />
                            <h4>Payment Failed <a href="/plans" className="tryagain"> Try Again <ReplayIcon/> </a> </h4> 
                            <div className="pym_dtl">
                                <h6>Payment Detail</h6>
                                <div className="d-flex justify-content-between">
                                    <p>Ref Number </p>
                                    <p>{getData.order_id}</p> 
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>Payment Status</p>
                                    <p className="fail">Failed</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>Payment Time</p>
                                    <p>{moment(getData.added_on).format("DD/MM/YYYY")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="success_dtl plntext">
                            <h4>Your Subscription <span className="fail">Inactive</span></h4>
                            <h5>{getData.plan_name}</h5>
                            <span className="exp">Expiry -</span>
                            <h6>Key plan features</h6>
                            <ul>
                            { getFeature.map(getFeature =>(
                                    planRecord.filter(person => person.featurs == getFeature._id).map(filteredPerson => (
                                        (filteredPerson.yes_no === 'Yes' ? <li> <TaskAltIcon /> {getFeature.title} {(filteredPerson.title) ? '('+filteredPerson.title+')' : ''}</li> : "" )
                                    ))
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default Failure_sec;
