import React from "react";
import axios from "axios";
import Slider from "react-slick";
import { teamlist } from "../Api_list";


const Coachslider = () => {
    const [ post, setPost ] = React.useState( null );
    React.useEffect(()=>{
        var headerData = {"headers":{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "authorization": window.HEADERSAUTH,
          }} 
        axios.get(  teamlist + 'core_team', headerData ).then(( response )=>{
            setPost( response.data )
        })
    }, [])
    if (!post) return null;
    var getData = post.data;
    let count = 4;
    if( count > getData.length ){
        count = getData.length;
    }

   const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: count,
    slidesToScroll: count
};
    return (
        <>
         <section className="coach_slider">
                <div className="container">
                    <div className="row centr_hdng_txt">
                        <h2 className="sitehdng"> Our Career Coach And Counselor  </h2>
                    </div>
            <div className="coach_slider">
                <Slider {...settings}>
                {getData.map(getData => (
                    <div className="coach_slide">
                        <div className="coach_img">
                            <img src={ window.IMGURL + getData.profile_pic }  alt={ getData.alt_tag} />
                        </div>
                        <p>{ getData.full_name}</p>
                        <span className="skls">{ getData.designation}</span>
                    </div>
                ))}
                </Slider>
            </div>
            </div>
            </section>
        </>
    )
}

export default Coachslider;